@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Jost:ital,wght@0,100..900;1,100..900&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Mukta", sans-serif !important;
}
body {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
a:hover {
  text-decoration: none;
}
.position-unset {
  position: unset;
}
.form-label .label-note {
  font-size: 10px;
  font-weight: 400;
  color: #000;
}
.p-datepicker table td .p-disabled {
  opacity: 0.2;
}
button.p-sidebar-close {
  outline: none;
  box-shadow: none;
}
/* Container */
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px;
  }
}
.min-h-vh-50 {
  min-height: 50vh;
}
.pb-40 {
  padding-bottom: 40px;
}
.pt-80 {
  padding-top: 80px !important;
}
.fancy-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.fancy-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancy-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #a3a2a2;
}
.bg-light-gray {
  background: #f7f6f6;
}
.cursor-pointer {
  cursor: pointer;
}
.row-gap-gutter {
  row-gap: var(--bs-gutter-x);
}
.PhoneInput {
  font-size: 16px;
  background: transparent;
  border: 1px solid #868686;
  border-radius: 5px;
  color: #0e0e46;
  min-height: 50px;
  margin: 0;
  padding: 9px 15px;
  max-height: 50px;
}

.PhoneInput input {
  margin: 0;
  padding: 0;
  border: none;
  height: 48px;
  width: calc(100% - 30px);
  outline: none;
  font-size: 14px;
}

.PhoneInput--focus {
  border-color: var(--secondary-theme-color);
}
/*Common */
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: none;
}
.p-datepicker .p-datepicker-header button {
  color: var(--black);
}
.p-datepicker table td > span,
.p-datepicker table th > span {
  width: 30px;
  height: 30px;
  font-size: 14px;
  margin: 0;
  color: #000;
}

.p-datepicker table td {
  padding: 4px;
}

.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background: #12086f;
}
.p-calendar input::placeholder {
  color: var(--black);
}
.p-calendar input {
  padding: 9px 15px;
  min-height: unset;
  height: unset;
  font-size: 14px;
  border-color: #c1c1c1;
  height: 38px;
}

.p-calendar input + button {
  padding: 0;
  border-radius: 0 4px 4px 0;
  background: var(--primary-theme-color);
  border-color: var(--primary-theme-color);
  min-height: unset;
  height: unset;
  line-height: unset;
}

.p-calendar {
  width: 100%;
}
.btn.download-btn {
  background-color: green;
  border-color: green;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 300;
}
.p-datepicker {
  z-index: 1056 !important;
}
.btn.download-btn:hover {
  color: green;
}
.read-more-less span {
  font-size: 14px;
  font-weight: 600;
  /* color: var(--secondary-theme-color); */
  color: #e48b00;
  cursor: pointer;
}
.read-more-less .rotate {
  transform: rotate(180deg);
}
.custom-dropdown {
  position: relative;
}
.custom-dropdown .custom-dropdown-title,
.filter-btn,
.sort-btn {
  height: 50px;
  padding: 14px 20px;
  border-radius: 12px;
  border: 1px solid rgb(197 196 196);
  font-size: 16px;
  line-height: 21px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.2s;
  justify-content: space-between;
  font-weight: 500;
  background: var(--white);
  color: var(--black);
  gap: 10px;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
  transition: all 300ms ease-in-out;
}
.property-filter-section .filter-card .search-wrap input {
  background: #fff;
  border: 1px solid rgb(197 196 196);
  color: var(--black);
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
}
.custom-dropdown .custom-dropdown-title:hover,
.filter-btn:hover,
.sort-btn:hover,
.sort-btn.btn.show,
.sort-btn.btn:focus-visible {
  background-color: #f2f1fb;
  border-color: #f2f1fb;
  color: var(--black);
  box-shadow: none;
}
.custom-dropdown .custom-dropdown-title svg {
  font-size: 15px;
  position: relative;
  top: 1px;
}
.custom-dropdown .custom-filter-modal-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 17px 5px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  position: absolute;
  border-radius: 10px;
  z-index: 50;
  left: 0px;
  margin-top: 12px;
  background-color: white;
  width: 500px;
}

.custom-dropdown .filter-modal-body {
  padding: 24px;
  overflow: auto;
  max-height: 50vh;
  z-index: 9;
}
.custom-dropdown .locality-list-item,
.filter-list-item {
  cursor: pointer;
  border: 1px solid rgb(197 196 196);
  border-radius: 10px;
  padding: 11px 24px;
  margin-right: 12px;
  margin-top: 12px;
  background-color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
  /* white-space: nowrap; */
  font-weight: 500;
  transition: all 300ms ease-in-out;
}
.property-filter-card .custom-dropdown .locality-list-item:hover,
.property-filter-card .custom-dropdown .locality-list-item.active,
.filter-list-item:hover,
.filter-list-item.active {
  background: #cce1fb;
  border-color: #cce1fb;
  color: var(--black);
}
.custom-dropdown .filter-modal-body .locality-list,
.filter-list-wrap {
  display: flex;
  flex-wrap: wrap;
  /* width: 720px;
  max-width: 90vw; */
}

.custom-dropdown .locality-list-item:hover {
  background: var(--secondary-theme-color);
  border-color: var(--secondary-theme-color);
  color: var(--white);
}
.custom-dropdown .filter-search input {
  background: #fff;
  border-color: #c5c4c4;
  border-radius: 12px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.custom-dropdown .filter-body-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.property-filter-modal .modal-title {
  font-size: 16px;
}

.property-filter-modal .modal-dialog {
  max-width: 750px;
}

.property-filter-modal .filter-body-wrap h2 {
  font-size: 18px;
  margin-bottom: 0;
}
.modal-backdrop.light-backdrop.show {
  background: var(--black);
  opacity: 0.5;
}
.property-filter-modal .filter-body-wrap:not(:last-child) {
  margin-bottom: 30px;
}
.property-filter-modal .modal-body {
  max-height: 63vh;
  overflow-y: auto;
}
.high-lights ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 12px;
}

.high-lights ul li {
  padding: 4px 12px;
  border: 1px solid #dfdfdf;
  border-radius: 30px;
  background: var(--white);
}

.high-lights {
  margin-top: 16px;
}
.avail-for {
  font-size: 14px;
  margin-bottom: 8px;
}

.avail-for .text {
  font-weight: 600;
}

.avail-for .date {
  padding-left: 4px;
  color: green;
}
.custom-table {
  font-size: 14px;
  background: var(--white);
  border: 1px solid #eee;
  border-radius: 8px;
}
.custom-table table {
  margin-bottom: 0;
}

.custom-table th,
.custom-table td {
  white-space: nowrap;
}

.custom-table th {
  background: #f8f7ff;
}
.custom-table th:first-child {
  border-top-left-radius: 8px;
}

.custom-table th:last-child {
  border-top-right-radius: 8px;
}
.custom-table td.text-warning span,
.custom-table td.text-danger span,
.custom-table td.text-success span {
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  padding: 4px;
}
.custom-table td.text-warning span {
  background: #fffaf1;
  border: 1px solid #ffe8bd;
  color: orange;
}

.custom-table td.text-danger span {
  background: #ffebef;
  border: 1px solid #dc3545;
}
.custom-table td.text-success span {
  background: #edfff3;
  border: 1px solid #558f68;
}
.file-dropper {
  border: 2px dashed #c9c9c9;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: border 0.3s ease;
}

.file-dropper.dragging {
  border-color: #007bff;
  background-color: #f1f1f1;
}

.file-dropper-label {
  color: #0e0e46;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 0;
}

.file-dropper:hover {
  border-color: var(--secondary-theme-color);
}

.file-dropper span {
  display: block;
  font-size: 16px;
  color: #0e0e46;
}

.file-dropper span.selected {
  font-weight: bold;
}
.file-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
}

.file-preview .file-thumbnail img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.file-preview .file-thumbnail {
  position: relative;
}

.file-preview .file-thumbnail button {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  padding: 0;
  background: red;
  border-color: red;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: none;
}
/**/
/* data Table */
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
    .p-datatable-tbody
  )
  .p-datatable-tbody
  > tr
  > td {
  border-width: 0 1px 1px 1px;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-color: #e5e7eb;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
  border-color: #e5e7eb;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  padding: 12px;
  font-size: 13px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-right-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th span {
  white-space: nowrap;
}
/***/
.detail-card {
  background: var(--white);
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  position: relative;
  margin: 0 0 24px;
  /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.2); */
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.detail-card h2 {
  font-size: 20px;
  margin-bottom: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d8d8;
  margin-bottom: 10px;
}
.form-select:focus {
  box-shadow: none;
}
.form-select {
  cursor: pointer;
  font-size: 14px;
}
.toggle-switch {
  position: relative;
}
.form-switch .form-check-input {
  width: 45px;
  height: 25px;
  border-radius: 100vw;
  transition: background-color 300ms;
  position: relative;
}

.form-switch .form-check-input {
  background: transparent;
  border: none;
  position: absolute;
  box-shadow: none;
  opacity: 0;
  right: 0;
  top: 0;
  margin: 0;
}

.form-switch .form-check-input + label {
  width: 45px;
  height: 25px;
  background-color: rgb(208, 208, 208);
  border-radius: 100vw;
  transition: background-color 300ms;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

.form-switch .form-check-input + label:before {
  content: "";
  background: rgb(255, 255, 255);
  height: calc(21px);
  width: calc(21px);
  border-radius: 50%;
  margin: 2px;
  position: absolute;
  transform: translateX(0px);
  transition: all 300ms ease-in-out;
  left: 0;
}

.form-switch .form-check-input:checked + label:before {
  right: 0;
  left: unset;
}

.form-switch .form-check-input:checked + label {
  background: #f26419;
}

.select-wrap {
  position: relative;
}

.select-wrap .drop-down-arrow {
  content: "";
  border-style: solid;
  border-width: 6px 6px 0;
  height: 6px;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: 23px;
  right: 12px;
  width: 6px;
  border-color: var(--black) transparent transparent;
}

.swiper .swiper-pagination-bullet {
  background: transparent;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  position: relative;
  display: inline-block;
  opacity: 1;
}

.swiper .swiper-pagination-bullet:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e3e3e3;
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
}

.swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-radius: 100%;
  border-color: var(--primary-theme-color);
}

.swiper .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background: var(--primary-theme-color);
}
.swiper-button-next,
.swiper-button-prev {
  width: 45px;
  height: 45px;
  background-color: var(--white);
  border-radius: 100%;
  top: unset;
  bottom: 0;
  transition: all 300ms ease-in-out;
  z-index: 1;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  content: "";
  width: 22px;
  height: 22px;
  background-size: contain;
  transition: all 300ms ease-in-out;
}
.swiper-button-next:after {
  background-image: url(../assets/images/next.png);
}
.swiper-button-prev:after {
  background-image: url(../assets/images/back.png);
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: var(--primary-theme-color);
}

.swiper-button-next:hover:after,
.swiper-button-prev:hover:after {
  filter: invert(1);
}
.testimonial-slider .swiper-button-prev {
  left: calc(50% - 54px);
  right: unset;
}

.testimonial-slider .swiper-button-next {
  right: calc(50% - 54px);
  left: unset;
}

.prop-img-slider .swiper .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: var(--white);
  opacity: 1;
}

.prop-img-slider
  .swiper
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 16px;
  border-radius: 8px;
  border-color: var(--white);
}

.prop-img-slider .swiper .swiper-pagination-bullet:before {
  content: none;
}

.read-more {
  background: transparent;
  border: none;
  font-weight: 700;
  color: var(--primary-theme-color);
  padding: 0 0 0 10px;
}
.property-list-view .product-custom .profile-widget {
  display: flex;
  flex-wrap: wrap;
}

.property-list-view .product-custom .doc-img {
  width: 250px;
}

.property-list-view .product-custom .pro-content {
  width: calc(100% - 250px);
  padding: 16px;
  justify-content: flex-start;
}

.property-list-view .pro-content p {
  margin-bottom: 0;
}

.property-list-view .product-custom .pro-content .details {
  padding: 0;
}

.property-list-view .product-custom .pro-content .details li {
  margin-bottom: 0;
}

.property-list-view .product-custom .doc-img .property-img img {
  height: 180px;
  border-radius: 10px;
}
.property-list-view .pro-content h3 {
  margin-bottom: 10px;
  width: calc(100% - 30px);
}
.product-custom .doc-img .property-img img {
  height: 190px;
}
/* Header */
.header .header-navbar-rht li .btn.user-dropdown-btn {
  width: 35px;
  height: 35px;
  min-width: unset;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background-color: var(--primary-theme-color);
  border-radius: 100%;
  border-color: var(--primary-theme-color);
}

.header .header-navbar-rht li .btn.user-dropdown-btn:after {
  content: none;
}

.header .header-navbar-rht li .btn.user-dropdown-btn svg {
  color: var(--white);
  font-size: 16px;
}
.header .header-navbar-rht li .menu-icon {
  font-size: 24px;
}

.header .header-navbar-rht li .menu-icon svg {
  position: relative;
  top: 2px;
  cursor: pointer;
  font-size: 28px;
}
.header .header-navbar-rht li a {
  font-size: 15px;
  color: #000;
}
.swr-user-menu {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}
.ser-main-menu li a,
.swr-user-menu li a {
  font-size: 15px;
  color: #000000;
  /* color: #343434; */
  /* margin-bottom: 16px; */
  display: block;
  padding: 8px 16px;
  border-radius: 8px;
}

.ser-main-menu li a:hover,
.swr-user-menu li a:hover {
  background: #eff6ff;
}
.swr-user-menu li a img {
  width: 24px;
  margin-right: 8px;
}
.swr-user-menu li:not(:last-child) {
  margin-bottom: 4px;
}

.swr-user-menu li a {
  display: flex;
  align-items: center;
}
.swr-user-menu {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.nav-sidebar.p-sidebar {
  border-radius: 24px 0 0 24px;
}

.sidebar-top-menu-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.sidebar-top-menu-wrap .logout-btn {
  justify-content: center;
  display: flex;
  padding: 20px 0;
}

.sidebar-top-menu-wrap .logout-btn button {
  min-width: 160px;
}
.user-profile .dropdown-menu {
  min-width: 230px;
  padding: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-color: #ddd;
}
.user-profile .dropdown-menu .dropdown-item {
  padding: 0;
}
.user-profile .dropdown-menu .dropdown-item a,
.user-profile .dropdown-menu .dropdown-item .wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  color: var(--black);
  font-size: 14px;
}

.user-profile .dropdown-menu .dropdown-item img {
  height: 20px;
}

.user-profile .dropdown-menu .dropdown-item:hover {
  background: #f4f3ff;
}
/**/

.banner-search .banner-tab-property .banner-property-info {
  display: flex;
  align-items: center;
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:not(:last-child) {
  width: calc(50% - 40px);
}
.banner-search .banner-property-grid .error-text {
  position: absolute;
  color: red;
  font-size: 12px;
  margin-top: 2px;
  left: 4px;
}
.banner-property-grid .locality-list,
.search-wrap .custom-filter-modal-wrapper .locality-list {
  gap: 8px;
}
.banner-property-grid .locality-list li,
.search-wrap .custom-filter-modal-wrapper .locality-list-item {
  padding: 6px 8px;
  font-size: 13px;
  margin-right: 0px;
  width: calc(33.33% - 8px);
  text-align: center;
  margin-top: 0;
}
.view-more {
  font-size: 15px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: var(--secondary-theme-color);
  gap: 6px;
}

.view-more i {
  color: inherit;
}

.easy-stay-card {
  position: relative;
  background-image: url(../assets/images/short-stay-bg.png);
  padding: 50px;
  border-radius: 10px;
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-color: #fff3ec;
  position: relative;
}

.easy-stay-card img {
  border-radius: 10px;
}
.easy-stay-card ul {
  display: flex;
  gap: 20px;
}
.easy-stay-card ul.content-list {
  flex-direction: column;
  gap: 8px;
  margin-top: 30px;
}

.easy-stay-card ul.content-list li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-weight: 500;
}

.easy-stay-card ul.content-list li svg {
  position: relative;
  top: 3px;
  color: #f26419;
}
.easy-stay-card h3 {
  font-size: 20px;
  color: #000;
}
.easy-stay-card .image img {
  max-height: 400px;
  display: flex;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
}

.partners-sec.get-app {
  padding: 0 0 0;
  margin-top: 40px;
  border-bottom: none;
  margin-bottom: 50px;
}

.get-app .easy-stay-card .content {
  padding: 0 0;
}

.get-app .easy-stay-card .content h3 {
  margin-bottom: 20px;
}

.get-app .easy-stay-card {
  background: #d3f2fb;
}

.get-app .easy-stay-card .app-info-wrap {
  display: flex;
  gap: 30px;
}

.get-app .easy-stay-card .app-info-wrap .app-qr img {
  height: 130px;
  margin-top: -60px;
}
.get-app .easy-stay-card .image img {
  max-height: 420px;
  left: unset;
  top: unset;
  transform: none;
  bottom: 0;
}
.contact-numbers {
  display: flex;
  gap: 6px;
}

.contact-numbers .numbers {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 10px 16px;
}

.contact-numbers .numbers .item {
  position: relative;
}

.contact-numbers .numbers .item:first-child:before,
.contact-numbers .numbers .item:nth-child(3):before {
  content: "";
  position: absolute;
  right: -3px;
  width: 1px;
  height: 90%;
  background: #fcaf3d;
  top: 50%;
  transform: translateY(-50%);
}

.popular-properties {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.popular-properties li {
  margin-bottom: 0 !important;
}

.popular-properties li a {
  font-size: 14px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 6px;
  backdrop-filter: blur(8px);
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.1);
  display: block;
}

.popular-properties li a:hover {
  color: #fff !important;
  background: #f26b24;
}

/**/
.howit-work .container {
  /* max-width: 90%; */
}
.benefits__row {
  display: grid;
  column-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

.custom-card img {
  height: 52px;
}

.custom-card h3 {
  font-size: 18px;
  margin: 20px 0 10px;
}

.custom-card p {
  font-size: 13px;
}

.custom-card {
  padding: 12px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  height: 100%;
}

.areas__rowwrap {
  display: grid;
  gap: 30px;
}
.areas__row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
}

.areas__row .areas__col a {
  padding: 20px;
  text-align: center;
  background: #fff3ec;
  border-radius: 10px;
  border: 1px solid #eee;
  display: block;
  text-decoration: none;
}

.areas__row .areas__col h3 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.own-building .image img {
  border-radius: 10px;
  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.2));
  max-height: 350px;
  display: block;
  margin: 0 auto;
}

.own-building {
  padding: 0 0 80px;
}

.own-building .section-heading {
  padding-right: 50px;
}

.own-building .section-heading h3 {
  font-size: 22px;
  margin: 20px 0 10px;
}

.own-building .section-heading a {
  margin-top: 30px;
}

/**/
.social-media {
  padding-bottom: 80px;
}

/* Inner Pages */
/* Detail Page */
.custom-breadcrumb {
  padding: 8px 0;
  background: #f7f7f7;
}
.custom-breadcrumb .bread-crumb-list {
  display: flex;
  gap: 0;
  font-size: 14px;
  color: #000;
}

.custom-breadcrumb .bread-crumb-list a,
.custom-breadcrumb .bread-crumb-list span {
  color: inherit;
  font-weight: 500;
}

.custom-breadcrumb .bread-crumb-list li {
  position: relative;
}

.custom-breadcrumb .bread-crumb-list li:not(:last-child):after {
  content: "/";
  margin: 0 8px;
}
.custom-breadcrumb.dark {
  background: var(--secondary-theme-color);
}

.custom-breadcrumb.dark .bread-crumb-list a,
.custom-breadcrumb.dark .bread-crumb-list span {
  color: var(--white);
}

.custom-breadcrumb.dark .bread-crumb-list li:not(:last-child):after {
  color: var(--white);
}
/* Detail Banner */
.residence-banner {
  padding: 30px 0;
  background-color: #f1f5f8;
}
.section-padding {
  padding: 50px 0;
  position: relative;
}
.residence-banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 250px;
  left: 0;
  bottom: 0;
  z-index: -1;
  /* background: #f7f6ff; */
  background-color: #fdfdfd;
}
.residence-banner .residence-title-wrap {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.residence-banner .residence-title-wrap .right-col {
  width: 150px;
  text-align: right;
}

.residence-banner .residence-title-wrap .left-col {
  width: calc(100% - 165px);
}
.booking-banner-text.residence-banner .residence-title-wrap .left-col {
  width: 100%;
}
.residence-banner .residence-title-wrap .sortlist-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 4px;
}

.residence-banner .residence-title-wrap .sortlist-wrap svg {
  color: var(--secondary-theme-color);
  font-size: 22px;
}

.residence-banner .residence-title-wrap h1 {
  font-size: 26px;
  margin-bottom: 4px;
  line-height: 30px;
}

.residence-banner .residence-title-wrap p {
  margin-bottom: 0;
  font-size: 14px;
  color: rgb(78, 82, 83);
}

.residence-banner .residence-title-wrap p svg {
  color: var(--secondary-theme-color2);
  margin-right: 6px;
}

.residence-banner .residence-title-wrap .location {
  display: flex;
  gap: 5px;
}

.residence-banner .residence-title-wrap .location a {
  font-size: 14px;
  font-weight: 700;
  color: green;
}
.residence-banner .residence-title-wrap .location a span {
  color: rgb(78, 82, 83);
  padding-right: 5px;
}
.residence-banner .residence-title-wrap .contact-owner {
  margin-top: 10px;
}

.residence-banner .banner-thumb-image.left-image img,
.residence-banner
  .lg-react-element.gallery
  .gallery__item.banner-col-grid-layout
  img {
  height: 430px;
}

.residence-banner .banner-thumb-image img,
.residence-banner .lg-react-element.gallery img {
  height: 203px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.residence-banner .show-all-image-btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.residence-banner .lg-react-element.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
  grid-template-rows: auto;
  gap: 24px; /* Add some space between the images */
}

.residence-banner .lg-react-element.gallery .gallery__item:first-child {
  grid-column: span 2; /* First image spans 2 columns */
  grid-row: span 2; /* First image spans 2 rows */
  width: 100%;
  height: auto;
}

.residence-banner .lg-react-element.gallery .gallery__item {
  width: 100%;
}

.residence-banner .lg-react-element.gallery .gallery__item:nth-child(2) {
  grid-column: span 1; /* Second image spans 2 columns */
  grid-row: span 1; /* Stays in the first row */
  width: 100%;
  height: auto;
}

.residence-banner .lg-react-element.gallery .gallery__item:nth-child(3) {
  grid-column: span 1; /* Each of the remaining images gets 1 column */
  grid-row: span 1; /* Each takes 1 row */
  width: 100%;
  height: auto;
}

.residence-banner .lg-react-element.gallery .gallery__item:nth-child(4) {
  grid-column: span 1; /* Each of the remaining images gets 1 column */
  grid-row: span 1; /* Each takes 1 row */
  width: 100%;
  height: auto;
}

.residence-banner .lg-react-element.gallery .gallery__item:nth-child(5) {
  grid-column: span 1; /* Each of the remaining images gets 1 column */
  grid-row: span 1; /* Each takes 1 row */
  width: 100%;
  height: auto;
}
.residence-banner
  .lg-react-element.gallery
  .gallery__item.banner-col-grid-layout {
  grid-column: span 2; /* First image spans 2 columns */
  grid-row: span 2; /* First image spans 2 rows */
  width: 100%;
  height: auto;
}
.residence-banner .lg-react-element.gallery .gallery__item {
  display: block;
  cursor: pointer;
}
.residence-banner .lg-react-element.gallery .gallery__item.video-type {
  position: relative;
}
.residence-banner .lg-react-element.gallery .gallery__item.video-type::before {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  background: url(../assets/images/play.png);
  background-size: contain;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  z-index: 1;
  filter: brightness(1000);
}

.residence-banner .lg-react-element.gallery .gallery__item:first-child img {
  height: 430px;
}
.residence-banner .lg-react-element.gallery .gallery__item.video-type {
  position: relative;
  z-index: 1;
}

.residence-banner .lg-react-element.gallery .gallery__item.video-type::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--black);
  border-radius: 8px;
  opacity: 0.7;
}
/**/
.residency-details-section {
  padding-bottom: 50px;
  /* background: #f7f6ff; */
  /* background-color: #fdfdfd; */
  background-color: #f1f5f8;
}
.residency-details-section .property-content-gap {
  margin-bottom: 30px;
}
.residency-details-section .property-rental-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.residency-details-section .residency-details-wrap {
  display: flex;
  flex-wrap: wrap;
}
.residency-details-section .property-rental-wrap .for-daily a {
  font-weight: 700;
  padding: 6px 20px;
  margin-top: 10px;
  display: inline-block;
  background: var(--secondary-theme-color2);
  color: var(--white);
  border-radius: 8px;
}
.residency-details-section .residency-details-wrap .left-col {
  width: calc(100% - 370px);
  padding-right: 30px;
}
.residency-details-section .residency-details-wrap .right-col {
  width: 370px;
}
.residency-details-section .property-rental-wrap .term {
  font-size: 14px;
  color: #565555;
}

.residency-details-section .property-rental-wrap .rent-month .amount {
  font-size: 22px;
  font-weight: 600;
  /* color: var(--secondary-theme-color2); */
  line-height: 40px;
}

.residency-details-section .property-rental-wrap .rent-month svg {
  font-size: 18px;
  margin-right: 4px;
}

.residency-details-section .property-rental-wrap .deposit span {
  font-weight: 800;
}

.residency-details-section .property-rental-wrap .deposit svg {
  font-size: 14px;
  margin-right: 2px;
  margin-left: 4px;
}

.residency-details-wrap .book-schdule-card {
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  background: var(--white);
  /* background: linear-gradient(
    199.5deg,
    rgb(150 199 255 / 50%) -74.79%,
    rgba(96, 195, 173, 0) 96.63%
  );
  border: 1px solid rgb(0 108 228); */
}
.residency-details-wrap .book-schdule-card .book-now-form h2 {
  margin: -16px -16px 16px;
  padding: 8px 16px;
  font-size: 20px;
  text-align: center;
  background: var(--primary-theme-color);
  /* background-color: #e48b00; */
  border-radius: 8px 8px 0 0;
  color: var(--white);
  font-weight: 500;
}

.residency-details-wrap .property-detail-form-tabs {
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  margin-bottom: 20px;
  background: #f9f9f9;
}

.residency-details-wrap .property-detail-form-tabs .nav-item button {
  background: transparent;
  color: #000;
  border: none;
  display: block;
  text-align: center;
  width: 100%;
  border-radius: 6px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
}

.property-detail-form-tabs .nav-item {
  width: 50%;
  text-align: center;
}

.property-detail-form-tabs .nav-item button.active {
  background: var(--secondary-theme-color2);
  color: var(--white);
}

.residency-details-wrap .tab-content .form-control,
.residency-details-wrap .form-select,
.schedule-visit-form .form-control,
.book-now-form .form-control {
  background-color: var(--white);
  border-color: #c1c1c1;
  min-height: 38px;
  height: 38px;
}

.residency-details-wrap .get-updates .whatsapp-updates svg {
  color: rgb(0 230 118);
  font-size: 30px;
  margin-right: 10px;
}

.residency-details-wrap .get-updates .whatsapp-updates {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.residency-details-wrap .get-updates .wrap {
  display: flex;
  align-items: center;
}
.residency-details-wrap .get-updates .whatsapp-updates span {
  font-weight: 600;
}
.residency-details-wrap .book-schdule-card .get-updates {
  margin-top: 0;
}
.toggle-switch {
  position: relative;
}
.form-switch .form-check-input {
  width: 45px;
  height: 25px;
  border-radius: 100vw;
  transition: background-color 300ms;
  position: relative;
}

.form-switch .form-check-input {
  background: transparent;
  border: none;
  position: absolute;
  box-shadow: none;
  opacity: 0;
  right: 0;
  top: 0;
  margin: 0;
}

.form-switch .form-check-input + label {
  width: 45px;
  height: 25px;
  background-color: rgb(208, 208, 208);
  border-radius: 100vw;
  transition: background-color 300ms;
  position: relative;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.form-switch .form-check-input + label:before {
  content: "";
  background: rgb(255, 255, 255);
  height: calc(21px);
  width: calc(21px);
  border-radius: 50%;
  margin: 2px;
  position: absolute;
  transform: translateX(0px);
  transition: all 300ms ease-in-out;
  left: 0;
}

.form-switch .form-check-input:checked + label:before {
  right: 0;
  left: unset;
}

.form-switch .form-check-input:checked + label {
  background: var(--secondary-theme-color);
}

.terms-check label {
  font-weight: 400;
  color: #000;
  position: relative;
  padding-left: 8px;
  cursor: pointer;
  font-size: 13px;
}

.terms-check input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.terms-check input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: -20px;
  width: 16px;
  height: 16px;
  border: 2px solid #ddd;
  top: 4px;
  border-radius: 2px;
}

.terms-check input[type="checkbox"] + label:after {
  content: "";
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 6px;
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
  position: absolute;
  left: -15px;
  top: 5px;
}

.terms-check input[type="checkbox"]:checked + label:before {
  background: var(--secondary-theme-color);
  border-color: var(--secondary-theme-color);
}

.residency-details-wrap .submit-btn-wrap button {
  display: block;
  width: 100%;
  margin-top: 30px;
  /* background: #e48b00; */
  /* border-color: #e48b00; */
  border-color: #ff6838;
  background-color: #ff6838;
  padding: 8px 0;
  border-radius: 8px;
}
.residency-details-wrap .submit-btn button:hover {
  border-color: #ff6838;
  color: #ff6838;
}
.residency-details-wrap .property-overview {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  text-align: center;
}

.residency-details-wrap .property-overview li img {
  margin-bottom: 10px;
  height: 20px;
}
.residency-details-wrap .property-overview p {
  font-weight: 600;
}
.sticky-stripe .sticky-list {
  display: flex;
  gap: 50px;
}

.sticky-stripe .sticky-list li {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  padding: 12px 0;
  display: inline-block;
}

.sticky-stripe .sticky-list li:before {
  content: "";
  width: 100%;
  left: 0;
  bottom: 0;
  height: 3px;
  background: var(--secondary-theme-color);
  position: absolute;
  border-radius: 14px;
  transition: all 300ms ease-in-out;
  transform: scaleX(0);
}

.sticky-stripe .sticky-list li.active:before,
.sticky-stripe .sticky-list li:hover:before {
  transform: scaleX(1);
}

.about-property .about-inner-content .content-wrap p {
  margin-bottom: 12px;
  font-size: 14px;
}

.about-property .about-inner-content .content-wrap {
  height: 100px;
  overflow: hidden;
  transition: all 300ms ease-in-out;
}
.about-property .about-inner-content .content-wrap.read-full-para {
  height: auto;
}
.about-property .about-inner-content .content-wrap p,
.about-property .about-inner-content .content-wrap p span {
  font-family: "Inter", sans-serif !important;
}
.about-property h3 {
  font-size: 22px;
  position: relative;
  z-index: 1;
}
.about-property h3 span {
  background: #fff;
  padding-right: 10px;
}
.about-property h3:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d9d8d8;
  top: 50%;
  z-index: -1;
}
.property-details-wrap .property-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px 10px;
  margin-top: 20px;
}

.property-details li {
  display: flex;
  flex-direction: column;
  color: #606060;
}

.property-details li span {
  font-weight: 700;
  opacity: 1;
  color: #000;
}

.property-amenities {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.property-amenities li {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 7px 24px;
  /* border: 1px dashed var(--secondary-theme-color); */
  border-radius: 5px;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.property-amenities li {
  box-shadow: none;
  padding: 0;
  width: calc(33.33% - 20px);
  justify-content: flex-start;
}
.property-amenities li svg {
  /* color: var(--secondary-theme-color); */
  color: #ff6838;
}
.property-amenities li img {
  height: 14px;
}
.explore-location .map iframe {
  width: 100%;
  border-radius: 10px;
  height: 380px;
}
.property-video .sample-video img {
  border-radius: 10px;
}
.or-content {
  margin: 24px 0;
  text-align: center;
  position: relative;
  z-index: 1;
}

.or-content:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background: #cfcfcf;
  transform: translateY(-50%);
  z-index: -1;
}
.login-wrapper .or-content span {
  background: #fff;
}
.or-content span {
  padding: 0 10px;
  /* background: #fff; */
  background: #f1f5f8;
}
.nearby-areas-properties {
  display: grid;
  gap: 30px 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px 0 10px;
}

.nearby-areas-properties .nearby-areas-properties-item h3 {
  font-size: 18px;
  margin-bottom: 4px;
  color: green;
  display: flex;
  gap: 10px;
  align-items: center;
}
.nearby-areas-properties .nearby-areas-properties-item h3 img {
  height: 20px;
  position: relative;
  top: -2px;
}
.nearby-areas-properties .nearby-areas-properties-list {
  /* font-size: 15px; */
  color: var(--black);
}

.nearby-areas-properties .nearby-areas-properties-list .location-distance {
  font-weight: 500;
}

.nearby-areas-properties .nearby-areas-properties-list li:not(:last-child) {
  margin-bottom: 6px;
}
.nearby-areas-properties .nearby-areas-properties-list .location-name a {
  font-weight: 600;
  text-decoration: underline;
}
/***/
.property-filter-section {
  padding: 30px 0 50px;
  position: relative;
  z-index: 2;
}

.custom-breadcrumb.property-listing-breadcrumb {
  padding-top: 30px;
}

.property-filter-section:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  background: var(--secondary-theme-color);
  z-index: -1;
}
.property-filter-section .property-filter-card {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.property-filter-section .filter-card {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.property-filter-section .filter-card .search-wrap {
  width: 35%;
}
.property-filter-section .filter-card .filters-wrap,
.property-filter-section .filter-card .right-col,
.property-filter-section .filter-card .more-filters {
  display: flex;
  gap: 12px;
}

.property-filter-section .property-filter-card .applied-filters {
  margin-top: 20px;
}

.property-filter-section .property-filter-card .applied-filters h5 {
  font-size: 14px;
  opacity: 0.7;
  font-weight: 500;
  color: var(--black);
}

.property-filter-section .property-filter-card .applied-filter-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  font-size: 14px;
}

.property-filter-section .property-filter-card .applied-filter-list li {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 6px;
  background: #cce1fb;
  border: 1px solid var(--secondary-theme-color);
  border-radius: 14px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.property-filter-section .property-filter-card .applied-filter-list li svg {
  cursor: pointer;
}

/**/
.property-listing-section {
  padding-bottom: 70px;
  min-height: 20vh;
}
.property-listing-section .property-list-title {
  display: flex;
  align-items: start;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  margin-bottom: 30px;
  gap: 10px;
}

.property-listing-section .property-list-title h1 {
  font-size: 32px;
  margin-bottom: 0;
}
.property-listing-section .grid-view-option ul {
  display: flex;
}

.property-listing-section .grid-view-option ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  padding: 12px;
  z-index: 1;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
}

.property-listing-section .grid-view-option {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  min-width: 214px;
}

.property-listing-section .grid-view-option ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: var(--secondary-theme-color);
  z-index: -1;
  transition: all 300ms ease-in-out;
}

.property-listing-section .grid-view-option ul li.active:before {
  width: 100%;
}

.property-listing-section .grid-view-option ul li.active {
  color: var(--white);
}

.property-listing-section .property-card__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.property-listing-section .property-card__row .property-card__col {
  width: 25%;
  padding-left: 12px;
  padding-right: 12px;
}

.property-listing-section
  .property-card__row.property-list-view
  .property-card__col {
  width: 50%;
}

/*Login*/
.direct-email-options {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.direct-email-options img {
  height: 30px;
}

.direct-email-options .item {
  cursor: pointer;
}
.otp-form .otp-input input {
  width: 45px;
  height: 45px;
  min-height: unset;
  padding: 10px;
  text-align: center;
}
.direct-email-options .item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  padding: 8px 14px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  font-weight: 500;
}
/* Booking */
.booking-banner-text.residence-banner .residence-title-wrap h1 {
  font-size: 28px;
}
.booking-container {
  max-width: 1140px;
}
.rental-info {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  gap: 30px;
  background-color: var(--white);
}

.rental-info .left-col {
  width: calc(66.66% - 30px);
  padding-right: 30px;
  border-right: 1px solid #eee;
}

.rental-info .right-col {
  width: 33.33%;
}

.rental-info .all-payment-amount-details {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}

.rental-info .all-payment-amount-details .item {
  width: 100%;
}

.rental-info .all-payment-amount-details .item h3 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}

.rental-info .all-payment-amount-details .item p {
  margin-bottom: 0;
  opacity: 0.7;
  display: none;
  font-size: 14px;
}

.rental-info .all-payment-amount-details .item .content-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.rental-info .all-payment-amount-details .content-wrap .amount-charges {
  width: 130px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: var(--black);
}

.rental-info .all-payment-amount-details .content-wrap .title {
  width: calc(100% - 150px);
}

.rental-info .all-payment-amount-details .content-wrap .amount-charges svg {
  font-size: 15px;
  margin-right: 3px;
}
.rental-info .all-payment-amount-details .payable-amount {
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.booking-meta-details {
  padding: 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin-bottom: 30px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background: var(--white);
}

.booking-meta-details .booking-details-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.booking-meta-details .booking-details-wrap .title {
  font-weight: 700;
}

.booking-meta-details .booking-details-wrap .sub-title {
  margin-bottom: 0;
  font-weight: 400;
}
.booking-meta-details .token-text {
  font-size: 14px;
}

.booking-meta-details .token-amount {
  display: flex;
  gap: 8px;
  align-items: center;
}

.booking-meta-details .token-amount .amount {
  font-size: 22px;
  font-weight: 600;
  color: var(--secondary-theme-color2);
}

.booking-meta-details .token-amount .amount svg {
  font-size: 20px;
}

.booking-confirmation-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.booking-confirmation-card .title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #008000;
}

.booking-confirmation-card .title h1 {
  font-size: 28px;
  margin-bottom: 0;
  color: #008000;
}

.booking-confirmation-card .title svg {
  font-size: 22px;
}
.bookig-details-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 20px;
}

.bookig-details-grid .content-wrap > span {
  font-size: 14px;
  opacity: 0.8;
}

.bookig-details-grid .content-wrap .bookig-detail {
  font-weight: 600;
  font-size: 14px;
}
.bookig-details-grid .content-wrap .bookig-detail a {
  color: var(--secondary-theme-color);
  text-decoration: underline;
  font-weight: 700;
}
.booking-confirmation-card .inner-title {
  font-size: 22px;
  margin-bottom: 10px;
}

.booking-confirmation-card > div:not(:last-child) {
  margin-bottom: 30px;
}
.payment-details .bookig-details-grid {
  grid-template-columns: repeat(4, 1fr);
}
.booking-confirmation-card .payment-details {
  padding-top: 30px;
  border-top: 1px solid #a5a5a5;
}

/* About Us Page */
.about-us-page {
  padding: 80px 0 80px;
}

.about-us-page .about-banner__row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.about-us-page .about-banner__col {
  width: 50%;
}
.about-banner img {
  border-top-right-radius: 30em;
  border-bottom-right-radius: 30em;
  max-height: 500px;
  padding-right: 50px;
}
.about-us-page .about-banner__col .section-heading {
  max-width: 650px;
  align-items: flex-start;
  margin-bottom: 0;
}
.about-us-page .about-banner__col .section-heading p {
  font-size: 16px;
  opacity: 0.9;
  font-weight: 400;
  margin-bottom: 20px;
}
.text-section {
  padding: 80px 0;
}

.community-section {
  padding: 80px 0;
  background: #f2f8fe;
}

.community-card .community__row {
  display: flex;
  gap: 30px;
  align-items: center;
}

.community-card .community__row .community__col:first-child {
  width: 450px;
}

.community-card .community__row .community__col:last-child {
  width: calc(100% - 480px);
  padding-left: 50px;
}

.community-card .section-heading {
  align-items: flex-start;
  margin-bottom: 0;
}

.community-card .section-heading span,
.about-us-page .about-banner__col .section-heading span {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #2480e7;
}

.community-card .section-heading p {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.9;
  margin-bottom: 16px;
}
.become-part {
  padding: 80px 0;
}
.looking-for-wrap {
  display: flex;
  gap: 50px;
}

.looking-for-wrap > div {
  display: flex;
  padding: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  gap: 20px;
  align-items: center;
  width: 50%;
}

.looking-for-wrap > div .icon {
  width: 150px;
}

.looking-for-wrap > div .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 20px;
}

.become-part .left-content {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
}
.looking-for-wrap > div .wrap {
  width: calc(100% - (160px + 20px));
}
.looking-for-wrap > div a {
  color: var(--secondary-theme-color);
  font-size: 14px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
}
.looking-for-wrap > div a svg {
  position: relative;
  top: 2px;
  font-size: 16px;
}

.faq-page-section {
  padding: 40px 0 80px;
}
.faq-card .accordion-header button svg {
  position: absolute;
  right: 20px;
  transform: rotate(180deg);
}

.faq-card .accordion-header button.collapsed svg {
  transform: rotate(0deg);
}

/* Privacy Policy*/
.privacy-policy-wrap h2 {
  font-size: 24px;
}

.privacy-policy-wrap h3 {
  font-size: 20px;
  margin-bottom: 4px;
}

.privacy-policy-wrap p {
  /* font-size: 14px; */
}
.privacy-policy-wrap .content {
  padding: 0;
}

.privacy-policy-wrap .policy-item:not(:last-child) {
  margin-bottom: 40px;
}
.privacy-policy-wrap .policy-item ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 16px;
}

.privacy-policy-wrap .policy-item ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 7px;
  height: 7px;
  background: #000;
  border-radius: 2px;
}
.privacy-policy-section,
.blog-page-section,
.grediant-section-bg {
  position: relative;
  z-index: 1;
  padding: 80px 0;
}

.privacy-policy-section:before,
.blog-page-section:before,
.grediant-section-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 400px;
  background-image: linear-gradient(180deg, #006ce41c, transparent);
  z-index: -1;
}
.table-of-content {
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: sticky;
  top: 100px;
  max-height: calc(100dvh - 166px);
  overflow-y: auto;
}

.table-of-content h2 {
  font-size: 22px;
  padding: 20px 20px 0;
}

.table-of-content ul li a {
  padding: 10px 20px;
  display: block;
  border-top: 1px solid #d7d7d7;
  font-weight: 600;
  color: #000;
}
.cancellation-policy .privacy-policy-wrap h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

/* Blog Inner */

.blog-inner-section .tags-wrap {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.blog-inner-section .tags-wrap .item {
  font-size: 14px;
  padding: 4px 8px;
  color: var(--secondary-theme-color);
  border: 1px solid #d2e7ff;
  background: #ebf5ff;
  border-radius: 4px;
  font-weight: 600;
}
.blog-banner .blog-banner-img,
.blog-banner .blog-banner-img img {
  width: 100%;
}

.blog-banner .blog-banner-img img {
  height: 450px;
  object-fit: cover;
  border-radius: 8px;
}
.blog-banner .banner-content-wrapper {
  width: 100%;
}

.blog-banner .blog-content-inner h2 {
  font-size: 26px;
}

.blog-banner .blog-content-inner h3 {
  font-size: 22px;
}
.blog-banner .blog-content-inner h4 {
  font-size: 18px;
}
.blog-banner .blog-content-inner h5,
.blog-banner .blog-content-inner h6 {
  font-size: 16px;
}

.blog-banner .blog-content-inner ul li {
  padding-left: 16px;
  position: relative;
  margin-bottom: 10px;
}

.blog-banner .blog-content-inner ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 7px;
  height: 7px;
  background: var(--primary-theme-color);
  border-radius: 100%;
  display: block;
}
.blog-banner .blog-content-inner ul li ul {
  margin-top: 10px;
  font-size: 14px;
}
.blog-properties {
  padding: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: 20px;
}

.blog-properties h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.blog-properties .product-custom .doc-img .property-img img {
  height: 150px;
  border-radius: 10px;
}

.blog-properties .product-custom .pro-content {
  padding: 10px 0 0;
}

.blog-properties .product-custom .profile-widget {
  box-shadow: none;
}

.blog-properties .pro-content p {
  margin-bottom: 0;
}
.blog-properties .pro-content h3 {
  font-size: 16px;
  margin-bottom: 8px;
}
.blog-properties .swiper-button-next,
.blog-properties .swiper-button-prev {
  top: unset;
  bottom: 0px;
  width: 30px;
  height: 30px;
  background: #efefef;
}

.blog-properties > .swiper {
  padding-bottom: 50px;
}

.blog-properties .swiper-button-next:after,
.blog-properties .swiper-button-prev:after {
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  filter: none;
}

.blog-properties .swiper-button-prev {
  left: calc(50% - 40px);
}

.blog-properties .swiper-button-next {
  right: calc(50% - 40px);
}

.schedule-visit-form .schedule-visit-info-title {
  margin-bottom: 10px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  color: var(--secondary-theme-color);
  font-weight: 500;
  cursor: pointer;
}
.schedule-visit-form h2 {
  font-size: 22px;
  text-align: center;
  border-radius: 8px 8px 0 0;
  color: var(--primary-theme-color);
  margin-bottom: 20px;
}
.schedule-visit-form .submit-btn-wrap {
  display: flex;
  gap: 16px;
}
.schedule-visit-form .form-select {
  font-size: 14px;
  border-color: #c1c1c1;
  height: 38px;
}
.visit-result {
  padding: 12px;
  text-align: center;
}

.visit-result .icon svg {
  color: green;
  font-size: 50px;
  margin-bottom: 20px;
}

.visit-result .icon h5 {
  font-size: 22px;
  color: green;
  margin-bottom: 0;
}

.visit-result .icon span {
  color: #818181;
}

.visit-result .icon {
  margin-bottom: 30px;
}

.visit-result .visit-timing {
  font-size: 18px;
  font-weight: 700;
}

.schedule-visit-modal .schedule-visit-form .submit-btn-wrap {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  justify-content: flex-end;
}

.rent-prop-title {
  margin-bottom: 40px;
}
.feedback-icons {
  display: flex;
  gap: 16px;
}

.feedback-icons img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.feedback-icons .feedback-icon {
  position: relative;
}

.feedback-icons .feedback-icon .form-check-input {
  /* position: absolute; */
}

.feedback-icons .form-check .form-check-label {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.feedback-icons .form-check .form-check-label span {
  color: var(--black);
}
.give-feedback {
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
  color: var(--secondary-theme-color);
  cursor: pointer;
}
.news-slider .swiper-slide {
  height: unset;
}
.dont-have-account {
  margin-top: 30px;
  text-align: center;
  font-size: 16px;
}

.dont-have-account a {
  margin-left: 8px;
  font-weight: 700;
}
.no-property-found {
  text-align: center;
  padding: 60px 0;
  margin: 0;
}
.no-property-found h5,
.no-property-found svg {
  font-size: 28px;
}

.no-property-found svg {
  color: #fcaf3d;
  margin-bottom: 20px;
  font-size: 32px;
}

.bookig-detail-address {
  font-weight: 600;
  font-size: 14px;
}
.content-wrap-property > span {
  font-size: 14px;
  opacity: 0.8;
}
.action-button-wrraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-button-wrraper .edit-btn {
  color: green;
}

.action-button-wrraper .delete-btn {
  color: red;
}

.action-button-wrraper .btn {
  padding: 0;
}
.whatsapp-wrraper span {
  color: #000000;
  display: block;
  font-weight: 600;
  font-size: 14px;
}
.validation-error {
  color: red;
  font-size: 12px;
}

/* my-kyc */

.file-upload-wrap {
  margin-top: 20px;
}

.dotted-upload {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  color: #666;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.dotted-upload:hover {
  background-color: #f9f9f9;
}

.file-input {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.file-list li {
  color: #333;
  margin: 5px 0;
}
.dotted-file-upload-wrap {
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
}

.upload-data-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
}
.email-display {
  padding: 9px 15px;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pro-content .wishlist {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.avail-from {
  position: absolute;
  top: 10px;
  z-index: 2;
  left: 10px;
  background: #fff;
  padding: 0 8px;
  border-radius: 4px;
}
.avail-from {
  font-size: 10px;
}
.avail-from .list {
  font-weight: 300;
}

.avail-from .date {
  color: #d71818;
  font-weight: 500;
  font-size: 12px;
}

/* New Card UI */
.pro-content .details {
  padding: 0;
  background: transparent;
  margin-bottom: 0;
}

.pro-content .property-category .view-more {
  display: none;
}

.pro-content .property-price {
  margin-bottom: 0;
  line-height: 22px;
  font-size: 16px;
  white-space: nowrap;
  color: #d71818;
}
.price-location-wrap {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pro-content .property-price svg {
  font-size: 13px;
  margin-right: 2px;
}

.feedback-options .icon-wrap input {
  position: absolute;
  top: 0;
  opacity: 0;
}

.feedback-options .icon-wrap {
  position: relative;
}

.feedback-options .icon-wrap input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.feedback-options .icon-wrap input:checked + label {
  opacity: 1;
}
.feedback-options .icon-wrap label {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;
  opacity: 0.7;
  align-items: center;
  transition: opacity 0.3s ease;
}

.feedback-options .icon-wrap label span {
  color: #000;
}
.verified {
  position: absolute;
  bottom: -35px;
  right: 10px;
  z-index: 1;
}

.verify-info-popup .modal-header {
  padding: 10px 16px;
}

.verify-info-popup .modal-header h4 {
  font-size: 22px;
}
.visit-result .video-wrap {
  position: relative;
  cursor: pointer;
}

.visit-result .video-wrap svg {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 100%;
}

.PhoneInput--disabled .PhoneInputCountrySelect {
  display: contents;
}

/* recepit */
.invoice-list-blk .card {
  border-radius: 10px;
}

.invoice-item .invoice-logo {
  margin-bottom: 30px;
}

.invoice-item .invoice-logo img {
  width: auto;
  max-height: 80px;
}

.invoice-item .invoice-text h2 {
  color: #161c2d;
  font-size: 36px;
  font-weight: 600;
}

.invoice-item .invoice-details {
  text-align: right;
  font-weight: 500;
}

.invoice-item .invoice-details strong {
  color: #161c2d;
}

.invoice-item .invoice-details-two {
  text-align: left;
}

.invoice-item .invoice-text {
  padding-top: 42px;
  padding-bottom: 36px;
}

.invoice-item .invoice-text h2 {
  font-weight: 400;
}

.invoice-info {
  margin-bottom: 30px;
}

.invoice-info p {
  margin-bottom: 0;
}

.invoice-info.invoice-info2 {
  text-align: right;
}

.invoice-item .customer-text {
  font-size: 18px;
  color: #161c2d;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
}

.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
  color: #161c2d;
  font-weight: 600;
  padding: 10px 20px;
  line-height: inherit;
}

.invoice-table tr td,
.invoice-table-two tr td {
  font-weight: 500;
}

.invoice-table-two {
  margin-bottom: 0;
}

.invoice-table-two tr th,
.invoice-table-two tr td {
  border-top: 0;
}

.invoice-table-two tr td {
  text-align: right;
}

.invoice-info h5 {
  font-size: 16px;
  font-weight: 500;
}
.receipt-pdf-wrraper {
  display: flex;
  justify-content: space-between;
}

.min-h-300 {
  min-height: 300px;
}

/* Property type Slider */
.property-type-slider .swiper {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.property-type-slider
  .swiper
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #fff;
}

.property-type-slider
  .swiper
  .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background: #fff;
}
.preffered-tanets {
  display: none;
}
.property-list-view .preffered-tanets {
  display: block;
}
.preffered-tanets ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 12px;
  margin-top: 16px;
}
.preffered-tanets ul li {
  padding: 2px 4px;
  border-radius: 4px;
}
.preffered-tanets ul li:nth-child(3n + 1) {
  background: #ffdeff;
  color: #700270;
}
.preffered-tanets ul li:nth-child(3n + 2) {
  background: #ffebeb;
  color: #f91616;
}

.preffered-tanets ul li:nth-child(3n + 3) {
  background: #f3e7ff;
  color: #580da1;
}
.dotted-upload .file-input {
  position: absolute;
  left: 0;
  top: 0;
}

.feedback-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.feedback-list .card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.star-rating {
  font-size: 1.5em;
  display: inline-flex;
  gap: 4px;
}

.feedback-icon-wrraper {
  display: flex;
  justify-content: space-between;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.no-data-found {
  text-align: center;
  font-size: 20px;
}

/* Timeline */
.custom-modal-title h2 {
  font-size: 22px;
  border-radius: 8px 8px 0 0;
  color: var(--primary-theme-color);
  margin-bottom: 20px;
}
.support-timeline-dialog .MuiDialogContent-root {
  padding: 16px;
}

.timeline-card {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.timeline-card .ticket-badge {
  margin-bottom: 4px;
  text-transform: uppercase;
  line-height: 14px;
}
.st-info {
  display: flex;
  flex-wrap: wrap;
}
.st-info li:not(:last-child) {
  border-right: 1px solid #eee;
  margin-right: 8px;
  padding-right: 8px;
}
.please-wait-loader {
  padding: 80px 0;
  text-align: center;
  font-size: 40px;
  min-height: 50vh;
}
.dashboard-link {
  margin-top: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.user-guide-note {
  background: #f5f7fa;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #438af1;
}

.user-guide-note h4 {
  font-size: 18px;
  color: #438af1;
}

.user-guide-note .content-wrap > ul {
  margin: 0;
  padding: 0;
}

.user-guide-note .content-wrap > ul li .wrap {
  display: flex;
  gap: 10px;
}

.user-guide-note .content-wrap > ul > li {
  margin-bottom: 10px;
}

.user-guide-note .content-wrap > ul > li:last-child {
  margin-bottom: 0;
}

.user-guide-note .content-wrap > ul > li .wrap svg {
  color: #438af1;
  font-size: 15px;
  position: relative;
  top: 4px;
}

.user-guide-note .content-wrap > ul .mandate-list {
  padding-left: 40px;
  margin-top: 4px;
  list-style: initial;
}

section.loading {
  min-height: 50vh;
  padding: 100px;
}
.verification-status ul {
  display: flex;
  justify-content: space-between;
}

.verification-status ul .icon {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: #e6ecff;
  border-radius: 100%;
  color: #12086f;
  margin-bottom: 10px;
}

.verification-status ul .text {
  font-weight: 500;
  opacity: 0.4;
}

.verification-status ul li {
  max-width: 80px;
  text-align: center;
  width: 80px;
  position: relative;
  z-index: 1;
}

.verification-status ul li:not(:last-child):before {
  content: "";
  position: absolute;
  width: 310px;
  height: 1px;
  background: #c7c7c7;
  left: 50px;
  top: 22px;
  z-index: -1;
}
.verification-status ul li.status-verified .icon {
  background: var(--primary-theme-color);
  color: var(--white);
}
.verification-status ul li.status-verified .text {
  opacity: 1;
}
.verification-status ul li.status-verified::before {
  background: var(--primary-theme-color);
}

.verification-text {
  margin-top: 40px;
}

.verification-text a {
  font-weight: 600;
  text-decoration: underline;
  color: #ff2e2e;
}
.download-agreement .modal-title.h4 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Mukta", sans-serif !important;
  color: green;
}
.download-agreement .modal-body {
  padding-bottom: 40px;
}
.pdf-preview-wrapper .pdf-iframe {
  width: 100%;
  height: 100%;
}
.download-agreement.preview-document .modal-content {
  height: calc(100dvh - (20px + 40px));
}

.download-agreement .modal-content .modal-body {
  height: calc(100% - 70px);
}

.download-agreement .modal-content .agreement-wrap {
  height: 100%;
}

.download-agreement .modal-content .pdf-preview-wrapper {
  height: calc(100% - 70px);
  border-bottom: 1px solid #525659;
}
.agreement-wrap .sign-doc {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.wraping-payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper-payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.verify-passport {
  height: 41px;
}

.pdf-iframe.demo {
  width: 100%;
  height: 60vh;
}
.orange-link {
  color: var(--secondary-theme-color);
}
