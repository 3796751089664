/* My Account */
.my-account-section {
  /* background: #fbfaff; */
}
.my-account-section .form-control,
.my-account-section .PhoneInput {
  min-height: 36px;
  border-color: #c9c9c9;
}
.my-account-section .PhoneInput {
  padding-top: 0;
  padding-bottom: 0;
}
.my-account-section .form-label {
  font-size: 14px;
  position: relative;
}
.my-account-sidebar {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 90px;
}

.my-account-sidebar .sidebar-list li {
  border-bottom: 1px solid #c7c7c7;
}

.my-account-sidebar .sidebar-list li img {
  height: 20px;
}

.my-account-sidebar .sidebar-list li a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--black);
  padding: 16px 20px;
  font-size: 15px;
  border-left: 5px solid transparent;
}
.my-account-sidebar .sidebar-list li a.active {
  border-color: #006ce4;
}
.my-account-sidebar .sidebar-list li:last-child {
  border-bottom: none;
}
.my-account-title h2 {
  font-size: 28px;
  margin-bottom: 20px;
}
.my-account-subtitle h3 {
  font-size: 24px;
}
.my-account-title .sub-title {
  font-weight: 500;
  font-size: 14px;
  opacity: 0.8;
}
.profile-info-fields {
  position: relative;
  z-index: 1;
  margin-top: 30px;
}
.profile-info-fields:before {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  width: 100%;
  height: 1px;
  background: #c7c7c7;
  z-index: -1;
}
.profile-info-fields h4 {
  background: var(--white);
  margin-bottom: 20px;
  font-size: 20px;
  display: inline-flex;
  padding-right: 10px;
}

.save-btn,
.buttons-wrap {
  margin: 0 -20px;
  padding: 20px 20px 0;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

/* Account Card */
.account-card .nav-tabs {
  border: none;
  padding: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.account-card .nav-tabs button.active {
  background: var(--secondary-theme-color);
  color: var(--white);
}

.account-card .nav-tabs button {
  border-radius: 8px;
  color: var(--secondary-theme-color);
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
}
.account-card .tab-content .tab-pane {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

/* Flatmate Details */
.flatmate-details-wrap .my-account-title,
.witness-detail-wrap .my-account-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.witness-detail-wrap .my-account-title button,
.flatmate-details-wrap .my-account-title button {
  padding: 8px 20px;
  font-size: 14px;
}
.witness-detail-wrap .profile-info-wrap,
.flatmate-details-wrap .profile-info-wrap {
  margin-bottom: 30px;
}
.flatmate-details-wrap .buttons-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

/* Wishlist */
.wishlist-property .product-custom .doc-img .property-img img {
  height: 160px;
}

.wishlist-property .pro-content .details li {
  font-size: 10px;
}

.wishlist-property .pro-content .list {
  font-size: 14px;
}

.wishlist-property .view-more {
  font-size: 14px;
  white-space: nowrap;
  display: none;
}

.wishlist-property .pro-content {
  padding: 10px;
}

/* Assigned Property */
.property-detail .title h2 {
  font-size: 18px;
  margin-bottom: 0;
}

.property-detail .title .address {
  font-size: 12px;
  opacity: 0.8;
}

.property-detail .title .address svg {
  color: #525252;
  opacity: 1;
}

.property-detail .title {
  margin-bottom: 10px;
}

.property-detail .rent-meta-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 430px;
  gap: 8px 16px;
  margin-top: 20px;
}

.property-detail .rent-meta-list li svg {
  font-size: 12px;
  margin-right: 0px;
}

.property-detail-wrap {
  padding: 16px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.property-detail-wrap img {
  border-radius: 6px;
  height: 170px;
  width: 100%;
  object-fit: cover;
}

.rent-meta-details {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-end;
}

.property-detail .rent-meta-list li {
  font-size: 14px;
}

.rent-meta-details .view-prop-details a {
  font-size: 14px;
  background: var(--secondary-theme-color);
  color: var(--white);
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;
  border-radius: 6px;
}
.property-detail .review-btn a button {
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
}
.renewal-property {
  margin-top: 20px;
}

.assigned-assets {
  margin-top: 30px;
}

.assigned-assets ul {
  display: flex;
  gap: 16px;
}

.assigned-assets ul li svg {
  color: green;
  margin-right: 5px;
}

.building-type {
  font-size: 14px;
  margin-top: 10px;
  border: 1px solid #a0cdff;
  font-weight: 600;
  display: inline-block;
  padding: 2px 6px;
  background: #f0f7ff;
  line-height: 22px;
  color: var(--secondary-theme-color);
  border-radius: 4px;
}

.bill-details__row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}

.bill-details__row .card {
  padding: 12px;
  border: 1px solid #eee;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin-bottom: 0;
  height: 100%;
}

.bill-details__row .card .bill-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 4px;
}

.bill-details__row .card .bill-info {
  opacity: 0.8;
  font-size: 12px;
}

.bill-details__row .card .bill-amount {
  color: green;
  font-weight: 700;
  font-size: 18px;
}

.bill-details__row .card .bill-amount svg {
  font-size: 14px;
  margin-right: 2px;
}

/** Support **/
/* .assigned-agents__row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.assigned-agents__row .assigned-card {
  padding: 18px 16px 10px;
  border: 1px solid var(--secondary-theme-color);
  border-radius: 12px;
  position: relative;
}

.assigned-agents__row .assigned-card .title {
  position: absolute;
  top: -12px;
  display: inline-flex;
  background: #fff;
  left: 16px;
  padding: 0 4px;
  font-weight: 700;
  color: var(--secondary-theme-color);
}

.assigned-agents__row .assigned-card .agent-details .name {
  display: flex;
  gap: 8px;
  margin-bottom: 2px;
  font-size: 14px;
}

.assigned-agents__row .assigned-card .agent-details .name span {
  font-weight: 700;
}
.assigned-agents__row .assigned-card .agent-details .name a {
  color: green;
  text-decoration: underline;
}
.assigned-agents__row .assigned-card .agent-details .name span svg {
  font-size: 12px;
}
.assigned-agents__row .assigned-card .agent-details .name span svg {
  font-size: 12px;
} */

.support-detail-wrap .assigned-agents {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* feedback */
.feedback-wrapper .my-account-title,
.assigned-property-wrap .my-account-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.feedback-wrapper .my-account-title h2,
.assigned-property-wrap .my-account-title h2 {
  margin-bottom: 0;
}
.whatsapp-display {
  border: 1px solid #c9c9c9;
  padding: 9px 10px;
  border-radius: 0.25rem;
}
.my-account-section .PhoneInput input {
  height: 39px;
}
.download-agreement-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;
}

.download-agreement-wrap .agreement-note {
  font-size: 12px;
}

.download-agreement-wrap .agreement-note a {
  color: var(--secondary-theme-color);
  text-decoration: underline;
  font-weight: 500;
}

.download-agreement .agreement-note a {
  color: var(--secondary-theme-color);
  text-decoration: underline;
  font-weight: 500;
}
.wrraper-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrraper-buttons-duration {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.span-file-size {
  padding-top: 10px;
  display: inline-block;
}
