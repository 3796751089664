.resend-otp {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}
.resend-button-wrraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.whatsapp-wrraper {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  align-items: center;
}
