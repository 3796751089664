@media (max-width: 1365px) {
  .benefits__row {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
  }
  .product-custom .doc-img .property-img img,
  .product-custom .pro-img .property-img img {
    height: 230px;
  }
  .get-app .easy-stay-card {
    padding: 30px;
  }
  .get-app .easy-stay-card .image img {
    max-height: 370px;
  }

  .property-list-view .pro-content p {
    font-size: 11px;
  }

  .property-list-view .pro-content .property-price {
    font-size: 15px;
    margin-bottom: 12px;
  }

  .property-list-view .product-custom .doc-img {
    width: 200px;
  }

  .property-list-view .product-custom .pro-content {
    width: calc(100% - 210px);
  }

  .property-list-view .pro-content p svg {
    font-size: 10px;
  }
}
@media (max-width: 1199px) {
  .howit-work,
  .property-type-sec,
  .feature-property-sec,
  .cities-list-sec,
  .main-property-sec,
  .testimonial-sec,
  .faq-section,
  .latest-blog-sec,
  .about-us-page,
  .text-section,
  .community-section,
  .become-part {
    padding: 50px 0;
  }

  .own-building {
    padding: 30px 0 50px;
  }

  .property-type-sec .section-heading p {
    font-size: 14px;
  }

  .section-heading {
    margin-bottom: 30px;
  }

  .social-media {
    padding-bottom: 50px;
  }
  .footer .container {
    max-width: 100%;
    min-width: unset;
  }
  .property-card {
    padding: 20px 30px;
  }

  .property-card .property-img img {
    height: 50px;
  }
  .section-heading h2 {
    font-size: 32px;
    line-height: 36px;
  }
  .product-custom .doc-img .property-img img,
  .product-custom .pro-img .property-img img {
    height: 150px;
  }

  .areas__row {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .pro-content .property-price {
    font-size: 15px;
  }
  .pro-content .details {
    font-size: 12px;
  }

  .product-custom .profile-widget .property-category li:first-child {
    display: flex;
    flex-direction: column;
  }
  .footer-bottom-content p,
  .footer-bottom .right-content ul a {
    font-size: 13px;
  }
  .about-us-page .about-banner__col .section-heading p {
    font-size: 14px;
  }

  .section-heading h2 {
    font-size: 30px;
    line-height: 34px;
  }

  .banner-content h1 {
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 10px;
  }
  .banner-content span {
    font-size: 20px;
  }

  .banner-content p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .community-card .section-heading span,
  .about-us-page .about-banner__col .section-heading span {
    font-size: 13px;
  }
  .about-banner img {
    min-height: 330px;
    object-fit: cover;
  }
  .community-card .community__row .community__col:first-child {
    width: 350px;
  }
  .community-card .community__row .community__col:last-child {
    width: calc(100% - 350px);
    padding-left: 20px;
  }
  .become-part .left-content {
    font-size: 26px;
    line-height: 30px;
  }

  .looking-for-wrap > div .icon {
    width: 110px;
  }

  .looking-for-wrap > div .wrap {
    width: calc(100% - (110px + 10px));
  }

  .looking-for-wrap > div {
    gap: 10px;
  }
  .looking-for-wrap > div .title {
    font-size: 18px;
    font-weight: 600;
  }
  .footer-widget-list ul li a {
    font-size: 11px;
  }
  .custom-dropdown .custom-dropdown-title,
  .filter-btn,
  .sort-btn {
    padding: 0 10px;
    height: 40px;
    font-size: 14px;
  }

  .custom-dropdown .filter-modal-body {
    padding: 14px;
  }

  .custom-dropdown .custom-filter-modal-wrapper {
    width: 400px;
  }
  .search-wrap .custom-filter-modal-wrapper .locality-list-item {
    width: calc(50% - 8px);
  }
  .custom-dropdown .locality-list-item,
  .filter-list-item {
    white-space: unset;
    word-break: break-all;
  }
  .property-filter-section .filter-card .search-wrap input {
    min-height: 40px;
    padding: 0 12px;
  }

  .banner-search .banner-tab .nav-tabs .nav-item .nav-link {
    padding: 12px;
    font-size: 14px;
  }

  .banner-search .banner-tab .nav-tabs .nav-item .nav-link img {
    height: 17px;
  }

  .banner-search .banner-tab-property {
    padding: 20px;
  }

  .banner-section {
    padding: 80px 0;
  }

  .banner-property-grid .custom-dropdown .custom-filter-modal-wrapper {
    width: 440px;
  }
  .residency-details-section .residency-details-wrap .left-col {
    width: calc(100% - 330px);
  }
  .residency-details-section .residency-details-wrap .right-col {
    width: 330px;
  }
  .property-list-view .product-custom .pro-content .view-more {
    display: none;
  }

  .property-list-view
    .product-custom
    .profile-widget
    .property-category
    li:first-child {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .property-list-view .product-custom .doc-img {
    width: 200px;
  }

  .property-list-view .product-custom .pro-content {
    width: calc(100% - 200px);
  }
  .my-account-title h2 {
    font-size: 24px;
  }

  .bill-details__row .card .bill-amount {
    font-size: 18px;
  }

  .bill-details__row .pay-bill button {
    font-size: 14px;
    padding: 4px 8px;
    line-height: 18px;
  }

  .bill-details__row .card .bill-amount svg {
    font-size: 14px;
  }
  .property-detail .review-btn a button {
    font-size: 13px;
    white-space: nowrap;
  }
  .property-detail .title h2 {
    font-size: 16px;
  }

  .wishlist-property .pro-content .details {
    display: none !important;
  }

  .wishlist-property .view-more {
    display: none;
  }

  .wishlist-property
    .product-custom
    .profile-widget
    .property-category
    li:first-child {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .wishlist-property .pro-content .list {
    font-size: 13px;
  }
  .pro-content .details li {
    font-size: 12px;
  }
  .pro-content {
    padding: 12px;
  }
  .avail-from .date {
    font-size: 10px;
  }
  .property-listing-section .property-list-title h1 {
    font-size: 26px;
  }
}
@media (max-width: 991px) {
  .booking-banner-text.residence-banner .residence-title-wrap h1 {
    font-size: 24px;
  }

  .rental-info .left-col,
  .rental-info .right-col {
    width: 100%;
  }

  .rental-info .proceed-button {
    margin-top: 30px;
  }

  .rental-info .left-col {
    padding: 0;
    border: none;
  }
  .rental-info .left-col .inner-title {
    font-size: 24px;
  }
  .banner-property-grid .custom-dropdown .custom-filter-modal-wrapper {
    width: 400px;
    left: unset;
    right: 0;
  }
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:last-child {
    width: 50px;
    margin-bottom: 0;
  }

  .banner-search .banner-tab-property .banner-property-info {
    gap: 10px;
  }

  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:not(:last-child) {
    width: calc(100% - 40px);
    margin-bottom: 0;
  }
  .bg-09 {
    display: none;
  }
  .custom-card h3 {
    font-size: 16px;
  }

  .custom-card {
    padding: 10px;
  }

  .benefits__row {
    gap: 16px;
  }

  .custom-card p {
    font-size: 12px;
  }

  .howit-work .container {
    /* max-width: 98%; */
  }

  .section-heading p {
    font-size: 14px;
  }

  .section-heading h2 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .product-custom .doc-img .property-img img,
  .product-custom .pro-img .property-img img {
    height: 220px;
  }
  .counter-sec .counter-box .counter-icon img {
    height: 50px;
  }

  .counter-sec .counter-box h3 {
    font-size: 26px;
  }

  .counter-sec .counter-box h5 {
    font-size: 16px;
  }

  .counter-sec {
    padding: 20px 0 0 !important;
  }
  .get-app .easy-stay-card .image img {
    max-height: 280px;
    right: 0;
  }

  .get-app .easy-stay-card .app-info-wrap .app-qr img {
    object-fit: contain;
    margin-top: -85px;
    border-radius: 23px;
  }
  .easy-stay-card ul.content-list {
    margin-top: 8px;
  }

  .get-app .easy-stay-card {
    padding: 20px;
  }
  .main-property-sec {
    padding-top: 30px;
  }
  .testimonial-slider .testimonial-card {
    padding: 16px;
    gap: 0;
  }

  .testimonial-card p {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .testimonial-card h4 {
    font-size: 18px;
    margin-bottom: 0;
  }

  .testimonial-card .rating span svg {
    font-size: 12px;
  }

  .testimonial-card .rating span {
    margin-right: 0;
  }

  .faq-section .faq-img {
    display: none;
  }

  .faq-card .accordion-header button {
    font-size: 17px;
    padding: 12px;
  }

  .faq-card .collapse.show .accordion-body {
    margin: 0 12px;
  }
  .blog-card .blog-content {
    padding: 12px;
  }

  .blog-card .blog-content .property-category a span {
    height: 35px;
    width: 35px;
  }
  .blog-card .blog-content .blog-property span {
    font-size: 12px;
    padding: 2px 8px;
  }
  .latest-blog-sec {
    padding: 50px 0 20px;
  }
  .footer-widget-list ul li {
    margin-bottom: 5px;
  }

  .footer-widget-list {
    margin-bottom: 0;
  }
  .footer-bottom-content {
    flex-direction: column-reverse;
    gap: 8px;
  }
  .looking-for-wrap {
    gap: 20px;
  }
  .blog-inner-section .tags-wrap .item {
    font-size: 12px;
    padding: 4px 8px;
  }
  .blog-banner .blog-banner-contenthead {
    margin-bottom: 20px;
    padding-bottom: 12px;
  }

  .blog-banner .blog-banner-content {
    margin-top: 20px;
  }
  .table-of-content {
    display: none;
  }
  .table-of-content {
    display: none;
  }
  .contact-info-sec .card .card-header h3 {
    margin-bottom: 10px;
    font-size: 24px;
  }
  .property-filter-section .filter-card .filters-wrap {
    display: none;
  }
  .property-listing-section .property-card__row .property-card__col {
    width: 50%;
  }

  .property-listing-section .property-list-title h1 {
    font-size: 26px;
  }
  .property-listing-section .grid-view-option ul li {
    padding: 10px 10px;
    white-space: nowrap;
  }

  .property-filter-section {
    padding-bottom: 20px;
  }
  .footer .footer-top .footer-content-heading h4 {
    margin-bottom: 9px;
  }
  .footer-bottom-content .right-content {
    display: none;
  }
  .residence-banner .residence-title-wrap h1 {
    font-size: 22px;
    line-height: 24px;
  }

  .residence-banner .residence-title-wrap .right-col button,
  .residence-banner .residence-title-wrap .right-col a {
    padding: 6px 12px;
    font-size: 14px;
  }

  .residence-banner .residence-title-wrap .contact-owner {
    display: flex;
    justify-content: flex-end;
  }
  .residency-details-section .residency-details-wrap .left-col {
    width: 100%;
    padding-right: 0;
  }

  .residency-details-section .residency-details-wrap .right-col {
    width: 100%;
  }

  .residence-banner .show-all-image-btn button {
    padding: 6px 12px;
    font-size: 14px;
  }

  .residence-banner .lg-react-element.gallery .gallery__item:first-child img {
    height: 300px;
  }

  .residence-banner .banner-thumb-image img,
  .residence-banner .lg-react-element.gallery img {
    height: 138px;
  }
  .residence-banner .banner-thumb-image.left-image img,
  .residence-banner
    .lg-react-element.gallery
    .gallery__item.banner-col-grid-layout
    img {
    height: 300px;
  }
  .blog-properties {
    display: none;
  }
  .blog-properties {
    display: none;
  }

  .blog-banner .looking-for-wrap > div {
    width: 50%;
  }

  .blog-banner .looking-for-wrap {
    flex-direction: row !important;
  }
  .residency-details-wrap .submit-btn-wrap button {
    width: auto;
    min-width: 150px;
  }
  .nearby-areas-properties .nearby-areas-properties-item h3 {
    gap: 4px;
    font-size: 17px;
  }
  .property-list-view .product-custom .doc-img {
    width: 150px;
  }

  .property-list-view .product-custom .pro-content {
    width: calc(100% - 150px);
  }

  .property-list-view .pro-content h3 {
    line-height: 19px;
  }

  .property-list-view .product-custom .pro-content .details {
    display: none !important;
  }

  .property-list-view .product-custom .doc-img .property-img img {
    height: 170px;
  }

  .property-list-view .pro-content .property-price {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .property-list-view .pro-content .date,
  .property-list-view .pro-content .list {
    font-size: 12px;
  }
  .property-listing-section .grid-view-option {
    min-width: 206px;
  }
  .property-filter-modal .modal-dialog {
    max-width: 90%;
  }
  .footer-bottom-content p,
  .footer-bottom .right-content ul a {
    margin-bottom: 0;
    text-align: center;
  }

  .footer-bottom-content {
    justify-content: center;
    align-items: center;
  }
  .footer .footer-top .container > .row > div:first-child {
    order: 2;
  }
  .footer .footer-top .container > .row > div:nth-child(2) {
    order: 3;
  }
  .footer .footer-top .container > .row > div:nth-child(3) {
    order: 4;
  }
  .footer .footer-top .container > .row > div:nth-child(4) {
    order: 5;
  }
  .footer .footer-top .container > .row > div:nth-child(5) {
    order: 1;
  }
  .footer .footer-top {
    display: none;
  }
  .booking-container .my-account-sidebar,
  .user-profile .dropdown-menu .dropdown-item img {
    display: none;
  }
  .my-account-title h2 {
    margin-bottom: 10px;
  }

  .flatmate-details-wrap .my-account-title,
  .witness-detail-wrap .my-account-title {
    margin-bottom: 0;
  }

  .account-card .tab-content .tab-pane {
    padding: 16px;
  }
  .account-card > .my-account-title {
    margin-bottom: 20px;
  }
  .account-card .tab-pane .my-account-title {
    margin-bottom: 10px;
  }
  .account-card .tab-pane .my-account-title h2 {
    font-size: 20px;
  }
  .my-account-section {
    padding: 30px 0;
  }
  .building-type {
    margin-top: 0;
  }

  .property-detail .rent-meta-list {
    margin-top: 10px;
  }

  .rent-meta-details {
    gap: 12px;
  }
  .bill-details__row {
    grid-template-columns: repeat(3, 1fr);
  }
  .footer-main-links .footer-widget-list ul li a {
    font-size: 12px;
    color: #fff;
    gap: 8px;
  }

  .footer-main-links .footer-widget-list ul li a svg {
    position: relative;
    top: 3px;
  }

  .footer .footer-top .footer-main-links .footer-content-heading h4 {
    font-size: 16px;
    text-align: center;
  }
  .footer-tab-about ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    justify-content: center;
  }
  .login-wrapper {
    min-height: calc(100dvh - (70px + 50px));
  }
  .verification-status ul li:not(:last-child):before {
    width: 130px;
  }
  .header .header-navbar-rht.logged-out li:nth-child(1),
  .header .header-navbar-rht.logged-out li:nth-child(2),
  .header .header-navbar-rht.logged-out li:nth-child(3) {
    display: none;
  }
  .property-listing-section {
    min-height: calc(100svh - 355px);
  }
}
@media (max-width: 767px) {
  .header .header-navbar-rht li.new-property-btn a {
    white-space: nowrap;
  }
  .header .header-navbar-rht {
    flex-wrap: nowrap;
  }
  .howit-work,
  .property-type-sec,
  .feature-property-sec,
  .cities-list-sec,
  .main-property-sec,
  .testimonial-sec,
  .faq-section,
  .latest-blog-sec,
  .about-us-page,
  .text-section,
  .community-section,
  .become-part {
    padding: 40px 0;
  }
  .privacy-policy-section,
  .blog-page-section,
  .grediant-section-bg {
    padding: 50px 0;
  }
  .banner-section:before {
    /* background: linear-gradient(80deg, #f1f1f1 30%, transparent); */
  }
  .booking-meta-details .token-amount {
    flex-direction: column;
    min-width: 100px;
    align-items: self-end;
    justify-content: flex-start;
    gap: 0;
  }

  .booking-meta-details {
    align-items: flex-start;
  }

  .booking-meta-details .booking-details-wrap {
    row-gap: 10px;
    column-gap: 20px;
  }

  .booking-meta-details .token-amount .amount {
    font-size: 18px;
  }

  .booking-meta-details .token-amount .amount svg {
    font-size: 16px;
  }
  .areas__row {
    grid-template-columns: repeat(3, 1fr);
  }

  .areas__row .areas__col a {
    padding: 12px;
  }

  .areas__row .areas__col h3 {
    font-size: 16px;
  }
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:not(:last-child) {
    width: calc(50% - 6px);
  }

  .banner-search .banner-tab-property .banner-property-info {
    flex-wrap: wrap;
  }

  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:nth-child(3) {
    width: calc(100% - 67px);
  }

  .banner-property-grid .custom-dropdown .custom-filter-modal-wrapper {
    width: 100%;
    min-width: 320px;
    left: 0;
    right: unset;
  }
  .banner-property-grid .locality-list li {
    font-size: 12px;
  }
  .banner-property-grid .custom-dropdown .filter-modal-body {
    max-height: 215px;
  }
  .benefits__row {
    grid-template-columns: repeat(2, 1fr);
  }
  .own-building .section-heading {
    padding-right: 0;
    align-items: center !important;
    margin-bottom: 30px !important;
  }

  .own-building .section-heading h3 {
    margin: 20px 0 10px;
    animation: 0;
  }

  .own-building .section-heading p {
    text-align: center;
  }

  .own-building {
    padding: 10px 0 40px;
  }
  .banner-content h1 {
    font-size: 30px;
    line-height: 34px;
  }

  .banner-content span {
    font-size: 18px;
  }

  .banner-content p {
    margin-bottom: 20px;
    font-size: 15px;
  }
  .pro-content {
    padding: 12px;
  }

  .bg-03 {
    display: none;
  }

  .product-custom .doc-img .property-img img,
  .product-custom .pro-img .property-img img {
    height: 180px;
  }

  .pro-content h3 {
    font-size: 14px;
    line-height: 18px;
  }

  .pro-content p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .pro-content p svg {
    font-size: 12px;
  }

  .pro-content .property-price {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .pro-content .details {
    margin-bottom: 10px;
  }
  .pro-content .view-more,
  .bg-02 {
    display: none;
  }

  .pro-content .list,
  .pro-content .date {
    font-size: 13px;
  }
  .product-custom .profile-widget .property-category li:first-child {
    flex-direction: row;
    gap: 4px;
  }
  .cities-list-sec .section-heading {
    text-align: center;
  }

  .section-heading {
    margin-bottom: 20px;
  }
  .counter-sec .counter-box h3 {
    font-size: 22px;
  }

  .counter-sec .counter-box h5 {
    font-size: 14px;
  }
  .counter-sec .counter-box {
    margin-bottom: 0;
  }
  .get-app .easy-stay-card .image img {
    display: none;
  }
  .about-us-page .about-banner__col {
    width: 100%;
  }

  .about-us-page .about-banner__col .section-heading {
    padding: 0 12px;
    margin-bottom: 30px;
  }

  .about-us-page .about-banner__row {
    flex-direction: column-reverse;
  }

  .about-para p {
    font-size: 14px;
  }

  .community-card .community__row {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .community-card .community__row .community__col:last-child {
    width: 100%;
    padding-left: 0;
  }

  .looking-for-wrap > div .icon {
    width: 80px;
  }

  .looking-for-wrap > div .wrap {
    width: calc(100% - (80px + 10px));
  }

  .looking-for-wrap > div .title {
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 20px;
  }
  .custom-dropdown .locality-list-item,
  .filter-list-item {
    padding: 5px 12px;
    margin-top: 6px;
    font-size: 12px;
  }

  .property-filter-modal .filter-body-wrap:not(:last-child) {
    margin-bottom: 20px;
  }

  .property-filter-modal .filter-body-wrap h2 {
    font-size: 16px;
  }

  .property-filter-modal .modal-title {
    font-size: 15px;
  }
  .property-listing-section
    .property-card__row.property-list-view
    .property-card__col {
    width: 100%;
  }

  .property-list-view .product-custom .doc-img {
    width: 190px;
  }

  .property-list-view .product-custom .pro-content {
    width: calc(100% - 190px);
  }
  .property-listing-section .property-list-title h1 {
    font-size: 22px;
    line-height: 26px;
  }
  .residency-details-wrap .property-overview {
    grid-template-columns: repeat(4, 1fr);
  }
  .residence-banner .lg-react-element.gallery {
    display: block;
  }
  .header .header-navbar-rht li .btn {
    padding: 5px 12px;
    min-width: unset;
  }
  .custom-breadcrumb .bread-crumb-list a,
  .custom-breadcrumb .bread-crumb-list span {
    font-size: 12px;
  }
  p {
    font-size: 14px;
  }
  .blog-banner .blog-content-inner h2 {
    font-size: 22px;
  }

  .blog-banner .blog-content-inner h3 {
    font-size: 18px;
  }
  .blog-banner .blog-content-inner h4 {
    font-size: 16px;
  }
  .blog-banner .blog-content-inner h5,
  .blog-banner .blog-content-inner h6 {
    font-size: 14px;
  }
  .privacy-policy-wrap h2,
  .cancellation-policy .privacy-policy-wrap h2 {
    font-size: 22px;
  }
  .cancellation-policy .privacy-policy-wrap h2 {
    text-align: center;
  }

  .privacy-policy-wrap .policy-item:not(:last-child) {
    margin-bottom: 20px;
  }
  .property-detail-wrap .property-image {
    margin-bottom: 20px;
  }

  .p-datatable .p-datatable-thead > tr > th,
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 8px;
  }

  .my-account-subtitle h3 {
    font-size: 20px;
  }
  .bill-details__row {
    grid-template-columns: repeat(3, 1fr);
  }
  .bookig-details-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .login-wrapper {
    min-height: calc(100dvh - (70px + 50px));
  }
  .my-account-section {
    min-height: calc(100dvh - (50px + 50px));
  }
  .contact-info-sec .contact-info-details span {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  .contact-info-sec .contact-info-details {
    align-items: flex-start !important;
  }
  .timeline-card {
    font-size: 12px;
  }
  .timeline-card .MuiCardContent-root {
    padding: 10px;
  }

  .st-info li {
    font-size: 11px;
  }
  .main-property-sec .partners-sec.get-app {
    margin-top: 0;
  }
}

@media (max-width: 575px) {
  .counter-sec {
    padding: 0;
  }
  .section-heading h2 {
    font-size: 22px;
    line-height: 26px;
  }
  .booking-meta-details {
    flex-direction: column;
    align-items: flex-end;
    gap: 0;
  }

  .booking-meta-details .token-amount {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .booking-banner-text.residence-banner .residence-title-wrap h1 {
    font-size: 18px;
    line-height: 22px;
  }

  .residence-banner .residence-title-wrap p {
    font-size: 12px;
  }

  .booking-meta-details {
    padding: 12px;
    margin-bottom: 12px;
  }
  .userform-details .bookig-details-grid {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 10px;
    gap: 20px 10px;
  }

  .rental-info .all-payment-amount-details {
    margin-bottom: 0 !important;
  }

  .rental-info {
    padding: 12px;
  }

  .section-padding {
    padding: 30px 0;
  }
  .header .header-nav .header-nav-wrap {
    gap: 10px;
  }

  .header .header-navbar-rht li.new-property-btn a {
    font-size: 12px;
  }
  .header .header-navbar-rht li.new-property-btn a .own-prop {
    font-size: 10px;
  }
  .header .logo.navbar-brand img {
    max-width: 145px;
  }
  .header .header-navbar-rht li .btn {
    /* font-size: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px; */
  }
  .header .header-navbar-rht li .btn svg {
    font-size: 18px;
  }
  .areas__row {
    grid-template-columns: repeat(2, 1fr);
  }
  .banner-property-grid .custom-dropdown .custom-filter-modal-wrapper {
    width: 300px;
  }

  .banner-section {
    padding: 50px 0;
  }

  .banner-content h1 {
    font-size: 26px;
    line-height: 30px;
  }

  .banner-content span {
    font-size: 16px;
  }

  .banner-content p {
    font-size: 14px;
  }

  .banner-section:before {
    /* background: linear-gradient(110deg, #f1f1f1 30%, transparent); */
  }

  .banner-search .banner-property-grid .error-text {
    position: relative;
  }

  .banner-search .banner-tab-property {
    padding: 10px;
  }

  .banner-search .banner-tab {
    padding-bottom: 8px;
  }
  .benefits__row {
    grid-template-columns: repeat(1, 1fr);
  }
  .get-app .easy-stay-card .app-info-wrap .app-qr img {
    margin-top: -50px;
    height: 100px;
  }

  .get-app .easy-stay-card .app-info-wrap {
    gap: 10px;
  }

  .easy-stay-card ul {
    gap: 10px;
  }

  .partners-sec.get-app {
    margin-top: 0;
    margin-bottom: 0;
  }

  .bg-08 {
    top: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    width: 18px;
    height: 18px;
  }

  .testimonial-slider .swiper-button-next {
    right: calc(50% - 35px);
  }

  .testimonial-slider .swiper-button-prev {
    left: calc(50% - 36px);
  }
  .footer .footer-top .footer-content-heading h4 {
    font-size: 11px;
  }

  .footer-widget-list ul li a {
    font-size: 8px;
  }
  .property-type-slider .swiper-wrapper .swiper-slide {
    max-width: 150px;
  }
  .property-type-slider {
    padding-left: 12px;
  }

  .property-type-slider .swiper-pagination {
    position: relative;
  }

  .property-type-slider .swiper {
    padding-bottom: 20px;
  }

  .property-type-slider
    .swiper
    .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    background: yellow;
  }
  .feature-slider > .swiper > .swiper-pagination {
    position: relative;
    margin-top: 20px;
    bottom: unset;
  }

  .feature-slider > .swiper > .swiper-wrapper > .swiper-slide {
    height: unset;
  }
  .about-banner img {
    min-height: 210px;
  }

  .looking-for-wrap > div {
    width: 100%;
  }

  .looking-for-wrap {
    flex-wrap: wrap;
  }

  .looking-for-wrap > div .icon {
    width: 120px;
  }

  .looking-for-wrap > div .wrap {
    width: calc(100% - (120px + 10px));
  }

  .looking-for-wrap > div .title {
    font-size: 18px;
  }
  .blog-banner .blog-banner-img img {
    height: auto;
  }

  .blog-banner .looking-for-wrap > div {
    width: 100%;
  }
  .property-filter-section .property-filter-card {
    padding: 12px;
  }

  .property-filter-section {
    padding-top: 10px;
  }

  .property-listing-section .grid-view-option ul li {
    font-size: 0px;
    gap: 0;
  }

  .property-listing-section .grid-view-option ul li svg {
    font-size: 20px;
  }

  .property-listing-section .grid-view-option {
    min-width: 80px;
  }

  .property-filter-section .filter-card .search-wrap {
    width: 100%;
  }
  .property-listing-section .property-list-title h1 {
    font-size: 20px;
  }
  .property-listing-section .property-card__row .property-card__col {
    width: 100%;
  }
  .property-list-view .product-custom .doc-img {
    width: 150px;
  }

  .property-list-view .product-custom .pro-content {
    width: calc(100% - 150px);
    padding: 12px;
  }
  .avail-from,
  .avail-from .date {
    font-size: 8px;
  }
  .property-list-view .property-img .swiper-pagination {
    display: none;
  }

  .property-list-view .pro-content h3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .property-list-view .product-custom .doc-img .property-img img {
    height: 136px;
  }

  .property-list-view .pro-content .property-price {
    font-size: 14px;
    margin: 0;
  }

  .property-list-view .pro-content p {
    font-size: 10px;
    margin-bottom: 10px;
  }
  .residence-banner .residence-title-wrap {
    flex-wrap: wrap;
  }

  .residence-banner .residence-title-wrap .left-col,
  .residence-banner .residence-title-wrap .right-col {
    width: 100%;
  }

  .residence-banner .residence-title-wrap .location {
    flex-wrap: wrap;
  }

  .residence-banner .residence-title-wrap .location a {
    font-size: 12px;
  }

  .residence-banner .residence-title-wrap p {
    width: 100%;
  }

  .residence-banner .residence-title-wrap .right-col .right-content {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
  }

  .residence-banner .residence-title-wrap .contact-owner {
    margin-top: 0;
  }

  .residence-banner .lg-react-element.gallery .gallery__item:first-child img {
    height: 250px;
  }

  .residency-details-section .property-rental-wrap {
    grid-template-columns: repeat(2, 1fr);
    font-size: 12px;
    gap: 20px;
  }

  .residency-details-section .property-rental-wrap .for-daily a {
    font-size: 14px;
    padding: 5px 8px;
  }

  .residency-details-section .property-rental-wrap .for-daily a svg {
    margin-right: 0 !important;
  }

  .residency-details-section .property-rental-wrap .rent-month .amount {
    font-size: 18px;
    line-height: 24px;
  }

  .residency-details-section .property-rental-wrap .term {
    font-size: 12px;
  }

  .residency-details-section .property-rental-wrap .deposit svg {
    font-size: 11px;
  }

  .residency-details-section .property-rental-wrap .rent-month svg {
    font-size: 16px;
  }

  .residency-details-wrap .property-overview {
    grid-template-columns: repeat(3, 1fr);
  }
  .sticky-stripe .sticky-list li {
    font-size: 14px;
  }

  .sticky-stripe .sticky-list {
    gap: 30px;
  }

  .about-property .about-inner-content .content-wrap p {
    font-size: 12px;
    line-height: 18px;
  }
  .property-details-wrap .property-details {
    grid-template-columns: repeat(2, 1fr);
  }

  .property-amenities li {
    width: calc(50% - 20px);
    font-size: 12px;
  }

  .nearby-areas-properties {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px 0;
  }

  .nearby-areas-properties .nearby-areas-properties-list {
    font-size: 12px;
  }
  .custom-breadcrumb .bread-crumb-list a,
  .custom-breadcrumb .bread-crumb-list span {
    font-size: 10px;
  }
  .residence-banner .residence-title-wrap h1 {
    font-size: 20px;
    line-height: 24px;
  }
  .residence-banner {
    padding: 20px 0;
  }
  .login-wrapper .loginbox {
    padding: 30px 20px;
  }
  .login-wrapper .loginbox .login-auth .login-auth-wrap h1 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .PhoneInput input {
    font-size: 14px;
  }

  .direct-email-options {
    flex-wrap: wrap;
  }

  .direct-email-options .item {
    width: 210px;
    justify-content: center;
  }

  .dont-have-account {
    font-size: 14px;
  }
  .account-card .nav-tabs button {
    padding: 4px 8px;
    white-space: nowrap;
  }
  .account-card .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .profile-info-fields {
    margin-top: 10px;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .flatmate-details-wrap .my-account-title,
  .witness-detail-wrap .my-account-title {
    flex-wrap: wrap;
  }

  .witness-detail-wrap .my-account-title button,
  .flatmate-details-wrap .my-account-title button {
    font-size: 12px;
    padding: 4px 8px;
  }

  .account-card .tab-pane .my-account-title .title-wrap {
    width: 100%;
  }

  .account-card .tab-pane .my-account-title .add-btn {
    margin-left: auto;
  }
  .feedback-wrapper .my-account-title,
  .assigned-property-wrap .my-account-title {
    flex-wrap: wrap;
    row-gap: 10px;
  }

  .property-detail-wrap .property-detail > div:first-child {
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  .property-detail .review-btn a button {
    font-size: 12px;
    padding: 0 10px;
    line-height: 9px;
  }
  .property-detail .rent-meta-list li {
    display: flex;
    flex-direction: column;
  }
  .bookig-details-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }

  .booking-confirmation-card .title h1 {
    font-size: 22px;
  }

  .booking-confirmation-card .title svg {
    font-size: 19px;
  }

  .payment-details .bookig-details-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .booking-confirmation-card > div:not(:last-child) {
    margin-bottom: 16px;
  }
  .booking-confirmation-card .inner-title {
    font-size: 20px;
  }
  .feedback-options .icon-wrap {
    width: calc(33.33% - 8px);
  }
  .feedback-icons {
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
  }
  .support-timeline-dialog .MuiDialogContent-root {
    padding: 16px;
  }

  .support-timeline-dialog .MuiTimeline-root {
    padding: 0;
    margin-top: 20px;
  }

  .support-timeline-dialog .MuiTimeline-root > li > div:first-child {
    display: none;
  }
  .support-timeline-dialog
    .MuiTimeline-root
    > li
    .MuiCard-root
    .MuiCardContent-root {
    padding: 12px;
  }
  .st-info {
    font-size: 12px;
  }
  .bill-details__row {
    grid-template-columns: repeat(2, 1fr);
  }
  .bill-details__row .card .bill-name {
    font-size: 12px;
    line-height: 16px;
  }
  .bill-details__row .card {
    gap: 4px;
  }
  .custom-modal-title h2 {
    font-size: 18px;
  }
  .custom-breadcrumb .bread-crumb-list li {
    line-height: 11px;
  }
  .dotted-file-upload-wrap {
    flex-wrap: wrap;
  }
  .dotted-file-upload-wrap .file-upload-wrap {
    width: 100%;
  }
  .mobile-strip {
    background: green;
    padding: 1px 12px;
    font-size: 12px;
  }

  .mobile-strip a {
    display: block;
    color: #fff;
  }
  .mob-none {
    display: none !important;
  }
  .user-guide-note {
    font-size: 12px;
  }
  .user-guide-note h4 {
    font-size: 16px;
  }
  .download-agreement-wrap {
    align-items: flex-start;
    width: 100%;
  }
  .verification-status ul li {
    width: 25%;
    max-width: 100%;
  }

  .verification-status ul li:not(:last-child):before {
    width: 100%;
    top: 19px;
  }
  .verification-status ul .text {
    font-size: 12px;
  }
  .verification-status ul .icon {
    width: 36px;
    height: 36px;
  }
  .download-agreement .modal-title.h4 {
    font-size: 16px;
  }

  .download-agreement .modal-header,
  .download-agreement .modal-body {
    padding: 12px;
  }

  .download-agreement.preview-document .modal-content {
    height: calc(100dvh - 20px);
  }
  .privacy-policy-wrap h3 {
    font-size: 17px;
  }

  .privacy-policy-wrap p {
    font-size: 12px;
  }

  .privacy-policy-wrap h2,
  .cancellation-policy .privacy-policy-wrap h2 {
    font-size: 20px;
  }
  .property-listing-section {
    min-height: calc(100svh - 337px);
  }
  .banner-property-grid .locality-list li {
    width: calc(50% - 8px);
  }
  .header .header-navbar-rht li.new-property-btn a {
    flex-direction: column;
    line-height: normal;
    align-items: flex-end;
  }
  .custom-dropdown .custom-filter-modal-wrapper {
    width: 320px;
  }
  .timeline-card .updated-by {
    display: none;
  }

  .support-timeline .MuiTimelineItem-positionAlternate {
    flex-direction: row !important;
  }

  .support-timeline
    .MuiTimelineItem-positionAlternate
    .MuiTimelineContent-root {
    text-align: left !important;
  }
}

/********************************************/
@media (min-width: 992px) {
  .tab-show {
    display: none;
  }
}
@media (min-width: 576px) {
  .mob-show {
    display: none !important;
  }
}
