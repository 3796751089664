/*
Author       : Dreamguys
Template Name: DreamsEstate - Bootstrap Template
Version      : 1.0
*/
/*============================
 [Table of CSS]

1. Typography
2. Colors
3. General
4. Grid
5. Buttons
6. Bootstrap Class
7. Switch
8. Select
9. Avatar
10. Content
11. Footer
12. Header
13. Common
14. Breadcrumb
15. Home
16. Agent
17. Compare
18. Detail View
19. Rent List
20. Property Grid Sidebar
21. Rent
22. Login
23. FAQ
24. Invoice
25. Agency
26. Gallery
27. Testimonial
28. Agent Details
29. About Us
30. Contact
31. Our Team
32. Blog
33. Shop
34. Wishlist
35. Checkout
36. Add Property

========================================*/
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&amp;display=swap");

:root {
  --primary-theme-color: #12086f;
  /* --secondary-theme-color: #f26419; */
  /* --secondary-theme-color: #086f6f; */
  /* --secondary-theme-color: #ff5c41; */
  --secondary-theme-color: #006ce4;
  --secondary-theme-color2: #006ce4;
  --white: #ffffff;
  --black: #000000;
  --font-family: "Inter", sans-serif;
}

.css-equal-content,
.css-equal-heights {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.css-equal-content {
  color: red;
  float: left;
}

.gallery-property ul li a,
.cart-section .product-group .product-content .social-links a,
.shop-page .product-custom .profile-widget .doc-img-view ul li a,
.blog-banner .blog-detailset,
.blog-banner,
.bloglistleft-widget .post-author,
.bloggrid-sidebar .post-author,
.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .meta-item li .post-author,
.blog-section .blog-content .meta-item,
.blog-section .blog-content .date-icon .post-author,
.blog-section .blog-content .date-icon,
.our-team-section .our-team .team-prof .footer-social-links ul li a,
.our-team-section .our-team .team-prof .footer-social-links ul,
.contact-info-sec .contact-info-details span,
.details-wrap.agency .detail-user-img,
.service-area li::before,
.agent-list.agency .agent-img,
.login-wrapper,
.modal-succeess .success-popup h4,
.modal-succeess .success-popup,
.booking-details .payment-icon ul li span,
.booking-details .payment-icon ul li,
.success-div span,
.grid-list-view ul li a,
.connect-us li a,
.sidebar-card .contact-btn.contact-btn-new a,
.collapse-card .sample-video .play-icon,
.compare-head .compare-action a,
.grid-pagination .pagination li .page-link,
.blog-card .blog-content .property-category a span,
.product-custom .favourite,
.testimonial-card .user-icon,
.single-property-card .buy-property .arrow,
.cities-list-sec .city-list .arrow-overlay a,
.property-card,
.howit-work-card .work-card-icon span,
.howit-work-card,
.section-heading,
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:last-child
  a,
.banner-search .banner-tab .nav-tabs .nav-item .nav-link,
.serve-form .form-group .button-notific,
.serve-form .form-group,
.error-page .main-wrapper,
.error-page,
.header .header-navbar-rht li .btn,
.header .main-menu-wrapper .main-nav li .btn.sign-btn,
.header .header-top .template-ad,
.login-body .footer-social-links .nav li a,
.login-body .footer-social-links .nav,
.social-links ul li a,
.avatar .avatar-title,
.btn-lightred,
.btn-secondary,
.btn-primary,
.align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.login-body .footer-social-widget .nav-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}

.details-wrap .detail-user-wrap .user-info-wrap,
.agent-list .agent-content .agent-info,
.single-property-card .buy-property,
.product-custom .feature-rating,
.header .header-nav .header-nav-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.sort-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .meta-item,
.invoice-section .card .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.cart-section .product-group .detail-gallery .new-featured span {
  display: inline-flex;
  display: -webkit-inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.cart-section .product-group .product-content .social-links a,
.cart-section .product-group .product-content .social-links,
.cart-section .product-group .product-content .product-compare,
.cart-section .product-group .product-content .detail-count,
.cart-section .product-group .product-content .rating,
.cart-section .product-group .detail-gallery .zoom-icon,
.shop-page #colors-available .colors-themes,
.blog-section .rightsidebar .card h4,
.profile-widget.rent-list-view,
.inside-collapse-card ul li,
.collapse-card .map iframe,
.agent-list,
.property-category .user-info .user-name,
.breadcrumb .bread-crumb-head .breadcrumb-list ul,
.header .header-navbar-rht .has-arrow .user-header,
.header .main-menu-wrapper .main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.property-submit .btn,
.details-wrap .detail-user-wrap .user-info-wrap .detail-action .btn,
.agent-list .agent-content .agent-info h5,
.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.blog-banner .post-author,
.our-agent,
.details-wrap .detail-user-wrap .detail-user-info p,
.details-wrap .detail-user-wrap,
.agent-list.agency .agent-content .agency-user .agency-user-info p,
.agency-user,
.agent-card.agency .agency-user .agency-user-info p,
.agent-card.agency .agency-user,
.advanced-search .review-form-btn,
.booking-details-btn,
.booking-details-price ul li,
.booking-details ul,
.arrival-div ul,
.details-div,
.more a,
.less a,
.price-filter .caption,
.profile-widget.rent-list-view .pro-content .list-head p,
.grid-list-view ul,
.grid-head,
.calender ul li,
.calender ul,
.list-details li,
.connect-us,
.user-active .user-name .rating.star-rate,
.user-active,
.sidebar-card .contact-btn a,
.sidebar-card .contact-btn,
.inside-collapse-card ul,
.collapse-card .review-card .customer-review .customer-info .customer-name,
.collapse-card .review-card .customer-review .customer-info,
.collapse-card ul.amenities-list li,
.latest-update .other-pages li a,
.latest-update .other-pages,
.page-title p,
.rating .rating-count,
.rating,
.agent-card .agent-content p,
.property-category .user-info,
.pro-content .details li,
.price-card .price-features ul li,
.price-section .pricing-tab,
.privacy-section .terms-policy ul li,
.header .header-navbar-rht,
.footer-bottom-content .company-logo,
.footer-bottom-content,
.footer-widget-list ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.our-agent .agent-name p,
.list-details.con-list li span,
.agent-list .agent-content .agent-info .rating,
.custom_check,
.header .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-navbar-rht .has-arrow .logged-item .nav-link,
.header .header-navbar-rht li.new-property-btn a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sort-result,
.latest-update .other-pages,
.pro-content .details,
.header .header-nav {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.inside-collapse-card ul li {
  -ms-flex-direction: column;
  flex-direction: column;
}

.cart-section .product-group .detail-gallery .zoom-icon,
.our-team-section .our-team .team-prof .footer-social-links ul,
.breadcrumb .bread-crumb-head .breadcrumb-list ul {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.profile-widget.rent-list-view .pro-content .details {
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}

.calender ul,
.list-details li,
.connect-us,
.sidebar-card .contact-btn,
.inside-collapse-card ul,
.collapse-card .review-card .customer-review .customer-info,
.pro-content .details,
.price-section .pricing-tab,
.header .menu-header,
.footer-bottom-content {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.inside-collapse-card ul li {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.cart-section .product-group .product-content .social-links,
.cart-section .product-group .product-content .product-compare,
.cart-section .product-group .product-content .detail-count,
.cart-section .product-group .product-content .rating,
.cart-section .product-group .detail-gallery .zoom-icon,
.blog-section .rightsidebar .card h4,
.blog-section .blog-content .viewlink,
.header .menu-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*-----------------
	1. Typography
-----------------------*/
h1 {
  font-weight: 700;
  font-size: 36px;
}
@media (max-width: 991.98px) {
  h1 {
    font-size: 32px;
  }
}
@media (max-width: 767.78px) {
  h1 {
    font-size: 28px;
  }
}

h2 {
  font-weight: 700;
  font-size: 32px;
}
@media (max-width: 991.98px) {
  h2 {
    font-size: 28px;
  }
}
@media (max-width: 767.78px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-weight: 700;
  font-size: 26px;
}
@media (max-width: 767.78px) {
  h3 {
    font-size: 24px;
  }
}

h4 {
  font-weight: 600;
  font-size: 24px;
}
@media (max-width: 767.78px) {
  h4 {
    font-size: 16px;
  }
}

h5 {
  font-weight: 700;
  font-size: 20px;
}
@media (max-width: 991.98px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-weight: 700;
  font-size: 18px;
}
@media (max-width: 991.98px) {
  h6 {
    font-size: 16px;
  }
}

/*-----------------
	2. Colors
-----------------------*/
.primary {
  background-color: var(--primary-theme-color);
}

.secondary {
  background-color: #1e1d85;
}

.bg-red {
  background-color: #fd3358 !important;
}

.bg-green {
  background-color: #0dca95 !important;
}

.text-primary {
  color: var(--primary-theme-color) !important;
}

/*-----------------
	3. General
-----------------------*/
body {
  background-color: #fff;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  color: #000000;
  line-height: 1.5;
}
@media (max-width: 767.78px) {
  body {
    font-size: 14px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: var(--primary-theme-color);
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
a:hover {
  color: var(--primary-theme-color);
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
a:focus {
  outline: 0;
}

p {
  margin-bottom: 20px;
}
p:last-child {
  margin-bottom: 0;
}
@media (max-width: 767.78px) {
  p {
    font-size: 15px;
  }
}

strong {
  font-weight: 700;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0e0e46;
}

@media (max-width: 991.98px) {
  .container {
    max-width: 100%;
  }
}

/*-----------------
	4. Grid
-----------------------*/
.css-equal-heights,
.css-equal-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.css-equal-content {
  color: red;
  float: left;
}

.gallery-property ul li a,
.cart-section .product-group .product-content .social-links a,
.shop-page .product-custom .profile-widget .doc-img-view ul li a,
.blog-banner .blog-detailset,
.blog-banner,
.bloglistleft-widget .post-author,
.bloggrid-sidebar .post-author,
.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .meta-item li .post-author,
.blog-section .blog-content .meta-item,
.blog-section .blog-content .date-icon .post-author,
.blog-section .blog-content .date-icon,
.our-team-section .our-team .team-prof .footer-social-links ul li a,
.our-team-section .our-team .team-prof .footer-social-links ul,
.contact-info-sec .contact-info-details span,
.details-wrap.agency .detail-user-img,
.service-area li::before,
.agent-list.agency .agent-img,
.login-wrapper,
.modal-succeess .success-popup h4,
.modal-succeess .success-popup,
.booking-details .payment-icon ul li span,
.booking-details .payment-icon ul li,
.success-div span,
.grid-list-view ul li a,
.connect-us li a,
.sidebar-card .contact-btn.contact-btn-new a,
.collapse-card .sample-video .play-icon,
.compare-head .compare-action a,
.grid-pagination .pagination li .page-link,
.blog-card .blog-content .property-category a span,
.product-custom .favourite,
.testimonial-card .user-icon,
.single-property-card .buy-property .arrow,
.cities-list-sec .city-list .arrow-overlay a,
.property-card,
.howit-work-card .work-card-icon span,
.howit-work-card,
.section-heading,
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:last-child
  a,
.banner-search .banner-tab .nav-tabs .nav-item .nav-link,
.serve-form .form-group .button-notific,
.serve-form .form-group,
.error-page .main-wrapper,
.error-page,
.header .header-navbar-rht li .btn,
.header .main-menu-wrapper .main-nav li .btn.sign-btn,
.header .header-top .template-ad,
.login-body .footer-social-links .nav li a,
.login-body .footer-social-links .nav,
.social-links ul li a,
.avatar .avatar-title,
.btn-lightred,
.btn-secondary,
.btn-primary,
.align-center,
.btn-theme-blue,
.btn-theme-green {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.login-body .footer-social-widget .nav-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}

.details-wrap .detail-user-wrap .user-info-wrap,
.agent-list .agent-content .agent-info,
.single-property-card .buy-property,
.product-custom .feature-rating,
.header .header-nav-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.sort-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .meta-item,
.invoice-section .card .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.cart-section .product-group .detail-gallery .new-featured span {
  display: inline-flex;
  display: -webkit-inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.cart-section .product-group .product-content .social-links a,
.cart-section .product-group .product-content .social-links,
.cart-section .product-group .product-content .product-compare,
.cart-section .product-group .product-content .detail-count,
.cart-section .product-group .product-content .rating,
.cart-section .product-group .detail-gallery .zoom-icon,
.shop-page #colors-available .colors-themes,
.blog-section .rightsidebar .card h4,
.profile-widget.rent-list-view,
.inside-collapse-card ul li,
.collapse-card .map iframe,
.agent-list,
.property-category .user-info .user-name,
.breadcrumb .bread-crumb-head .breadcrumb-list ul,
.header .header-navbar-rht .has-arrow .user-header,
.header .main-menu-wrapper .main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.property-submit .btn,
.details-wrap .detail-user-wrap .user-info-wrap .detail-action .btn,
.agent-list .agent-content .agent-info h5,
.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.blog-banner .post-author,
.our-agent,
.details-wrap .detail-user-wrap .detail-user-info p,
.details-wrap .detail-user-wrap,
.agent-list.agency .agent-content .agency-user .agency-user-info p,
.agency-user,
.agent-card.agency .agency-user .agency-user-info p,
.agent-card.agency .agency-user,
.advanced-search .review-form-btn,
.booking-details-btn,
.booking-details-price ul li,
.booking-details ul,
.arrival-div ul,
.details-div,
.more a,
.less a,
.price-filter .caption,
.profile-widget.rent-list-view .pro-content .list-head p,
.grid-list-view ul,
.grid-head,
.calender ul li,
.calender ul,
.list-details li,
.connect-us,
.user-active .user-name .rating.star-rate,
.user-active,
.sidebar-card .contact-btn a,
.sidebar-card .contact-btn,
.inside-collapse-card ul,
.collapse-card .review-card .customer-review .customer-info .customer-name,
.collapse-card .review-card .customer-review .customer-info,
.collapse-card ul.amenities-list li,
.latest-update .other-pages li a,
.latest-update .other-pages,
.page-title p,
.rating .rating-count,
.rating,
.agent-card .agent-content p,
.property-category .user-info,
.pro-content .details li,
.price-card .price-features ul li,
.price-section .pricing-tab,
.privacy-section .terms-policy ul li,
.header .header-navbar-rht,
.footer-bottom-content .company-logo,
.footer-bottom-content,
.footer-widget-list ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.our-agent .agent-name p,
.list-details.con-list li span,
.agent-list .agent-content .agent-info .rating,
.custom_check,
.header .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-navbar-rht .has-arrow .logged-item .nav-link,
.header .header-navbar-rht li.new-property-btn a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sort-result,
.latest-update .other-pages,
.pro-content .details,
.header .header-nav {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.inside-collapse-card ul li {
  -ms-flex-direction: column;
  flex-direction: column;
}

.cart-section .product-group .detail-gallery .zoom-icon,
.our-team-section .our-team .team-prof .footer-social-links ul,
.breadcrumb .bread-crumb-head .breadcrumb-list ul {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.profile-widget.rent-list-view .pro-content .details {
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}

.calender ul,
.list-details li,
.connect-us,
.sidebar-card .contact-btn,
.inside-collapse-card ul,
.collapse-card .review-card .customer-review .customer-info,
.pro-content .details,
.price-section .pricing-tab,
.header .menu-header,
.footer-bottom-content {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.inside-collapse-card ul li {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.cart-section .product-group .product-content .social-links,
.cart-section .product-group .product-content .product-compare,
.cart-section .product-group .product-content .detail-count,
.cart-section .product-group .product-content .rating,
.cart-section .product-group .detail-gallery .zoom-icon,
.blog-section .rightsidebar .card h4,
.blog-section .blog-content .viewlink,
.header .menu-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.col {
  /* width: auto; */
  /* padding-left: 15px;
  padding-right: 15px;
  float: left; */
  /* position: relative; */
  min-height: 1px;
}
@media (min-width: 320px) {
  .col.col-xs-12 {
    width: 100%;
  }
  .col.col-xs-11 {
    width: 91.66666667%;
  }
  .col.col-xs-10 {
    width: 83.33333333%;
  }
  .col.col-xs-9 {
    width: 75%;
  }
  .col.col-xs-8 {
    width: 66.66666667%;
  }
  .col.col-xs-7 {
    width: 58.33333333%;
  }
  .col.col-xs-6 {
    width: 50%;
  }
  .col.col-xs-5 {
    width: 41.66666667%;
  }
  .col.col-xs-4 {
    width: 33.33333333%;
  }
  .col.col-xs-3 {
    width: 25%;
  }
  .col.col-xs-2 {
    width: 16.66666667%;
  }
  .col.col-xs-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 568px) {
  .col.col-sm-12 {
    width: 100%;
  }
  .col.col-sm-11 {
    width: 91.66666667%;
  }
  .col.col-sm-10 {
    width: 83.33333333%;
  }
  .col.col-sm-9 {
    width: 75%;
  }
  .col.col-sm-8 {
    width: 66.66666667%;
  }
  .col.col-sm-7 {
    width: 58.33333333%;
  }
  .col.col-sm-6 {
    width: 50%;
  }
  .col.col-sm-5 {
    width: 41.66666667%;
  }
  .col.col-sm-4 {
    width: 33.33333333%;
  }
  .col.col-sm-3 {
    width: 25%;
  }
  .col.col-sm-2 {
    width: 16.66666667%;
  }
  .col.col-sm-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 768px) {
  .col.col-md-12 {
    width: 100%;
  }
  .col.col-md-11 {
    width: 91.66666667%;
  }
  .col.col-md-10 {
    width: 83.33333333%;
  }
  .col.col-md-9 {
    width: 75%;
  }
  .col.col-md-8 {
    width: 66.66666667%;
  }
  .col.col-md-7 {
    width: 58.33333333%;
  }
  .col.col-md-6 {
    width: 50%;
  }
  .col.col-md-5 {
    width: 41.66666667%;
  }
  .col.col-md-4 {
    width: 33.33333333%;
  }
  .col.col-md-3 {
    width: 25%;
  }
  .col.col-md-2 {
    width: 16.66666667%;
  }
  .col.col-md-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 1024px) {
  .col.col-lg-12 {
    width: 100%;
  }
  .col.col-lg-11 {
    width: 91.66666667%;
  }
  .col.col-lg-10 {
    width: 83.33333333%;
  }
  .col.col-lg-9 {
    width: 75%;
  }
  .col.col-lg-8 {
    width: 66.66666667%;
  }
  .col.col-lg-7 {
    width: 58.33333333%;
  }
  .col.col-lg-6 {
    width: 50%;
  }
  .col.col-lg-5 {
    width: 41.66666667%;
  }
  .col.col-lg-4 {
    width: 33.33333333%;
  }
  .col.col-lg-3 {
    width: 25%;
  }
  .col.col-lg-2 {
    width: 16.66666667%;
  }
  .col.col-lg-1 {
    width: 8.33333333%;
  }
}

/*-----------------
	5. Buttons
-----------------------*/
.btn-primary {
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  background-color: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
  box-shadow: inset 0 0 0 0 #f6f6f9;
  border-radius: 10px;
  padding: 12px 15px;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.btn-primary.orange-btn {
  background-color: #ff6838;
  border: 1px solid #ff6838;
}
@media (max-width: 767.78px) {
  .btn-primary {
    font-size: 14px;
    padding: 10px 15px;
  }
}
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
  color: #000;
  background-color: #f6f6f9;
  border-color: #f6f6f9;
  box-shadow: inset 0 50px 0 0 #f6f6f9;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.btn-secondary {
  font-weight: 700;
  font-size: 15px;
  color: var(--primary-theme-color);
  background-color: #f6f6f9;
  border: 1px solid #f6f6f9;
  box-shadow: inset 0 0 0 0 var(--primary-theme-color);
  border-radius: 10px;
  padding: 12px 15px;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
@media (max-width: 767.78px) {
  .btn-secondary {
    font-size: 14px;
    padding: 10px 15px;
  }
}
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary.active {
  color: #fff;
  background-color: var(--primary-theme-color);
  border-color: var(--primary-theme-color);
  box-shadow: inset 0 50px 0 0 var(--primary-theme-color);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.btn-theme-blue {
  font-weight: 700;
  font-size: 15px;
  font-weight: 700;
  color: var(--white);
  background: var(--secondary-theme-color);
  border: 1px solid var(--secondary-theme-color);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
  box-shadow: inset 0 0 0 0 var(--white);
  border-radius: 10px;
  padding: 12px 15px;
}
.btn-theme-blue:focus,
.btn-theme-blue:hover,
.btn-theme-blue:active,
.btn-theme-blue.active {
  color: var(--secondary-theme-color);
  background-color: var(--white);
  border-color: var(--secondary-theme-color);
  box-shadow: inset 0 50px 0 0 var(--white);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.btn-theme-green {
  color: #fff;
  background-color: green;
  border-color: green;
  box-shadow: inset 0 50px 0 0 green;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.btn-theme-green:focus,
.btn-theme-green:hover,
.btn-theme-green:active,
.btn-theme-green.active {
  color: green;
  background-color: var(--white);
  border-color: green;
  box-shadow: inset 0 50px 0 0 var(--white);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.btn-theme-red {
  color: #fff;
  background-color: red;
  border-color: red;
  box-shadow: inset 0 50px 0 0 red;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.btn-theme-red:focus,
.btn-theme-red:hover,
.btn-theme-red:active,
.btn-theme-red.active {
  color: red;
  background-color: var(--white);
  border-color: red;
  box-shadow: inset 0 50px 0 0 var(--white);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inherit;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #fff;
  border-color: var(--primary-theme-color);
  color: var(--primary-theme-color);
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #fff;
  border-color: var(--primary-theme-color);
  color: var(--primary-theme-color);
  opacity: 0.3;
}

.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #fff;
  border-color: #1e1d85;
  color: #1e1d85;
}

/*-----------------
	6. Bootstrap Class
-----------------------*/
.form-group {
  margin-bottom: 20px;
}
.form-group .forgot-link {
  color: #fd3358;
  font-weight: 700;
  font-size: 15px;
  float: right;
}
.form-group .forgot-link:hover {
  color: var(--primary-theme-color);
}
.form-group .pass-group {
  position: relative;
}

.form-control {
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #868686;
  border-radius: 5px;
  color: var(--bs-body-color);
  min-height: 50px;
  margin: 0;
  padding: 9px 15px;
}
@media (max-width: 991.98px) {
  .form-control {
    min-height: 40px;
  }
}
.form-control::-webkit-input-placeholder {
  font-size: 14px;
  color: #8a909a;
  font-weight: 400;
}
.form-control::-moz-placeholder {
  font-size: 14px;
  color: #8a909a;
  font-weight: 400;
}
.form-control:-ms-input-placeholder {
  font-size: 14px;
  color: #8a909a;
  font-weight: 400;
}
.form-control::-ms-input-placeholder {
  font-size: 14px;
  color: #8a909a;
  font-weight: 400;
}
.form-control::placeholder {
  font-size: 14px;
  color: #8a909a;
}
.form-control:focus {
  box-shadow: none;
  outline: 0 none;
  border-color: var(--secondary-theme-color);
}

.card {
  border: 1px solid #eeeeee;
  background: #fff;
  border-radius: 0;
  margin-bottom: 24px;
  box-shadow: 0px 4px 24px rgba(234, 234, 234, 0.3);
}
.card .card-header {
  background-color: transparent;
  border: 0;
  padding: 24px;
}
.card .card-header .card-title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  color: #000;
}
.card .card-body {
  padding: 24px;
}

label {
  color: #000000;
  display: block;
  font-weight: 600;
  margin-bottom: 4px !important;
  font-size: 14px;
}
label span {
  color: #ff0000;
}

.modal-backdrop {
  background-color: var(--black);
}
.modal-backdrop.show {
  opacity: 0.5;
}

.bootstrap-datetimepicker-widget table td.active {
  background-color: var(--primary-theme-color);
}
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: var(--primary-theme-color);
}

.card {
  color: inherit;
}

.card-title {
  color: #0e0e46;
}

/*-----------------
	7. Switch
-----------------------*/
.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.check:checked + .checktoggle {
  background-color: #10b990;
  border: 1px solid #10b990;
}
.check:checked + .checktoggle:after {
  background-color: #fff;
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
  -webkit-transform: translate(calc(-100% - 5px), -50%);
  -ms-transform: translate(calc(-100% - 5px), -50%);
}

.checktoggle {
  background-color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 42px;
}
.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  -webkit-transform: translate(5px, -50%);
  -ms-transform: translate(5px, -50%);
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}

.status-toggle .check:checked + .checktoggle {
  background-color: #10b990;
  border: 1px solid #10b990;
}
.status-toggle .check:checked + .checktoggle:after {
  transform: translate(calc(-100% - 2px), -50%);
  -webkit-transform: translate(calc(-100% - 2px), -50%);
  -ms-transform: translate(calc(-100% - 2px), -50%);
}
.status-toggle .checktoggle {
  width: 41px;
  height: 21px;
}
.status-toggle .checktoggle::after {
  width: 17px;
  height: 17px;
  transform: translate(2px, -50%);
  -webkit-transform: translate(2px, -50%);
  -ms-transform: translate(2px, -50%);
}

.status-tog .checktoggle {
  height: 28px;
  width: 50px;
  border-radius: 999px;
  background-color: #7d858f;
  border-color: #7d858f;
}
.status-tog .checktoggle:before {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  /* background-image: url(../img/icons/check.html); */
  background-repeat: no-repeat;
  background-size: 11px 20px;
  width: 20px;
  height: 20px;
  transform: translate(3px, -50%);
}
.status-tog .checktoggle:after {
  width: 24px;
  height: 24px;
  /* background-image: url(../img/icons/x-icon.html); */
  background-repeat: no-repeat;
  background-size: 12px 21px;
  transform: translate(1px, -50%);
  background-position: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.06);
}
.status-tog .check:checked + .checktoggle:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  /* background-image: url(../img/icons/x-icon.html); */
  background-repeat: no-repeat;
  background-size: 13px 21px;
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}
.status-tog .check:checked + .checktoggle:after {
  /* background-image: url(../img/icons/check.html); */
  background-repeat: no-repeat;
  background-size: 12px 21px;
  transform: translate(calc(-100% - 1px), -50%);
  background-position: center;
}

.bootstrap-tagsinput {
  display: block;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #f5f5f5;
  height: 46px;
  margin: 0;
  padding: 4px 10px 0 10px;
  width: 100%;
  box-shadow: none;
  overflow-y: auto;
}
.bootstrap-tagsinput .tag {
  margin-right: 5px;
  margin-bottom: 2px;
  color: #a9a9a9;
  background: #0e0e46 !important;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
}
.bootstrap-tagsinput .tag:hover {
  color: #a9a9a9;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  position: relative;
  left: 7px;
  cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  margin: 0;
  padding: 0px 2px;
}
.bootstrap-tagsinput input {
  line-height: 36px;
  border: 1px solid transparent;
  background: transparent;
}
.bootstrap-tagsinput input:focus-visible {
  border: 1px solid transparent;
}

/*-----------------
	8. Select
-----------------------*/
.select2-dropdown {
  border: 1px solid #eeeeee;
}

.select2-container .select2-selection--single {
  border: 1px solid #f6f6f9;
  background: #f6f6f9;
  height: 50px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  color: #0e0e46;
  padding-right: 15px;
  padding-left: 15px;
  outline: none;
}

.select2-container--default.select2-container--focus
  .select2-selection--single
  .select2-selection__rendered {
  color: #0e0e46;
}
.select2-container--default .select2-selection--single {
  border-radius: 5px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px;
  right: 15px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #8a909a transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  margin-left: -5px;
  margin-top: -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #8a909a;
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
}
.select2-container--default.form-control-sm
  .select2-selection--single
  .select2-selection__arrow {
  top: 7px;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #8a909a;
  border-width: 0 6px 6px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary-theme-color);
  color: #fff;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #f5f5f5;
  min-height: 50px;
}

.select2-results__option {
  font-size: 14px;
  padding-right: 15px;
  padding-left: 15px;
}

.cart-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 45px;
}
.cart-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 45px;
}
.cart-select .select2-container--default .select2-selection--multiple {
  min-height: 45px;
}
.cart-select .select2-container .select2-selection--single {
  height: 45px;
}

/*-----------------
	9. Avatar
-----------------------*/
.avatar {
  background-color: transparent;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 34px;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 34px;
  position: relative;
  white-space: nowrap;
  margin: 0 10px 0 0;
  border-radius: 50%;
}
.avatar.avatar-xs {
  width: 24px;
  height: 24px;
}
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.avatar .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #0185ff;
  color: #fff;
}
.avatar.avatar-online::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #10b990;
}
.avatar.avatar-offline::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #ff0000;
}
.avatar.avatar-away::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #1e1d85;
}
.avatar .border {
  border-width: 3px !important;
}
.avatar .rounded {
  border-radius: 6px !important;
}
.avatar .avatar-title {
  font-size: 18px;
}
.avatar .avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}
.avatar .avatar-xs .border {
  border-width: 2px !important;
}
.avatar .avatar-xs .rounded {
  border-radius: 4px !important;
}
.avatar .avatar-xs .avatar-title {
  font-size: 12px;
}
.avatar .avatar-xs.avatar-away::before,
.avatar .avatar-xs.avatar-offline::before,
.avatar .avatar-xs.avatar-online::before {
  border-width: 1px;
}

.avatar-sm {
  width: 27px;
  height: 27px;
  min-width: 27px;
}
.avatar-sm .border {
  border-width: 3px !important;
}
.avatar-sm .rounded {
  border-radius: 4px !important;
}
.avatar-sm .avatar-title {
  font-size: 15px;
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}
.avatar-lg .border {
  border-width: 3px !important;
}
.avatar-lg .rounded {
  border-radius: 8px !important;
}
.avatar-lg .avatar-title {
  font-size: 24px;
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}
.avatar-xl .border {
  border-width: 4px !important;
}
.avatar-xl .rounded {
  border-radius: 8px !important;
}
.avatar-xl .avatar-title {
  font-size: 28px;
}
.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}
@media (min-width: 767.78px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}
.avatar-xxl .border {
  border-width: 6px !important;
}
@media (min-width: 767.78px) {
  .avatar-xxl .border {
    border-width: 4px !important;
  }
}
.avatar-xxl .rounded {
  border-radius: 8px !important;
}
@media (min-width: 768px) {
  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }
}
.avatar-xxl .avatar-title {
  font-size: 30px;
}
@media (min-width: 768px) {
  .avatar-xxl .avatar-title {
    font-size: 42px;
  }
}
.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
  border-width: 4px;
}
@media (min-width: 768px) {
  .avatar-xxl.avatar-away::before,
  .avatar-xxl.avatar-offline::before,
  .avatar-xxl.avatar-online::before {
    border-width: 4px;
  }
}

.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

/*-----------------	
    10. Content
-----------------------*/
.content {
  padding: 60px 0;
}
.content.inner-content {
  background: #f7f6ff;
}
@media (max-width: 991.98px) {
  .content {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .content {
    padding: 40px 0;
  }
}
.content.feature-property-sec {
  background-color: transparent;
  padding: 60px 0;
}
@media (max-width: 991.98px) {
  .content.feature-property-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .content.feature-property-sec {
    padding: 40px 0;
  }
}

.main-wrapper {
  /* overflow: clip;
  width: 100%;
  float: left; */
}

/*-----------------	
    11. Footer
-----------------------*/
.footer {
  background: #10162e;
  position: relative;
}
.footer .footer-top {
  padding: 60px 0;
}
.footer .container {
  max-width: 85%;
  min-width: 1140px;
}
@media (max-width: 991.98px) {
  .footer .footer-top {
    padding: 40px 0 10px;
  }
}
.footer .footer-top .footer-border-img img {
  position: absolute;
  top: 0;
  right: 0;
}
.footer .footer-top .footer-content-heading h4 {
  color: var(--secondary-theme-color);
  font-size: 18px;
  margin-bottom: 20px;
}
@media (max-width: 1199.98px) {
  .footer .footer-top .footer-content-heading h4 {
    font-size: 16px;
  }
}
@media (max-width: 991.98px) {
  .footer .footer-top .footer-content-heading h4 {
    font-size: 16px;
    margin-bottom: 24px;
  }
}
@media (max-width: 767.78px) {
  .footer .footer-top .footer-content-heading h4 {
    font-size: 16px;
  }
}
.footer .footer-top .footer-content-heading p {
  color: #f2f2f2;
  margin-top: 30px;
}
.footer .footer-logo {
  margin-bottom: 50px;
}
.footer .footer-logo img {
  height: 26px;
}
.footer .footer-top .download-app {
  margin-bottom: 50px;
  display: flex;
}
.footer .footer-top .download-app img {
  margin-right: 5px;
}
@media (max-width: 991.98px) {
  .footer .footer-top .download-app img {
    max-width: 140px;
  }
}
@media (max-width: 575.98px) {
  .footer .footer-top .download-app {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .footer .footer-top .download-app img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
@media (max-width: 991.98px) {
  .footer .footer-top .download-app {
    margin-bottom: 30px;
  }
}
@media (max-width: 991.98px) {
  .footer .footer-top .footer-about {
    margin-bottom: 30px;
  }
}

.social-links h4 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
  text-transform: capitalize;
}
@media (max-width: 1199.98px) {
  .social-links h4 {
    font-size: 22px;
  }
}
@media (max-width: 991.98px) {
  .social-links h4 {
    font-size: 20px;
  }
}
@media (max-width: 767.78px) {
  .social-links h4 {
    font-size: 18px;
  }
}
.social-links ul {
  display: flex;
  justify-content: center;
}
.social-links ul li {
  margin-right: 10px;
}
.social-links ul li a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--primary-theme-color);
  color: #fff;
  font-size: 18px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.social-links ul li a:hover {
  background: var(--secondary-theme-color);
  color: #fff;
}
@media (max-width: 767.78px) {
  .social-links ul li a {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }
}
.social-links ul li:last-child {
  margin: 0;
}

@media (max-width: 991.98px) {
  .footer-widget-list {
    margin-bottom: 30px;
  }
}
.footer-widget-list ul li {
  margin-bottom: 12px;
  position: relative;
}
.footer-widget-list ul li:after {
  /* font-family: "Fontawesome";
  font-size: 14px;
  content: "\f054";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  color: #fcaf3d; */
}
.footer-widget-list ul li i {
  color: #fcaf3d;
  color: var(--secondary-theme-color);
  margin-right: 10px;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.footer-widget-list ul li a {
  color: #f2f2f285;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  display: flex;
  gap: 6px;
  font-size: 13px;
}
.footer-widget-list ul li a:hover {
  color: #fcaf3d;
}
.footer-widget-list ul li:last-child {
  margin: 0;
}

.footer-bottom {
  background: #0d1329;
  padding: 15px 0;
}

.footer-bottom-content {
  color: #fff;
  justify-content: space-between;
}
.footer-bottom .right-content ul {
  display: flex;
  gap: 16px;
}

.footer-bottom .right-content ul a {
  color: #fff;
  font-size: 14px;
}
.footer-bottom .right-content ul a:hover {
  color: var(--secondary-theme-color);
}
@media (max-width: 767.78px) {
  .footer-bottom-content {
    flex-direction: column;
    align-items: start;
  }
}
.footer-bottom-content p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
@media (max-width: 767.78px) {
  .footer-bottom-content p {
    margin-bottom: 10px;
  }
}
.footer-bottom-content .company-logo img {
  margin-left: 5px;
}

.login-body .footer-social-links {
  margin: 10px 0 30px;
}
.login-body .footer-social-links h6 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
}
@media (max-width: 767.78px) {
  .login-body .footer-social-links h6 {
    margin-bottom: 10px;
  }
}
.login-body .footer-social-links .nav li {
  margin-right: 10px;
}
.login-body .footer-social-links .nav li:last-child {
  margin-right: 0;
}
.login-body .footer-social-links .nav li a {
  cursor: pointer;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: var(--primary-theme-color);
  background: #f6f4ff;
  border-radius: 50px;
  min-width: 36px;
  height: 36px;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}
.login-body .footer-social-links .nav li a:hover {
  background: var(--primary-theme-color);
  color: #fff;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}
.login-body .footer-social-widget {
  margin: 20px 0 0;
}
.login-body .footer-social-widget .nav-social li {
  margin-right: 10px;
}
.login-body .footer-social-widget .nav-social li:last-child {
  margin-right: 0;
}
.login-body .footer-social-widget .nav-social li a:hover {
  color: #000;
}

/*-----------------	
    12. Header
-----------------------*/
.header {
  width: 100%;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}
.header .header-top {
  text-align: center;
  background: #f6f4ff;
  margin: 0;
  padding: 10px;
}
.header .header-top .template-ad h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.header .header-top .template-ad h5 span {
  color: var(--primary-theme-color);
}
.header .header-top .template-ad img {
  margin-right: 10px;
}
.header-top .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-top .top-right-menu ul {
  display: flex;
  gap: 24px;
  font-size: 14px;
}

.header-top .top-right-menu ul a {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #0e0e46;
  font-weight: 600;
}

.header-top .top-right-menu ul li {
  position: relative;
}

.header-top .top-right-menu ul li:first-child:before {
  content: "";
  position: absolute;
  right: -12px;
  width: 2px;
  height: 80%;
  top: 50%;
  transform: translateY(-50%);
  background: #000;
}
@media (max-width: 991.98px) {
  .header .header-top {
    display: none;
  }
}
.header.min-header {
  width: 100%;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: transparent;
}
.header.header-fixed {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 9999;
  background: #fff;
  box-shadow: inherit;
}
.header .header-nav .header-nav-wrap {
  background-color: transparent;
  border: 0;
  position: relative;
  margin: 0;
  padding: 0 0;
  height: 50px;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media (max-width: 1199.98px) {
  .header .header-nav {
    margin: 0;
    padding: 0 0;
  }
}
@media (max-width: 991.98px) {
  .header .header-nav {
    margin: 0;
    padding: 0 0;
  }
}
@media (max-width: 767.78px) {
  .header .header-nav {
    margin: 0;
    padding: 0 0;
  }
}
@media (max-width: 991.98px) {
  .header .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}
@media (max-width: 767.78px) {
  .header .navbar-header {
    width: 100%;
  }
}
.header .navbar-header #mobile_btn {
  display: none;
  margin-right: 30px;
}
@media (max-width: 991.98px) {
  .header .navbar-header #mobile_btn {
    display: inline-block;
    position: relative;
    top: 3px;
  }
}
@media (max-width: 767.78px) {
  .header .navbar-header #mobile_btn {
    margin-right: 0;
  }
}
.header .navbar-header #mobile_btn .bar-icon {
  display: inline-block;
  width: 31px;
}
.header .navbar-header #mobile_btn .bar-icon span {
  background-color: var(--primary-theme-color);
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  transition: ease all 0.5s;
  position: relative;
}
.header .navbar-header #mobile_btn .bar-icon span:nth-child(2) {
  width: 16px;
}
.header .navbar-header #mobile_btn .bar-icon span:nth-child(3) {
  margin-bottom: 0;
}
.header .main-menu-wrapper {
  margin: 0;
  padding: 0;
}
.header .main-menu-wrapper .main-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav ul {
    background-color: var(--primary-theme-color);
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
}
.header .main-menu-wrapper .main-nav li .btn {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 8px;
  border: 1px solid var(--primary-theme-color);
  min-width: 140px;
}
.header .main-menu-wrapper .main-nav li .btn:hover {
  color: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
}
.header .main-menu-wrapper .main-nav li .btn i {
  font-size: 15px;
  margin-right: 5px;
  margin-left: 0;
}
.header .main-menu-wrapper .main-nav li .btn.sign-btn {
  background-color: #fff;
  border: 1px solid #fff;
  color: #041b29;
  box-shadow: inset 0 0 0 0 var(--primary-theme-color);
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}
.header .main-menu-wrapper .main-nav li .btn.sign-btn:hover {
  color: #fff;
  background-color: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
  box-shadow: inset 0 0 0 50px var(--primary-theme-color);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.header .main-menu-wrapper .main-nav li .btn.log-btn {
  min-width: 87px;
}
.header .main-menu-wrapper .main-nav li a {
  font-weight: 600;
  color: #8a909a;
  display: block;
  font-size: 15px;
}
@media (max-width: 1399.98px) {
  .header .main-menu-wrapper .main-nav li a {
    font-size: 14px;
  }
}
.header .main-menu-wrapper .main-nav li.login-link {
  display: none;
}
@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav li.login-link {
    display: block;
  }
}
@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav li > ul {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.25);
    border-radius: 0px 0px 6px 5px;
    display: block;
    font-size: 14px;
    min-width: 200px;
    opacity: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    visibility: hidden;
    z-index: 1000;
    border-top: 1px solid var(--primary-theme-color);
  }
}
@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav li {
    display: block;
    position: relative;
  }
}
.header .main-menu-wrapper .main-nav li.searchbar .togglesearch {
  position: absolute;
  top: 74px;
  right: 8%;
  width: 280px;
  display: none;
  z-index: 999;
  background: #fff;
  margin: 0;
  padding: 10px;
  border-radius: 5px;
}
.header
  .main-menu-wrapper
  .main-nav
  li.searchbar
  .togglesearch
  input[type="text"] {
  width: 182px;
  padding: 5px 10px;
  border: 1px solid var(--primary-theme-color);
  outline: none;
  height: 39px;
  border-radius: 5px 0 0 5px;
}
.header .main-menu-wrapper .main-nav li.searchbar .togglesearch button {
  background: var(--primary-theme-color);
  color: #fff;
  border: 1px solid var(--primary-theme-color);
  outline: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  min-width: auto;
}
.header .main-menu-wrapper .main-nav li.searchbar .togglesearch button:hover {
  color: var(--primary-theme-color);
}
@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav > li {
    margin-right: 30px;
  }
}
@media (min-width: 1200px) {
  .header .main-menu-wrapper .main-nav > li {
    margin-right: 15px;
  }
}
@media (min-width: 1400px) {
  .header .main-menu-wrapper .main-nav > li {
    margin-right: 22px;
  }
}
@media (max-width: 1199.98px) {
  .header .main-menu-wrapper .main-nav > li {
    margin-right: 15px;
  }
}
@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav > li {
    border-bottom: 1px solid #675dd7;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav > li > a i {
    float: right;
    margin-top: 5px;
  }
}
@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav > li > a {
    line-height: 1.5;
    padding: 15px 20px !important;
    color: #fff !important;
    font-size: 14px;
    background: transparent !important;
    border-radius: 0 !important;
  }
}
.header .main-menu-wrapper .main-nav > li:last-child {
  margin-right: 0;
}
.header .main-menu-wrapper .main-nav > li.active > a {
  color: var(--primary-theme-color);
}
.header .main-menu-wrapper .main-nav > li > a {
  position: relative;
}
.header .main-menu-wrapper .main-nav > li > a:hover {
  color: var(--primary-theme-color);
}
@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav > li > a {
    padding: 0;
    line-height: 70px;
  }
}
.header .main-menu-wrapper .main-nav > li > a > i {
  font-size: 12px;
  margin-left: 6px;
}
.header .main-menu-wrapper .main-nav > li.searchbar a {
  color: #8a909a;
}
.header .main-menu-wrapper .main-nav > li.searchbar a i {
  font-size: 16px;
}
.header .main-menu-wrapper .main-nav > li.searchbar a:hover img {
  filter: brightness(0) saturate(100%) invert(41%) sepia(33%) saturate(7191%)
    hue-rotate(230deg) brightness(101%) contrast(99%);
}
@media (max-width: 1199.98px) {
  .header .main-menu-wrapper .main-nav > li.searchbar {
    display: none;
  }
}
.header
  .main-menu-wrapper
  .main-nav
  > li.has-submenu
  .submenu
  .has-submenu
  .submenu::before {
  top: 20px;
  margin-left: -35px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
  border-color: transparent #fff #fff transparent;
}
@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
  }
  .header
    .main-menu-wrapper
    .main-nav
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: -20px;
    margin-right: 0;
    border-top: 1px solid var(--primary-theme-color);
  }
}
.header .main-menu-wrapper .main-nav > li .submenu li {
  position: relative;
}
.header .main-menu-wrapper .main-nav > li .submenu li a {
  display: block;
  padding: 10px 15px;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  -webkit-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  position: relative;
  border-top: 1px solid #fff;
  text-transform: capitalize;
}
.header .main-menu-wrapper .main-nav > li .submenu li a.active {
  color: var(--primary-theme-color);
}
@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav > li .submenu li a.active {
    color: #fff;
  }
}
@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav > li .submenu li a:hover {
    color: var(--primary-theme-color);
  }
}
@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav > li .submenu li a {
    padding: 10px 15px 10px 35px;
    color: #ecf4ef;
    border-top: 1px solid #7d858f;
  }
}
.header .main-menu-wrapper .main-nav > li .submenu > li.has-submenu > a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 13px;
}
@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav > li .submenu > li .inner-submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }
}
.header .main-menu-wrapper .main-nav > li .submenu::before {
  display: none;
}
@media (min-width: 992px) {
  .header .main-menu-wrapper .main-nav > li .submenu::before {
    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
  }
}
@media (max-width: 991.98px) {
  .header .main-menu-wrapper .main-nav {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}
@media (max-width: 991.98px) {
  .header .main-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1060;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
    transform: translateX(-260px);
    -webkit-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    background-color: var(--primary-theme-color);
  }
}
.header .logo {
  display: flex;
  margin-right: 50px;
  width: 180px;
}
@media (max-width: 1399.98px) {
  .header .logo {
    margin-right: 26px;
    max-width: 200px;
  }
}
@media (max-width: 1199.98px) {
  .header .logo {
    margin-right: 40px;
  }
}
@media (max-width: 991.98px) {
  .header .logo.navbar-brand {
    width: 100%;
    text-align: center;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 1199.98px) {
  .header .logo.navbar-brand img {
    max-width: 165px;
  }
}
.header .header-navbar-rht {
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin-right: 0;
  margin-left: auto;
}
.header .header-navbar-rht li {
  margin-right: 30px;
}
.header .header-navbar-rht li.searchbar {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.header .header-navbar-rht li .btn {
  font-weight: 600;
  font-size: 15px;
  min-width: 108px;
  padding: 11px 15px;
  border-radius: 10px;
  color: #fff;
}
.header .header-navbar-rht li .btn:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 1199.98px) {
  .header .header-navbar-rht li .btn {
    padding: 10px 15px;
    min-width: 90px;
    font-size: 14px;
  }
}
@media (max-width: 1399.98px) {
  .header .header-navbar-rht li .btn {
    min-width: 95px;
    padding: 10px 10px;
    font-size: 13px;
  }
}
.header .header-navbar-rht li .btn i {
  margin-right: 5px;
}
.header .header-navbar-rht li .btn.sign-btn {
  background-color: #f6f6f9;
  border: 1px solid #f6f6f9;
  color: #000000;
  box-shadow: inset 0 0 0 0 var(--primary-theme-color);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.header .header-navbar-rht li .btn.sign-btn:hover {
  color: #fff;
  background-color: var(--primary-theme-color);
  border-color: var(--primary-theme-color);
  box-shadow: inset 0 50px 0 0 var(--primary-theme-color);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.header .header-navbar-rht li:last-child {
  margin-right: 0;
}
.header .header-navbar-rht li.new-property-btn {
  margin-right: 24px;
}
.header .header-navbar-rht li.new-property-btn a {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  line-height: 40px;
}
.header .header-navbar-rht li.new-property-btn a:hover {
  color: var(--secondary-theme-color);
}
.header .header-navbar-rht li.new-property-btn a:has(.own-prop) {
  top: 6px;
  flex-direction: column;
  line-height: 20px;
  position: relative;
}
.header .header-navbar-rht li.new-property-btn a .own-prop {
  font-weight: 400;
  padding-right: 6px;
  font-size: 12px;
  position: absolute;
  top: -16px;
  left: 0;
  white-space: nowrap;
}

.header .header-navbar-rht li.new-property-btn a i {
  font-size: 20px;
  margin-right: 4px;
  color: var(--primary-theme-color);
}
.header .header-navbar-rht li.call-btn {
  position: relative;
}

.header .header-navbar-rht li.call-btn span {
  position: absolute;
  white-space: nowrap;
  font-size: 12px;
  top: -6px;
}

.header .header-navbar-rht li.call-btn a {
  color: green;
}
@media (max-width: 1399.98px) {
  .header .header-navbar-rht li.new-property-btn a {
    font-size: 13px;
  }
}
.header .header-navbar-rht li.new-property-btn a.active {
  color: var(--primary-theme-color);
}
@media (max-width: 1199.98px) {
  .header .header-navbar-rht li.new-property-btn {
    /* display: none; */
  }
}
@media (max-width: 1399.98px) {
  .header .header-navbar-rht li.new-property-btn {
    margin-right: 12px;
  }
}
.header .header-navbar-rht .has-arrow .dropdown-toggle {
  color: #fff;
}
.header .header-navbar-rht .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #eeeeee;
  border-right: 2px solid #eeeeee;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  width: 8px;
  vertical-align: 2px;
  margin-left: 10px;
  transform-origin: 66% 66%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.header
  .header-navbar-rht
  .has-arrow
  .dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
}
.header .header-navbar-rht .has-arrow .dropdown-toggle .user-img {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.header .header-navbar-rht .has-arrow .dropdown-toggle .user-img > img {
  height: 46px;
  object-fit: cover;
  width: 46px;
  border: 2px solid #fff;
}
@media (max-width: 767.78px) {
  .header .header-navbar-rht .has-arrow .dropdown-toggle .user-content {
    display: none;
  }
}
.header .header-navbar-rht .has-arrow .user-header {
  padding: 10px 15px;
}
.header .header-navbar-rht .has-arrow .user-header .user-text {
  margin-left: 10px;
}
.header .header-navbar-rht .has-arrow .user-header .user-text h6 {
  font-size: 15px;
  margin-bottom: 2px;
}
.header .header-navbar-rht .has-arrow .logged-item .nav-link {
  font-size: 14px;
  line-height: 85px;
  padding: 0 10px;
}
.header .header-navbar-rht .has-arrow.logged-item .nav-link {
  font-size: 14px;
  line-height: 80px;
  padding: 0;
}
@media (max-width: 767.78px) {
  .header .header-navbar-rht {
    /* display: none; */
  }
}
.header .menu-header {
  background-color: #fff;
  height: 70px;
  padding: 0 15px;
  border-bottom: 0;
  display: none;
}
.header .menu-header .menu-close {
  font-size: 20px;
}
.header .menu-header .menu-close i {
  color: #1e1d85;
}
.header .menu-header .menu-close i:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 991.98px) {
  .header .menu-header {
    display: flex;
  }
}
.header .menu-header .menu-logo {
  max-width: 180px;
}
.header .main-nav > li .submenu > li .submenu {
  left: 100%;
  top: 0;
  margin-top: 10px;
}
@media (max-width: 991.98px) {
  .header .main-nav > li .submenu > li .submenu {
    left: 0;
    margin-top: 0;
  }
}

.menu-opened .main-menu-wrapper {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
}

.main-nav li .flag-dropdown .submenu::before {
  display: none;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.sidebar-overlay.opened {
  display: block;
}

.nav-fixed .header-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.nav-fixed .header-nav .header-nav-wrap {
  max-width: 1320px;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
/*-----------------
	13. Common
-----------------------*/
.custom_check {
  color: #8a909a;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 20px;
}
.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
  background-color: var(--primary-theme-color);
  border-color: var(--primary-theme-color);
}
.custom_check input:checked ~ .checkmark:after {
  display: block;
}
.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #f4f4f4;
  background-color: #f6f6f9;
  border-radius: 5px;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Fontawesome";
  font-weight: 700;
  position: absolute;
  display: none;
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
}

.custom_radio {
  color: #0e0e46;
  display: inline-block;
  position: relative;
  font-size: 14px;
  padding-top: 3px;
  padding-right: 35px;
  margin-right: 50px;
  margin-bottom: 0 !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_radio:last-child {
  margin-right: 0;
}
.custom_radio input {
  position: absolute;
  opacity: 0;
}
.custom_radio input:checked ~ .checkmark:after {
  opacity: 1;
}
.custom_radio .checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: #f6f6f9;
  border: 2px solid #0e0e46;
  border-radius: 50%;
}
.custom_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  left: -1px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 5px solid var(--primary-theme-color);
  background: #fff;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.error-page {
  padding: 50px;
  align-items: center;
  font-weight: 700;
}
@media (max-width: 575.98px) {
  .error-page {
    padding: 50px;
  }
}
.error-page .main-wrapper {
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  min-height: unset;
}

.error-box {
  margin: auto;
  padding: 98px 0;
  text-align: center;
  max-width: 630px;
}
@media (max-width: 1399.98px) {
  .error-box {
    margin: auto;
    padding: 60px 0;
  }
}
@media (max-width: 991.98px) {
  .error-box {
    margin: auto;
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .error-box {
    margin: auto;
    padding: 40px 0;
  }
}
.error-box img {
  margin: 0 0 40px;
}
.error-box h1 {
  font-size: 54px;
  margin: 0 0 11px;
}
@media (max-width: 1399.98px) {
  .error-box h1 {
    font-size: 42px;
  }
}
@media (max-width: 991.98px) {
  .error-box h1 {
    font-size: 28px;
  }
}
@media (max-width: 767.78px) {
  .error-box h1 {
    font-size: 26px;
  }
}
.error-box p {
  margin: auto auto 30px;
  font-weight: 500;
  max-width: 574px;
}
.error-box .error-content {
  max-width: 318px !important;
}
.error-box h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}
.error-box .btn-maintance {
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  padding: 12px 20px;
}
@media (max-width: 767.78px) {
  .error-box .btn-maintance {
    padding: 8px 12px;
    font-size: 14px;
  }
}
.error-box .error-500 {
  margin: 0 0 60px;
}
.error-box .coming-soon {
  margin: 0 0 20px;
}
.error-box .back-button .btn {
  padding: 11px 13px;
  font-size: 15px;
  color: #0e0e46;
  font-weight: 600;
  border-radius: 10px;
  background-color: #f6f6f9;
  border: 1px solid #f6f6f9;
  box-shadow: inset 0 0 0 0 #fff;
}
@media (max-width: 575.98px) {
  .error-box .back-button .btn {
    padding: 10px;
    font-size: 14px;
  }
}
.error-box .back-button .btn:focus,
.error-box .back-button .btn:hover,
.error-box .back-button .btn:active,
.error-box .back-button .btn.active {
  color: #fff;
  background-color: #fff;
  border-color: #f6f6f9;
  box-shadow: inset 0 50px 0 0 var(--primary-theme-color);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.error-box.come-box p {
  margin-bottom: 20px;
}

.serve-form {
  margin: 0 0 39px;
}
@media (max-width: 767.78px) {
  .serve-form {
    padding-bottom: 30px;
  }
}
.serve-form .form-group {
  margin-bottom: 0;
}
.serve-form .form-group .form-control {
  margin-right: 10px;
  min-height: 45px;
}
.serve-form .form-group .form-control::placeholder {
  color: #828282;
  font-weight: 500;
}
@media (max-width: 767.78px) {
  .serve-form .form-group .form-control {
    min-height: 46px;
    font-size: 14px;
  }
}
.serve-form .form-group .button-notific {
  min-width: 115px;
  height: 45px;
  font-weight: 600;
  font-size: 15px;
}
@media (max-width: 767.78px) {
  .serve-form .form-group .button-notific {
    min-width: 100px;
    font-size: 14px;
  }
}

.privacy-section {
  position: relative;
}
.privacy-section .terms-policy p {
  margin: 0 0 38px;
}
@media (max-width: 991.98px) {
  .privacy-section .terms-policy p {
    margin: 0 0 28px;
  }
}
@media (max-width: 767.78px) {
  .privacy-section .terms-policy p {
    margin: 0 0 20px;
  }
}
.privacy-section .terms-policy ul {
  margin: 0 0 38px;
}
.privacy-section .terms-policy ul li {
  margin: 0 0 11px;
}
.privacy-section .terms-policy ul li span {
  margin-right: 10px;
  color: var(--primary-theme-color);
}

.section {
  position: relative;
  padding: 60px 0;
}
@media (max-width: 991.98px) {
  .section {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .section {
    padding: 40px 0;
  }
}
.section.faq-section {
  padding: 60px 0;
}
@media (max-width: 991.98px) {
  .section.faq-section {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .section.faq-section {
    padding: 40px 0;
  }
}
.section.price-section {
  padding: 60px 0;
}
@media (max-width: 991.98px) {
  .section.price-section {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .section.price-section {
    padding: 40px 0 16px;
  }
}

.listing-section {
  position: relative;
  padding: 60px 0 36px;
}
@media (max-width: 991.98px) {
  .listing-section {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .listing-section {
    padding: 40px 0 16px;
  }
}
.listing-section.feature-property-sec {
  padding: 60px 0 36px;
}
@media (max-width: 991.98px) {
  .listing-section.feature-property-sec {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .listing-section.feature-property-sec {
    padding: 40px 0 16px;
  }
}

.btn-outline-light {
  color: #fff;
  background-color: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
  box-shadow: inset 0 0 0 0 #fff;
}
.btn-outline-light:hover {
  background-color: #fff;
  border-color: var(--primary-theme-color);
  color: var(--primary-theme-color);
  box-shadow: inset 0 50px 0 0 #fff;
}

.btn-size {
  padding: 18px 10px;
  line-height: 23px;
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .btn-size {
    padding: 15px 10px;
    line-height: 15px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
}

.progress-wrap {
  position: fixed;
  right: 12px;
  bottom: 12px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(75, 64, 237, 0.2);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  transform: translateY(15px);
  -webkit-transform: translateY(15px);
  -ms-transform: translateY(15px);
}
.progress-wrap svg.progress-circle path {
  stroke: var(--primary-theme-color);
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f062";
  font-family: "Fontawesome";
  text-align: center;
  line-height: 35px;
  font-size: 17px;
  color: var(--primary-theme-color);
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: #fff;
}
.page-loader .page-loader-inner {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 0 100px;
  text-align: center;
}
.page-loader .page-loader-inner img {
  position: relative;
  top: -40px;
  left: 40px;
  z-index: 9;
  width: 45px;
}
@media (max-width: 575.98px) {
  .page-loader .page-loader-inner img {
    left: 17px;
  }
}
.page-loader .page-loader-inner label {
  font-size: 10px;
  opacity: 0;
  display: inline-block;
  color: var(--primary-theme-color);
  margin: 0 2px;
}
.page-loader .page-loader-inner label:nth-child(1) {
  -webkit-animation: lol 3s 500ms infinite ease-in-out;
  animation: lol 3s 500ms infinite ease-in-out;
}
.page-loader .page-loader-inner label:nth-child(2) {
  -webkit-animation: lol 3s 400ms infinite ease-in-out;
  animation: lol 3s 400ms infinite ease-in-out;
}
.page-loader .page-loader-inner label:nth-child(3) {
  -webkit-animation: lol 3s 300ms infinite ease-in-out;
  animation: lol 3s 300ms infinite ease-in-out;
}
@media (max-width: 575.98px) {
  .page-loader .page-loader-inner label {
    position: relative;
    left: -22px;
  }
}

@keyframes lol {
  0% {
    opacity: 0;
    transform: translateX(-200px);
    -webkit-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(200px);
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
  }
}
/*-----------------	
    14. Breadcrumb
-----------------------*/
.breadcrumb {
  margin: 0;
  padding: 90px 0;
  /* background-image: url(../img/bg/breadcrumb-bg.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;
}
.breadcrumb:before {
  content: "";
  background: #0e0e46;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.breadcrumb .bread-crumb-head {
  text-align: center;
  position: relative;
}
.breadcrumb .bread-crumb-head .breadcrumb-title h2 {
  color: #fff;
  font-size: 34px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
@media (max-width: 991.98px) {
  .breadcrumb .bread-crumb-head .breadcrumb-title h2 {
    font-size: 28px;
  }
}
@media (max-width: 767.78px) {
  .breadcrumb .bread-crumb-head .breadcrumb-title h2 {
    font-size: 24px;
  }
}
.breadcrumb .bread-crumb-head .breadcrumb-list ul li {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.breadcrumb .bread-crumb-head .breadcrumb-list ul li span {
  color: #fcaf3d;
  margin: 0 7px 0 7px;
  padding: 0;
}
.breadcrumb .bread-crumb-head .breadcrumb-list ul li a {
  color: #fff;
}
.breadcrumb .bread-crumb-head .breadcrumb-list ul li a:hover {
  color: #fcaf3d;
}
.breadcrumb .bread-crumb-head .breadcrumb-list ul li + li {
  padding-left: 7px;
}
.breadcrumb .bread-crumb-head .breadcrumb-list ul li + li::before {
  content: "/";
  float: left;
  padding-right: 7px;
  color: #fcaf3d;
  font-size: 14px;
  font-weight: 600;
}
.breadcrumb .breadcrumb-border-img img {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 991.98px) {
  .breadcrumb {
    padding: 60px 0;
  }
}
@media (max-width: 767.78px) {
  .breadcrumb {
    padding: 50px 0;
  }
}

/*-----------------	
    15. Home
-----------------------*/
.banner-section {
  padding: 100px 0 100px;
  background: url(../assets/images/banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  /* overflow: hidden; */
  z-index: 1;
}
.banner-section:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #181818d1 22%, transparent);
  z-index: -1;
}
@media (max-width: 991.98px) {
  .banner-section {
    padding: 50px 0 50px;
  }
}
@media (max-width: 767.78px) {
  .banner-section {
    padding: 40px 0 40px;
  }
}

@media (max-width: 991.98px) {
  .banner-content {
    text-align: center;
  }
}
.banner-content h1 {
  color: #fff;
  font-size: 46px;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 20px;
}
.banner-content span {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  display: inline-block;
  color: #ff5c41;
  color: #efff00;
}
@media (max-width: 1399.98px) {
  .banner-content h1 {
    font-size: 48px;
  }
}
@media (max-width: 1199.98px) {
  .banner-content h1 {
    font-size: 44px;
  }
}
@media (max-width: 991.98px) {
  .banner-content h1 {
    font-size: 28px;
    max-width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 767.78px) {
  .banner-content h1 {
    font-size: 26px;
  }
}
.banner-content h1 span {
  color: var(--secondary-theme-color);
  font-size: 48px;
}
.banner-content p {
  font-weight: 500;
  font-size: 18px;
  /* color: #0e0e46; */
  color: #fff;
  margin-bottom: 40px;
  max-width: 570px;
  width: 100%;
}
@media (max-width: 767.78px) {
  .banner-content p {
    font-size: 15px;
    margin-bottom: 30px;
  }
}
.banner-content .btn {
  max-width: 200px;
  font-size: 14px;
  border-radius: 0;
  padding: 16px 20px;
}
.banner-content .btn i {
  margin-right: 10px;
}
@media (max-width: 767.78px) {
  .banner-content .btn {
    max-width: 180px;
    padding: 10px 15px;
  }
}

.banner-search .banner-tab {
  padding-bottom: 20px;
}
.banner-search .banner-tab .nav-tabs {
  margin: 0;
  padding: 0;
  border: 0;
}
@media (max-width: 991.98px) {
  .banner-search .banner-tab .nav-tabs {
    justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .banner-search .banner-tab .nav-tabs {
    flex-direction: column;
  }
}
.banner-search .banner-tab .nav-tabs .nav-item {
  margin-right: 25px;
}
.banner-search .banner-tab .nav-tabs .nav-item:last-child {
  margin-right: 0;
}
.banner-search .banner-tab .nav-tabs .nav-item .nav-link {
  font-weight: 600;
  font-size: 15px;
  color: #0e0e46;
  border: 1px solid #f6f4ff;
  background: #fff;
  position: relative;
  border-radius: 10px;
  padding: 17px 20px;
  min-width: 180px;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
@media (max-width: 991.98px) {
  .banner-search .banner-tab .nav-tabs .nav-item .nav-link {
    padding: 14px 15px;
    min-width: 160px;
    font-size: 14px;
  }
}
.banner-search .banner-tab .nav-tabs .nav-item .nav-link img {
  margin-right: 8px;
  -webkit-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}
.banner-search .banner-tab .nav-tabs .nav-item .nav-link:focus {
  background: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
  color: #fff;
}
.banner-search .banner-tab .nav-tabs .nav-item .nav-link:hover {
  background: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
  color: #fff;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.banner-search .banner-tab .nav-tabs .nav-item .nav-link:hover img {
  filter: invert(1) brightness(100);
}
.banner-search .banner-tab .nav-tabs .nav-item .nav-link.active {
  background: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
  color: #fff;
}
.banner-search .banner-tab .nav-tabs .nav-item .nav-link.active img {
  filter: invert(1) brightness(100);
}
.banner-search .banner-tab .nav-tabs .nav-item .nav-link.active:before {
  border: 7px solid var(--primary-theme-color);
  border-color: var(--primary-theme-color);
  content: "";
  position: absolute;
  bottom: -14px;
  left: 50%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
}
@media (max-width: 575.98px) {
  .banner-search .banner-tab .nav-tabs .nav-item {
    margin: 0 0 10px 0;
  }
}
.banner-search .banner-tab-property {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1399.98px) {
  .banner-search .banner-tab-property {
    padding: 25px;
  }
}
@media (max-width: 991.98px) {
  .banner-search .banner-tab-property .banner-property-info {
    /* flex-direction: column; */
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid {
  margin-right: 10px;
  position: relative;
}
@media (max-width: 991.98px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:last-child {
  margin-right: 0;
}
@media (max-width: 767.78px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:last-child {
    margin: 0;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:last-child
  a {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background: var(--primary-theme-color);
  color: #fff;
  border: 1px solid var(--primary-theme-color);
  font-size: 20px;
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:last-child
  a:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 991.98px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:last-child
    a {
    width: 100%;
  }
}
@media (max-width: 767.78px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:last-child
    a {
    height: 50px;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:nth-child(3)
  input {
  width: 300px;
}
@media (max-width: 1399.98px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:nth-child(3)
    input {
    width: 260px;
  }
}
@media (max-width: 1199.98px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:nth-child(3)
    input {
    width: auto;
  }
}
@media (max-width: 991.98px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:nth-child(3)
    input {
    width: 100%;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:nth-child(4)
  input {
  width: 120px;
}
@media (max-width: 991.98px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:nth-child(4)
    input {
    width: 100%;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:nth-child(5)
  input {
  width: 120px;
}
@media (max-width: 991.98px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:nth-child(5)
    input {
    width: 100%;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid:nth-child(2) {
  width: 30%;
}
@media (max-width: 991.98px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid:nth-child(2) {
    width: 100%;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid
  .form-control,
.banner-tab-property .banner-property-grid select {
  background: #f6f6f9;
  border: 1px solid #f4f4f4;
  font-size: 14px;
  border-radius: 10px;
  min-height: 50px;
}
@media (max-width: 767.78px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid
    .form-control {
    font-size: 14px;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid
  .form-control::placeholder {
  font-size: 14px;
  color: var(--bs-body-color);
  font-weight: 400;
}
@media (max-width: 767.78px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid
    .form-control::placeholder {
    font-size: 14px;
  }
}
@media (max-width: 767.78px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid
    .form-control {
    min-height: 50px;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid
  .select2-container
  .select2-selection--single {
  height: 60px;
  border-radius: 10px;
}
@media (max-width: 767.78px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid
    .select2-container
    .select2-selection--single {
    height: 50px;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #0e0e46;
  font-size: 16px;
  line-height: 60px;
}
@media (max-width: 767.78px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 50px;
    font-size: 14px;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 60px;
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #0e0e46 transparent transparent;
}
@media (max-width: 767.78px) {
  .banner-search
    .banner-tab-property
    .banner-property-info
    .banner-property-grid
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 50px;
  }
}
.banner-search
  .banner-tab-property
  .banner-property-info
  .banner-property-grid
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #0e0e46;
}

@media (max-width: 991.98px) {
  .banner-img-slider .banner-images {
    display: flex;
    justify-content: center;
  }
}
.banner-img-slider .banner-images img {
  border-radius: 50px 0 0 0;
  width: auto;
  text-align: center;
}
.banner-img-slider .owl-dots {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.banner-img-slider .owl-dots button {
  margin-right: 0 !important;
  margin-bottom: 5px;
}

.section-heading {
  margin-bottom: 50px;
}
.section-heading h2 {
  color: #0e0e46;
  position: relative;
  margin: 0 0 15px;
  font-size: 36px;
}
@media (max-width: 991.98px) {
  .section-heading h2 {
    font-size: 28px;
  }
}
@media (max-width: 767.78px) {
  .section-heading h2 {
    font-size: 22px;
  }
}
.section-heading h4 {
  color: #727272;
  font-size: 20px;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .section-heading h4 {
    font-size: 18px;
  }
}
@media (max-width: 767.78px) {
  .section-heading h4 {
    font-size: 16px;
  }
}
.section-heading p {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}
@media (max-width: 767.78px) {
  .section-heading p {
    font-size: 16px;
  }
}
@media (max-width: 991.98px) {
  .section-heading {
    margin-bottom: 40px;
  }
}
@media (max-width: 767.78px) {
  .section-heading {
    margin-bottom: 30px;
  }
}

.sec-line {
  position: relative;
  margin-bottom: 15px;
  line-height: 0;
}
.sec-line .sec-line1 {
  width: 16px;
  height: 6px;
  background: var(--secondary-theme-color);
  border-radius: 30px;
  display: inline-block;
  margin-right: 5px;
}
.sec-line .sec-line2 {
  width: 46px;
  height: 6px;
  background: var(--secondary-theme-color);
  border-radius: 30px;
  display: inline-block;
}

.howit-work {
  padding: 80px 0;
}
@media (max-width: 991.98px) {
  .howit-work {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .howit-work {
    padding: 40px 0 16px;
  }
}

.section-heading {
  flex-direction: column;
}
.section-heading h6 {
  font-size: 28px;
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .section-heading h6 {
    font-size: 24px;
  }
}

.howit-work-card {
  flex-direction: column;
  text-align: center;
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  border-radius: 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  padding: 24px;
}
@media (max-width: 991.98px) {
  .howit-work-card {
    margin-bottom: 24px;
  }
}
.howit-work-card:hover {
  transform: translatey(-20px);
}
.howit-work-card .work-card-icon {
  margin: 0 0 16px;
}
.howit-work-card .work-card-icon span {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background: var(--primary-theme-color);
}
.howit-work-card h4 {
  margin: 0 0 16px;
  font-size: 20px;
}
@media (max-width: 767.78px) {
  .howit-work-card h4 {
    font-size: 18px;
  }
}

.property-type-sec {
  background: var(--primary-theme-color);
  position: relative;
  padding: 80px 0;
}
@media (max-width: 991.98px) {
  .property-type-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .property-type-sec {
    padding: 40px 0;
  }
}
.property-type-sec .section-heading {
  align-items: start;
  margin-bottom: 30px;
}
@media (max-width: 767.78px) {
  .property-type-sec .section-heading {
    align-items: center;
    text-align: center;
  }
}
.property-type-sec .section-heading h2 {
  color: #fff;
  max-width: 300px;
}
.property-type-sec .section-heading h2:before {
  left: 0;
}
.property-type-sec .section-heading h2:after {
  left: 21px;
}
@media (max-width: 767.78px) {
  .property-type-sec .section-heading h2:after {
    left: 50%;
    transform: translatex(-50%);
  }
}
.property-type-sec .section-heading p {
  color: #fff;
}
.property-type-sec .section-shape-imgs .rectangle-left {
  position: absolute;
  top: 0;
  left: 0;
}
.property-type-sec .section-shape-imgs .rectangle-right {
  position: absolute;
  right: 0;
  bottom: 0;
}
.property-type-sec .owl-nav.mynav1 {
  position: relative;
}
@media (max-width: 767.78px) {
  .property-type-sec .owl-nav.mynav1 {
    margin-bottom: 30px;
  }
}

.property-card {
  flex-direction: column;
  text-align: center;
  padding: 40px 30px;
  background: #fff;
  border-radius: 10px;
}
.property-card .property-img {
  margin-bottom: 24px;
}
.property-card .property-img img {
  height: 71px;
}
.property-card h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
.property-card p,
.property-card span {
  font-weight: 500;
  margin-bottom: 0;
}

.owl-nav button {
  width: 44px;
  height: 44px;
  background: #fff;
  border-radius: 50%;
  border: none;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.owl-nav button.owl-prev {
  margin-right: 10px;
}
.owl-nav button:hover {
  background: var(--primary-theme-color);
  color: #fff;
}

.product-custom .feature-rating {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 20px 0 20px;
  width: 100%;
}
.product-custom .feature-rating .featured {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
}
.product-custom .feature-rating .new-featured {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  margin-left: 8px;
}
.product-custom .feature-rating .favourite {
  position: relative;
  top: 0;
  right: 0;
}

.bg-01 {
  position: absolute;
  top: 30%;
  right: 100px;
  z-index: -1;
}
@media (max-width: 1399.98px) {
  .bg-01 {
    right: 20px;
    width: 70px;
  }
}

.bg-02 {
  position: absolute;
  right: 100px;
  bottom: 18%;
}
@media (max-width: 1399.98px) {
  .bg-02 {
    right: 40px;
  }
}

.bg-03 {
  position: absolute;
  top: 18%;
  left: 160px;
}
@media (max-width: 1399.98px) {
  .bg-03 {
    left: 40px;
  }
}

.bg-04 {
  position: absolute;
  bottom: 160px;
  left: 25px;
}

.bg-05 {
  position: absolute;
  bottom: 78px;
  left: 23px;
  z-index: -1;
}

.bg-06 {
  position: absolute;
  bottom: 25px;
  left: 150px;
}
@media (max-width: 1399.98px) {
  .bg-06 {
    left: 20px;
  }
}

.bg-07 {
  position: absolute;
  top: 10%;
  right: 170px;
}
@media (max-width: 1399.98px) {
  .bg-07 {
    right: 100px;
  }
}

.bg-08 {
  position: absolute;
  top: 10%;
  right: 100px;
}
@media (max-width: 1399.98px) {
  .bg-08 {
    right: 40px;
  }
}

.bg-09 {
  position: absolute;
  right: 130px;
  bottom: 80px;
}
@media (max-width: 1399.98px) {
  .bg-09 {
    right: 40px;
  }
}

.bg-10 {
  position: absolute;
  bottom: 40px;
  left: -50px;
  z-index: -1;
}
@media (max-width: 1399.98px) {
  .bg-10 {
    left: -40px;
  }
}

.feature-property-sec {
  padding: 80px 0;
  background: #f5f5f5;
  position: relative;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .feature-property-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .feature-property-sec {
    padding: 40px 0;
  }
}
.feature-property-sec .section-heading h2:before {
  left: 185px;
}
.feature-property-sec .section-heading h2:after {
  right: 198px;
}
.feature-property-sec.for-rent .section-heading h2:before {
  left: 176px;
}
.feature-property-sec .feature-slider.owl-carousel .owl-nav {
  text-align: center;
  position: absolute;
  top: -110px;
  right: 0;
}
@media (max-width: 767.78px) {
  .feature-property-sec .feature-slider.owl-carousel .owl-nav {
    position: static;
  }
}
.feature-property-sec .feature-slider.owl-carousel .owl-nav button {
  background: #fff;
  color: #0e0e46;
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
}
.feature-property-sec .feature-slider.owl-carousel .owl-nav button:hover {
  background: var(--primary-theme-color);
  color: #fff;
}
.feature-property-sec .view-property-btn {
  margin-top: 26px;
}
@media (max-width: 991.98px) {
  .feature-property-sec .view-property-btn {
    margin-top: 16px;
  }
}
@media (max-width: 767.78px) {
  .feature-property-sec .view-property-btn {
    margin-top: 30px;
  }
}
.feature-property-sec.for-rent .favourite {
  position: absolute;
  top: unset;
  right: 20px;
  bottom: 20px;
}
.feature-property-sec.for-rent .rating .rating-count {
  margin-right: 5px;
}

.cities-list-sec {
  padding: 80px 0;
}
.cities-list-sec .section-heading h2:before {
  left: 110px;
}
.cities-list-sec .section-heading h2:after {
  right: 100px;
}
@media (max-width: 991.98px) {
  .cities-list-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .cities-list-sec {
    padding: 40px 0;
  }
}
.cities-list-sec .city-list {
  position: relative;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  margin: 0 0 24px;
  border-radius: 10px;
  overflow: hidden;
}
.cities-list-sec .city-list .city-img {
  position: relative;
  border-radius: 10px;
  z-index: -1;
}
.cities-list-sec .city-list .city-img img {
  border-radius: 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  height: 247px;
  object-fit: cover;
}
.cities-list-sec .city-list .city-img:before {
  content: "";
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 1;
}
.cities-list-sec .city-list .city-name {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 24px 24px;
}
.cities-list-sec .city-list .city-name h5 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}
@media (max-width: 991.98px) {
  .cities-list-sec .city-list .city-name h5 {
    font-size: 18px;
  }
}
.cities-list-sec .city-list .city-name span,
.cities-list-sec .city-list .city-name p {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
}
.cities-list-sec .city-list .arrow-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
}
.cities-list-sec .city-list .arrow-overlay a {
  width: 70px;
  height: 70px;
  background: var(--secondary-theme-color);
  border-radius: 50%;
  color: #fff;
  transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  position: relative;
  right: -8px;
  bottom: -15px;
}
@media (max-width: 991.98px) {
  .cities-list-sec .city-list .arrow-overlay a {
    width: 50px;
    height: 50px;
  }
}
.cities-list-sec .city-list:hover .arrow-overlay a {
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.cities-list-sec .city-list:hover .city-img {
  z-index: -1;
}
.cities-list-sec .city-list:hover .city-img img {
  transform: scale(1.14);
  -webkit-transform: scale(1.14);
  -ms-transform: scale(1.14);
}
.cities-list-sec .city-list:hover .city-img::before {
  z-index: 1;
}

.city-card-slider .owl-dots {
  margin-top: 26px;
}
@media (max-width: 991.98px) {
  .city-card-slider .owl-dots {
    margin-top: 16px;
  }
}
@media (max-width: 767.78px) {
  .city-card-slider .owl-dots {
    margin-top: 6px;
  }
}

.city-card-slider .owl-dots,
.blog-slider .owl-dots,
.banner-img-slider .owl-dots {
  text-align: center;
  line-height: 0;
  display: flex;
  justify-content: center;
}
.city-card-slider .owl-dots button,
.blog-slider .owl-dots button,
.banner-img-slider .owl-dots button {
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.city-card-slider .owl-dots button span,
.blog-slider .owl-dots button span,
.banner-img-slider .owl-dots button span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e3e3e3;
  display: inline-block;
}
.city-card-slider .owl-dots button.active,
.blog-slider .owl-dots button.active,
.banner-img-slider .owl-dots button.active {
  background: #fff;
  width: 22px;
  height: 22px;
  border: 2px solid var(--primary-theme-color);
  position: relative;
}
.city-card-slider .owl-dots button.active span,
.blog-slider .owl-dots button.active span,
.banner-img-slider .owl-dots button.active span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--primary-theme-color);
}

.counter-sec {
  padding: 80px 0 0;
  background: #fff;
}
.counter-sec .left-content {
  padding-right: 70px;
}
.counter-sec .left-content ul li {
  margin-bottom: 8px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  font-weight: 500;
}

.counter-sec .left-content ul li i {
  position: relative;
  top: 4px;
  color: var(--secondary-theme-color);
}

.counter-sec .left-content ul {
  margin-top: 20px;
}
@media (max-width: 991.98px) {
  .counter-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .counter-sec {
    padding: 40px 0 16px;
  }
}
.counter-sec .counter-box {
  text-align: center;
  background: transparent;
  padding: 20px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  border-radius: 15px;
}
@media (max-width: 767.78px) {
  .counter-sec .counter-box {
    margin-bottom: 24px;
  }
}
.counter-sec .counter-box.active {
  background: #fff;
}
.counter-sec .counter-box.active h3 {
  color: #1e1d85;
}
.counter-sec .counter-box.active h5 {
  color: #1e1d85;
}
.counter-sec .counter-box .counter-icon {
  margin-bottom: 20px;
}
.counter-sec .counter-box .counter-icon img {
  height: 70px;
}
.counter-sec .counter-box h3 {
  font-size: 46px;
  color: var(--secondary-theme-color);
  margin-bottom: 5px;
}
@media (max-width: 1199.98px) {
  .counter-sec .counter-box h3 {
    font-size: 40px;
  }
}
@media (max-width: 991.98px) {
  .counter-sec .counter-box h3 {
    font-size: 30px;
  }
}
@media (max-width: 767.78px) {
  .counter-sec .counter-box h3 {
    font-size: 24px;
  }
}
.counter-sec .counter-box h5 {
  font-size: 18px;
  /* color: #fff; */
  font-weight: 500;
  margin-bottom: 0;
}
.counter-sec .counter-box:hover {
  background: transparent;
}

.main-property-sec {
  padding: 80px 0;
  position: relative;
}
@media (max-width: 991.98px) {
  .main-property-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .main-property-sec {
    padding: 40px 0;
  }
}

.single-property-card .img-card {
  border-radius: 10px;
  overflow: hidden;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  position: relative;
}
.single-property-card .img-card img {
  border-radius: 10px;
  width: 100%;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.single-property-card .img-card:hover img {
  transform: scale(1.14);
  -webkit-transform: scale(1.14);
  -ms-transform: scale(1.14);
}
.single-property-card .img-card:hover::before {
  border: 20px solid transparent;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.single-property-card .img-card::before {
  content: "";
  width: 100%;
  height: 100%;
  border: 20px solid transparent;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
@media (max-width: 991.98px) {
  .single-property-card {
    margin-bottom: 24px;
    text-align: center;
  }
}
.single-property-card .buy-property {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  margin: -55px 20px 0 20px;
  padding: 24px;
  border-radius: 10px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .single-property-card .buy-property {
    margin: -45px 20px 0;
    padding: 20px;
  }
}
@media (max-width: 991.98px) {
  .single-property-card .buy-property {
    margin: -45px auto 0;
    padding: 20px;
    width: 80%;
  }
}
@media (max-width: 767.78px) {
  .single-property-card .buy-property {
    width: 90%;
  }
}
@media (max-width: 575.98px) {
  .single-property-card .buy-property {
    margin: -35px auto 0;
    padding: 15px;
  }
}
.single-property-card .buy-property .arrow {
  width: 50px;
  height: 50px;
  background: #ec4040;
  border: 1px solid #ec4040;
  border-radius: 50%;
  color: #fff;
  flex-shrink: 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.single-property-card .buy-property .arrow.sell-arrow {
  background: #fcaf3d;
  border: 1px solid #fcaf3d;
}
.single-property-card .buy-property .arrow.sell-arrow:hover {
  background: #fff;
  color: #fcaf3d;
}
.single-property-card .buy-property .arrow.rent-arrow {
  background: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
}
.single-property-card .buy-property .arrow.rent-arrow:hover {
  background: #fff;
  color: var(--primary-theme-color);
}
.single-property-card .buy-property .arrow:hover {
  background: #fff;
  color: #ec4040;
}
@media (max-width: 1199.98px) {
  .single-property-card .buy-property .arrow {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 991.98px) {
  .single-property-card .buy-property .arrow {
    width: 46px;
    height: 46px;
  }
}
.single-property-card .buy-property h4 {
  margin-bottom: 0;
}
.single-property-card .buy-property h4 a {
  color: #0e0e46;
  font-size: 22px;
}
.single-property-card .buy-property h4 a:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 1199.98px) {
  .single-property-card .buy-property h4 {
    font-size: 22px;
  }
}
@media (max-width: 767.78px) {
  .single-property-card .buy-property h4 {
    font-size: 18px;
  }
}

.main-property-details {
  padding: 0 0 80px;
  position: relative;
}
@media (max-width: 991.98px) {
  .main-property-details {
    padding: 0 0 26px;
  }
}
@media (max-width: 767.78px) {
  .main-property-details {
    padding: 0 0 16px;
  }
}

.partners-sec {
  padding: 0 0 80px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 80px;
}
@media (max-width: 991.98px) {
  .partners-sec {
    padding: 50px 0 0;
  }
}
@media (max-width: 767.78px) {
  .partners-sec {
    padding: 40px 0 0;
  }
}
.partners-sec .section-heading h2:before {
  left: 172px;
}
.partners-sec .section-heading h2:after {
  right: 193px;
}
.partners-sec .section-heading h2 {
  max-width: 530px;
  text-align: center;
}
@media (max-width: 991.98px) {
  .partners-sec .section-heading h2 {
    max-width: 100%;
  }
}
.partners-sec .partner-icon {
  background: #f7f6ff;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
}
.partners-sec .partner-icon img {
  width: auto;
}

.testimonial-sec {
  padding: 80px 0;
  background-color: var(--primary-theme-color);
  position: relative;
}
@media (max-width: 991.98px) {
  .testimonial-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .testimonial-sec {
    padding: 40px 0;
  }
}
.testimonial-sec .section-heading h2:before {
  left: 65px;
}
.testimonial-sec .section-heading h2:after {
  right: 60px;
}
.testimonial-sec:before {
  content: "";
  background: url(../assets/images/testimonial-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.testimonial-sec .section-heading h2 {
  color: #fff;
}
.testimonial-sec .section-heading p {
  color: #f2f2f2;
}

.testimonial-card {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  border: 4px solid #fff;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  padding: 24px;
}
.testimonial-card .user-icon {
  margin-bottom: 24px;
}
.testimonial-card .user-icon img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: auto;
}
.testimonial-card p {
  margin-bottom: 30px;
}
.testimonial-card h4 {
  margin-bottom: 7px;
  font-size: 20px;
}
.testimonial-card h4 a {
  color: #0e0e46;
}
.testimonial-card h4 a:hover {
  color: var(--primary-theme-color);
}
.testimonial-card .rating {
  justify-content: center;
}
.testimonial-card .rating span {
  margin-right: 3px;
}
.testimonial-card .rating span svg {
  color: #fcaf3d;
}
.testimonial-card .rating span:last-child {
  margin-right: 0;
}
.testimonial-card:hover {
  border: 4px solid #fcaf3d;
}
.testimonial-slider {
  padding-bottom: 70px;
}
.testimonial-slider .testimonial-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-slider .swiper-slide {
  height: unset;
}
.testimonial-slider.testimonialnext-prev .owl-nav {
  margin-top: 0;
}
.testimonial-slider.testimonialnext-prev .owl-nav .owl-prev button,
.testimonial-slider.testimonialnext-prev .owl-nav .owl-nav button {
  box-shadow: 0px 4px 14px 0px rgba(214, 214, 214, 0.2392156863);
}
.testimonial-slider .owl-nav {
  text-align: center;
  margin-top: 50px;
}
@media (max-width: 991.98px) {
  .testimonial-slider .owl-nav {
    margin-top: 40px;
  }
}
@media (max-width: 767.78px) {
  .testimonial-slider .owl-nav {
    margin-top: 30px;
  }
}
.testimonial-slider .owl-nav button.owl-prev,
.testimonial-slider .owl-nav button.owl-next {
  width: 44px;
  height: 44px;
  background: #fff;
  border-radius: 50%;
  border: none;
  color: #0e0e46;
}
.testimonial-slider .owl-nav button.owl-prev.owl-prev,
.testimonial-slider .owl-nav button.owl-next.owl-prev {
  margin-right: 10px;
}
.testimonial-slider .owl-nav button.owl-prev:hover,
.testimonial-slider .owl-nav button.owl-next:hover {
  background: var(--primary-theme-color);
  color: #fff;
}

.price-section {
  position: relative;
  padding: 80px 0;
}
@media (max-width: 991.98px) {
  .price-section {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .price-section {
    padding: 40px 0 16px;
  }
}
.price-section .pricing-tab {
  margin-bottom: 50px;
}
@media (max-width: 767.78px) {
  .price-section .pricing-tab {
    flex-direction: column;
  }
}
@media (max-width: 767.78px) {
  .price-section .pricing-tab {
    margin-bottom: 40px;
  }
}
.price-section .pricing-tab .section-heading {
  align-items: start;
  margin-bottom: 0;
  text-align: center;
}
@media (max-width: 767.78px) {
  .price-section .pricing-tab .section-heading {
    align-items: center;
    margin-bottom: 40px;
  }
}
.price-section .pricing-tab .section-heading h2::before {
  left: 0;
}
.price-section .pricing-tab .section-heading h2::after {
  left: 20px;
}
@media (max-width: 767.78px) {
  .price-section .pricing-tab .section-heading h2::after {
    left: 50%;
    transform: translatex(-50%);
  }
}
.price-section .pricing-tab ul.nav-pills {
  border: 1px solid #e3e3e3;
  background: #fff;
  border-radius: 10px;
  max-width: 250px;
}
.price-section .pricing-tab ul.nav-pills .nav-link {
  color: #8a909a;
  font-size: 15px;
  font-weight: 700;
  padding: 11px 30px;
}
.price-section .pricing-tab ul.nav-pills .nav-link.active {
  color: #fff;
  background: var(--primary-theme-color);
}
.price-section .pricing-tab ul.nav-pills li:first-child .nav-link {
  border-radius: 5px 0 0 5px;
}
.price-section .pricing-tab ul.nav-pills li:last-child .nav-link {
  border-radius: 0 5px 5px 0;
}

.price-card {
  border: 1px solid #e3e3e3;
  background: #fff;
  padding: 35px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
}
@media (max-width: 991.98px) {
  .price-card {
    margin: 0 0 24px;
    padding: 30px;
  }
}
@media (max-width: 767.78px) {
  .price-card {
    margin: 0 0 24px;
    padding: 25px;
  }
}
.price-card .price-sticker {
  position: absolute;
  top: 10px;
  right: -10px;
}
.price-card .price-title {
  margin: 0 0 35px;
}
.price-card .price-title h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 15px;
}
@media (max-width: 1399.98px) {
  .price-card .price-title h3 {
    font-size: 24px;
  }
}
@media (max-width: 991.98px) {
  .price-card .price-title h3 {
    font-size: 22px;
  }
}
@media (max-width: 767.78px) {
  .price-card .price-title h3 {
    font-size: 20px;
  }
}
@media (max-width: 991.98px) {
  .price-card .price-title {
    margin: 0 0 30px;
  }
}
@media (max-width: 767.78px) {
  .price-card .price-title {
    margin: 0 0 25px;
  }
}
.price-card .price-features {
  margin: 0 0 35px 0;
  border-radius: 10px;
}
.price-card .price-features h5 {
  font-size: 16px;
  margin: 0 0 25px;
}
.price-card .price-features ul li {
  margin-bottom: 20px;
  position: relative;
}
.price-card .price-features ul li span {
  margin-right: 10px;
  color: var(--primary-theme-color);
}
.price-card .price-features ul li:last-child {
  margin: 0;
}
.price-card .price-features.professional ul li span {
  color: #fcaf3d;
}
.price-card .price-features.enterprise ul li span {
  color: #0dca95;
}
@media (max-width: 991.98px) {
  .price-card .price-features {
    margin: 0 0 30px;
  }
}
@media (max-width: 767.78px) {
  .price-card .price-features {
    margin: 0 0 25px;
  }
}
.price-card .price-btn a {
  padding: 11px 10px;
  width: 100%;
  background: var(--primary-theme-color);
  display: block;
  text-align: center;
  font-size: 15px;
  color: #fff;
  border-radius: 10px;
  font-weight: 700;
}
.price-card .price-btn a:hover {
  color: var(--primary-theme-color);
}
.price-card .price-btn a:focus {
  color: var(--primary-theme-color);
}

.product-custom {
  width: 100%;
  height: 100%;
}
.product-custom .profile-widget {
  background-color: #fff;
  box-shadow: 0px 0 4px rgb(0 0 0 / 20%);
  position: relative;
  margin-bottom: 0;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.product-custom .doc-img,
.product-custom .pro-img {
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  border-radius: 10px 10px 0 0;
}
.product-custom .doc-img .property-img,
.product-custom .pro-img .property-img {
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  border-radius: 10px 10px 0 0;
  display: block;
}
/* .product-custom .doc-img .property-img:before, */
.product-custom .pro-img .property-img:before {
  content: "";
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}
.product-custom .doc-img .property-img img,
.product-custom .pro-img .property-img img {
  border-radius: 10px 10px 0 0;
  z-index: -1;
  position: relative;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  width: 100%;
  height: 292px;
  object-fit: cover;
}
.product-custom .doc-img:hover .property-img img,
.product-custom .pro-img:hover .property-img img {
  /* transform: scale(1.14); */
}
.product-custom .doc-img .favourite.selected,
.product-custom .pro-img .favourite.selected {
  background: var(--secondary-theme-color);
  border: 1px solid var(--secondary-theme-color);
}
.product-custom .doc-img .user-avatar,
.product-custom .pro-img .user-avatar {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.product-custom .doc-img .user-avatar img,
.product-custom .pro-img .user-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.product-custom .product-amount {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}
.product-custom .product-amount span {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
}
@media (max-width: 991.98px) {
  .product-custom .product-amount span {
    font-size: 20px;
  }
}
@media (max-width: 767.78px) {
  .product-custom .product-amount span {
    font-size: 18px;
  }
}
.product-custom .product-amount h5 {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}
.product-custom .featured {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}
.product-custom .featured span {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background-color: var(--primary-theme-color);
  padding: 5px 8px;
  display: inline-block;
  border-radius: 8px;
  display: none;
}
.product-custom .new-featured {
  position: absolute;
  top: 20px;
  left: 105px;
  z-index: 1;
}
.product-custom .new-featured span {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background-color: #ff0000;
  padding: 5px 8px;
  display: inline-block;
  border-radius: 8px;
}
.product-custom .favourite {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #fff;
  z-index: 1;
  text-align: center;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.product-custom .favourite:hover {
  background: var(--secondary-theme-color);
  border: 1px solid var(--secondary-theme-color);
}
.product-custom i {
  color: #fff;
}
.product-custom .swiper-slide a {
  display: block;
}
.pro-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  border-radius: 0 0 4px 4px;
  color: inherit !important;
  background: #fff;
}
.pro-content .title-wrap {
  display: flex;
  gap: 10px;
}

.pro-content .title-wrap svg {
  color: #d3d3d3;
  font-size: 15px;
  position: relative;
  top: 1px;
}
@media (max-width: 575.98px) {
  .pro-content {
    padding: 20px;
  }
}
.pro-content .rating {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  display: none;
}
.pro-content .checked {
  color: #fcaf3d;
}
.pro-content .details {
  margin: 0 0 20px;
  padding: 20px 20px 15px;
  /* background-color: #f7f6ff; */
  background-color: #f3f6fb;
  font-size: 14px;
  align-items: center;
}
.pro-content .details li {
  font-weight: 300;
  margin-right: 10px;
  font-size: 13px;
  color: #000;
}
.pro-content .details .amount {
  font-size: 15px;
  font-weight: 800;
  margin-right: 6px;
}

.pro-content .details li {
  color: #000;
}
@media (max-width: 991.98px) {
  .pro-content .details li {
    margin-right: 7px;
  }
}
.pro-content .details li img {
  margin-right: 8px;
  width: 15px;
  filter: grayscale(100) contrast(10);
  opacity: 0.8;
}
@media (max-width: 991.98px) {
  .pro-content .details li img {
    margin-right: 5px;
  }
}
.pro-content .details li:last-child {
  margin-right: 0;
}
@media (max-width: 1399.98px) {
  .pro-content .details {
    margin: 0 0 20px;
    padding: 15px 15px 10px;
  }
}
@media (max-width: 991.98px) {
  .pro-content .details {
    margin: 0 0 20px;
    padding: 10px 10px 5px;
  }
}
.pro-content h3 {
  margin-bottom: 15px;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
  width: 100%;
  font-family: "Inter", sans-serif !important;
  color: #000;
  letter-spacing: 0.2px;
  min-height: 31px;
}
.pro-content .property-price {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--secondary-theme-color);
}
@media (max-width: 1399.98px) {
}
@media (max-width: 1199.98px) {
  .pro-content h3 {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 767.78px) {
  .pro-content h3 {
    font-size: 14px;
  }
}
.pro-content h3 a {
  color: #0e0e46;
  text-transform: capitalize;
}
.pro-content h3 a:hover {
  color: var(--primary-theme-color);
}
.pro-content p {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pro-content p svg {
  font-size: 12px;
  color: #8a909a;
  margin-right: 5px;
  line-height: 0;
}
.pro-content p span {
  line-height: 0;
}
.pro-content p span svg {
  color: var(--secondary-theme-color);
}
@media (max-width: 575.98px) {
  .pro-content p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.pro-content .list {
  font-size: 15px;
  font-weight: 700;
}
@media (max-width: 1399.98px) {
  .pro-content .list {
    font-size: 14px;
  }
}
.pro-content .date {
  font-size: 14px;
  font-weight: 500;
  color: green;
}
@media (max-width: 1399.98px) {
  .pro-content .date {
    font-size: 13px;
  }
}

.feature-property-sec.for-rent .property-category {
  padding-top: 20px;
  border-top: 1px solid #f4f4f4;
}

.property-category .user-info span,
.property-category .user-info a {
  flex-shrink: 0;
}
.property-category .user-info span img,
.property-category .user-info a img {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  margin-right: 8px;
}
@media (max-width: 991.98px) {
  .property-category .user-info span img,
  .property-category .user-info a img {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 575.98px) {
  .property-category .user-info span img,
  .property-category .user-info a img {
    width: 40px;
    height: 40px;
  }
}
.property-category .user-info .user-name {
  flex-direction: column;
  overflow: hidden;
}
.property-category .user-info .user-name h6 {
  font-weight: 500;
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .property-category .user-info .user-name h6 {
    font-size: 16px;
  }
}
@media (max-width: 767.78px) {
  .property-category .user-info .user-name h6 {
    font-size: 15px;
  }
}
.property-category .user-info .user-name h6 a {
  margin-bottom: 0;
}
.property-category .user-info .user-name a {
  color: #0e0e46;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0;
}
.property-category .user-info .user-name a:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 1199.98px) {
  .property-category .user-info .user-name a {
    font-size: 16px;
  }
}
@media (max-width: 575.98px) {
  .property-category .user-info .user-name a {
    font-size: 15px;
  }
}
.property-category .user-info .user-name span,
.property-category .user-info .user-name p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .property-category .user-info .user-name span,
  .property-category .user-info .user-name p {
    font-size: 14px;
  }
}
@media (max-width: 1199.98px) {
  .property-category .user-info {
    width: calc(100% - 100px);
  }
}
.property-category .btn-primary {
  padding: 11px 15px;
}
@media (max-width: 1199.98px) {
  .property-category .btn-primary {
    padding: 10px;
    font-size: 14px;
  }
}
@media (max-width: 575.98px) {
  .property-category {
    flex-wrap: wrap;
  }
}

.faq-section {
  padding: 80px 0;
  background: #f7f6ff;
}
@media (max-width: 991.98px) {
  .faq-section {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .faq-section {
    padding: 40px 0;
  }
}
.faq-section .section-heading {
  align-items: start;
  margin-bottom: 28px;
}
@media (max-width: 767.78px) {
  .faq-section .section-heading {
    align-items: center;
    text-align: center;
  }
}
.faq-section .section-heading h2::before {
  left: 0;
}
.faq-section .section-heading h2::after {
  left: 20px;
}
@media (max-width: 767.78px) {
  .faq-section .section-heading h2::after {
    left: 50%;
    transform: translatex(-50%);
  }
}
.faq-section .faq-img {
  border-radius: 10px;
}
@media (max-width: 991.98px) {
  .faq-section .faq-img {
    text-align: center;
    margin-bottom: 24px;
  }
}
@media (max-width: 767.78px) {
  .faq-section .faq-img {
    display: none;
  }
}
.faq-section .faq-img img {
  border-radius: 10px;
}

.faq-card {
  background: #fff;
  margin: 0 0 20px;
  border: 1px solid #e3e3e3 !important;
  border-radius: 0 !important;
}
.faq-card:last-child {
  margin-bottom: 0;
}
.faq-card .collapse.show .accordion-body {
  margin: 0 20px;
  padding: 15px 0 20px 0;
  border-top: 1px solid #e3e3e3;
}
.faq-card .accordion-header button {
  color: #0e0e46;
  display: block;
  position: relative;
  padding: 20px 60px 15px 20px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  box-shadow: none !important;
  border-radius: 0 !important;
  background: var(--white);
}
@media (max-width: 575.98px) {
  .faq-card .faq-title a {
    font-size: 15px;
    padding-right: 40px;
  }
}
.faq-card .faq-title a.collapsed {
  padding-bottom: 20px;
}
.faq-card .faq-title > a:after {
  font-family: "Fontawesome";
  font-weight: 700;
  content: "\f078";
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.faq-card .faq-title > a:not(.collapsed):after {
  content: "\f077";
  color: #8a909a;
}

.agent-section {
  background-color: var(--primary-theme-color);
  background-image: url(../assets/images/agent-bg.png),
    url(../assets/images/agent-bg-01.png);
  background-repeat: no-repeat;
  background-position: right bottom, left bottom;
  position: relative;
  padding: 80px 0;
}
@media (max-width: 991.98px) {
  .agent-section {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .agent-section {
    padding: 40px 0;
    background-size: 110px;
  }
}
.agent-section .section-heading {
  align-items: start;
  margin-bottom: 0;
}
@media (max-width: 767.78px) {
  .agent-section .section-heading {
    margin-bottom: 30px;
    align-items: center;
    text-align: center;
  }
}
.agent-section .section-heading h2 {
  color: #fff;
  margin: 0 0 10px;
  padding: 0;
}
.agent-section .section-heading h2::after {
  display: none;
}
.agent-section .section-heading h2::before {
  display: none;
}
.agent-section .section-heading p {
  color: #f2f2f2;
  font-size: 16px;
}
.agent-section .register-btn {
  display: flex;
  justify-content: end;
}
@media (max-width: 767.78px) {
  .agent-section .register-btn {
    justify-content: center;
  }
}

.latest-blog-sec {
  padding: 80px 0;
}
.latest-blog-sec .swiper-wrapper {
  padding-bottom: 50px;
}
.latest-blog-sec .swiper-pagination {
  position: relative;
  left: unset;
  bottom: unset;
  margin-top: 0;
  top: unset;
}
@media (max-width: 991.98px) {
  .latest-blog-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .latest-blog-sec {
    padding: 40px 0;
  }
}
.latest-blog-sec .section-heading h2:before {
  left: 55px;
}
.latest-blog-sec .section-heading h2::after {
  right: 55px;
}

.blog-card {
  background: var(--white);
  box-shadow: 0px 4px 14px rgb(137 137 137 / 24%);
  border-radius: 10px;
  height: 100%;
}
@media (max-width: 991.98px) {
  /* .blog-card {
    margin-bottom: 40px;
  } */
}
@media (max-width: 767.78px) {
  /* .blog-card {
    margin-bottom: 30px;
  } */
}
.blog-card .blog-img {
  border-radius: 10px 10px 0 0;
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.blog-card .blog-img img {
  border-radius: 10px 10px 0 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  height: 180px;
  width: 100%;
  object-fit: cover;
}
.blog-card .blog-img:hover img {
  transform: scale(1.14);
}
.blog-card .blog-content {
  padding: 16px;
  /* box-shadow: 0px 4px 14px rgb(137 137 137 / 24%);
  border-radius: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 180px);
}
@media (max-width: 991.98px) {
  .blog-card .blog-content {
    padding: 20px;
  }
}
.blog-card .blog-content .blog-property {
  margin-bottom: 12px;
}
.blog-card .blog-content .blog-property span {
  font-weight: 700;
  font-size: 12px;
  color: var(--primary-theme-color);
  background-color: #12086f12;
  padding: 4px 14px;
  border-radius: 5px;
  display: inline-block;
}
@media (max-width: 991.98px) {
  .blog-card .blog-content .blog-property span {
    padding: 5px 15px;
    font-size: 14px;
  }
}

.blog-card .blog-content .blog-title p {
  font-size: 14px;
}
.blog-card .blog-content .blog-title h3 {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 20px;
  color: #0e0e0e;
  line-height: 20px;
}
.blog-card .blog-content .blog-title h3 a {
  color: inherit;
  color: inherit;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-card .blog-content .user-name {
  opacity: 0.6;
  font-size: 14px;
}
.blog-card .blog-content .blog-title h3:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 767.78px) {
  .blog-card .blog-content .blog-title h3 {
    font-size: 20px;
  }
}
.blog-card .blog-content .property-category a span {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
  color: #fff;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
@media (max-width: 991.98px) {
  .blog-card .blog-content .property-category a span {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 575.98px) {
  .blog-card .blog-content .property-category a span {
    width: 40px;
    height: 40px;
  }
}
.blog-card .blog-content .property-category a:hover span {
  background: #fff;
  color: var(--primary-theme-color);
}

.news-letter-sec {
  padding: 80px 0;
  background: #f7f6ff;
}
@media (max-width: 991.98px) {
  .news-letter-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .news-letter-sec {
    padding: 40px 0;
  }
}
.news-letter-sec .news-heading h2 {
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .news-letter-sec .news-heading h2 {
    font-size: 28px;
  }
}
@media (max-width: 767.78px) {
  .news-letter-sec .news-heading h2 {
    font-size: 24px;
  }
}
.news-letter-sec .news-heading p {
  font-size: 18px;
  font-weight: 500;
  max-width: 480px;
}
@media (max-width: 991.98px) {
  .news-letter-sec .news-heading p {
    font-size: 18px;
  }
}
@media (max-width: 767.78px) {
  .news-letter-sec .news-heading p {
    font-size: 16px;
  }
}
@media (max-width: 767.78px) {
  .news-letter-sec .news-heading {
    margin: 0 0 24px;
  }
}
.news-letter-sec .email-form {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
}
.news-letter-sec .email-form form {
  display: flex;
}
.news-letter-sec .email-form form .form-control {
  padding: 5px 5px 5px 0px;
  background: transparent;
  border: none;
  min-height: 41px;
  color: #8a909a;
}
.news-letter-sec .email-form form .form-control::placeholder {
  color: #8a909a;
}
.news-letter-sec .email-form form .btn {
  padding: 8px 15px;
}

body.search-visible {
  overflow-y: hidden;
}

.search-popup {
  height: 100vh;
  background: #15152f;
  opacity: 0.95;
  z-index: 99999;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}
.search-popup.visible {
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  z-index: 99999;
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.search-popup .search-form-box {
  margin-bottom: 40px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, calc(50% - 6px));
  gap: 12px;
}
.search-popup .search-form-box .select-wrap {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 6px));
  gap: 12px;
}
.search-popup .search-form-box .search-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-size: 24px;
  color: #8a909a;
  padding: 0;
  border: 0;
  background: transparent;
}
.search-popup input[type="text"],
.search-popup select {
  border-width: 0 0 1px 0;
  border-color: #e3e3e3;
  height: auto;
  width: 100%;
  font-weight: 500;
  border-radius: 0;
  margin-bottom: 0;
  background: transparent;
  color: #fff;
  margin: 0;
  padding: 10px 30px 10px 0;
  font-size: 16px;
  font-weight: 600;
}
.search-popup input[type="text"]:focus-visible {
  outline: 0;
}
.search-popup input[type="text"]::placeholder {
  color: #8a909a;
}
@media (max-width: 1399.98px) {
  .search-popup input[type="text"] {
    font-size: 24px;
  }
}
@media (max-width: 991.98px) {
  .search-popup input[type="text"] {
    font-size: 20px;
  }
}
@media (max-width: 767.78px) {
  .search-popup input[type="text"] {
    font-size: 16px;
  }
}
.search-popup .close-button {
  line-height: 0;
  z-index: 999;
  position: absolute;
  top: 35px;
  right: 35px;
  color: #f4f4f4;
  font-size: 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media (max-width: 991.98px) {
  .search-popup .close-button {
    top: 20px;
    right: 20px;
  }
}
.search-popup .popup-inner {
  max-width: 857px;
  width: 100%;
  margin: 100px auto;
}
@media (max-width: 1399.98px) {
  .search-popup .popup-inner {
    margin: 60px auto;
  }
}
@media (max-width: 767.78px) {
  .search-popup .popup-inner {
    min-width: 100%;
  }
}
.search-popup .inner-container {
  height: 100%;
}
@media (max-width: 991.98px) {
  .search-popup .inner-container {
    margin: 0 20px;
  }
}
.search-popup .inner-container h3 {
  font-size: 48px;
  margin-bottom: 60px;
  color: #fff;
  text-align: center;
}
@media (max-width: 1399.98px) {
  .search-popup .inner-container h3 {
    font-size: 40px;
    margin-bottom: 40px;
  }
}
@media (max-width: 991.98px) {
  .search-popup .inner-container h3 {
    font-size: 36px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767.78px) {
  .search-popup .inner-container h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.search-popup .inner-container h5 {
  color: var(--secondary-theme-color);
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .search-popup .inner-container h5 {
    font-size: 16px;
  }
}
.search-popup .inner-container ul li {
  color: #fff;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 20px;
}
.search-popup .inner-container ul li:last-child {
  margin-bottom: 0;
}
.search-popup .inner-container ul li a {
  color: #fff;
}
.search-popup .inner-container ul li a:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 991.98px) {
  .search-popup .inner-container ul li {
    font-size: 16px;
  }
}

/*-----------------	
    16. Agent
-----------------------*/
.agent-card {
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  margin: 0 0 24px;
  padding: 24px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  border: 0;
}
.agent-card .agent-img {
  margin: 0 0 24px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.agent-card .agent-img img {
  border-radius: 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  width: 100%;
}
.agent-card .agent-img .list-feature {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.agent-card .agent-img .list-feature span {
  background: #fd3358;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 4px 14px;
  display: inline-block;
}
@media (max-width: 991.98px) {
  .agent-card .agent-img .list-feature span {
    font-size: 14px;
  }
}
.agent-card .agent-content .rating {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}
.agent-card .agent-content .rating .rating-count {
  margin-right: 4px;
}
.agent-card .agent-content .rating .rating-review {
  display: inline-block;
}
.agent-card .agent-content .rating .rating-review span {
  margin-right: 5px;
}
.agent-card .agent-content h6 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.agent-card .agent-content h6 a {
  color: #0e0e46;
}
.agent-card .agent-content h6 a:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 991.98px) {
  .agent-card .agent-content h6 {
    font-size: 18px;
  }
}
.agent-card .agent-content p {
  font-weight: 500;
  font-size: 14px;
}
.agent-card .agent-content p i {
  margin-right: 5px;
  font-size: 18px;
}
.agent-card:hover .agent-img img {
  transform: scale(1.14);
}

.rating {
  font-size: 14px;
  font-weight: 500;
  flex-wrap: wrap;
}
.rating i {
  color: #e3e3e3;
  margin-right: 3px;
}
.rating i.checked {
  color: #fcaf3d;
}
.rating .rating-count {
  margin-right: 3px;
  flex-wrap: wrap;
}
.rating .rating-count i {
  margin-right: 3px;
}
.rating .rating-review {
  display: inline-block;
  margin-bottom: 0;
}
.rating .rating-review span {
  margin-right: 5px;
}

.showing-result-head.show-list {
  padding-bottom: 0;
}

.show-list label {
  margin-bottom: 10px;
}

.load-more.loader {
  margin-top: 16px;
}
@media (max-width: 991.98px) {
  .load-more.loader {
    margin-top: 6px;
  }
}

.grid-pagination {
  margin-top: 50px;
}
.grid-pagination.grid-pagination-map {
  padding-bottom: 24px;
}
.grid-pagination .pagination {
  margin: 0;
}
.grid-pagination .pagination li {
  margin: 0 8px 0 0;
  cursor: pointer;
}
.grid-pagination .pagination li.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.grid-pagination .pagination li .page-link {
  width: 45px;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  color: #0e0e46;
  border-radius: 50%;
  border-color: #fff;
  padding: 5px 12px;
  background: transparent;
  cursor: pointer;
}
.grid-pagination .pagination li .page-link:focus {
  background: var(--primary-theme-color);
  color: #fff;
  border-color: var(--primary-theme-color);
  box-shadow: none;
}
@media (max-width: 991.98px) {
  .grid-pagination .pagination li .page-link {
    width: 32px;
    height: 32px;
    padding: 5px 8px;
  }
}
.grid-pagination .pagination li.active .page-link,
.grid-pagination .pagination li:hover .page-link {
  background: var(--primary-theme-color);
  color: #fff;
  border-color: var(--primary-theme-color);
}
.grid-pagination .pagination li.next .page-link,
.grid-pagination .pagination li.prev .page-link {
  width: auto;
  border-radius: 5px !important;
}
.grid-pagination .pagination li.prev svg {
  margin-right: 12px;
}
.grid-pagination .pagination li.next svg {
  margin-left: 12px;
}
.grid-pagination .pagination li:last-child {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .grid-pagination .pagination li {
    margin-right: 5px;
  }
}

.agent-list {
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  margin: 0 0 24px;
  padding: 24px;
  border-radius: 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
@media (max-width: 991.98px) {
  .agent-list {
    flex-direction: column;
  }
}
.agent-list .agent-img {
  width: 180px;
  margin-right: 20px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.agent-list .agent-img img {
  border-radius: 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.agent-list .agent-img:hover img {
  transform: scale(1.14);
}
@media (max-width: 991.98px) {
  .agent-list .agent-img {
    text-align: center;
    margin: 0 auto 15px;
  }
}
.agent-list .agent-content .agent-info {
  flex-wrap: wrap;
}
.agent-list .agent-content .agent-info .rating {
  margin-bottom: 5px;
  font-size: 14px;
}
.agent-list .agent-content .agent-info .rating .rating-count {
  margin-right: 2px;
}
.agent-list .agent-content .agent-info .rating .rating-review {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.agent-list .agent-content .agent-info .rating .rating-review span {
  margin-right: 5px;
}
.agent-list .agent-content .agent-info h6 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.agent-list .agent-content .agent-info h6 a {
  color: #0e0e46;
}
.agent-list .agent-content .agent-info h6 a:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 991.98px) {
  .agent-list .agent-content .agent-info h6 {
    font-size: 18px;
  }
}
.agent-list .agent-content .agent-info h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #8a909a;
}
.agent-list .agent-content .agent-info h5 i {
  margin-right: 5px;
  font-size: 16px;
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .agent-list .agent-content .agent-info .list-feature {
    margin-bottom: 15px;
  }
}
@media (max-width: 991.98px) {
  .agent-list .agent-content .agent-info {
    flex-direction: column;
  }
}
.agent-list .agent-content p {
  margin: 0 0 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.agent-list .agent-content ul {
  margin: 15px 0 0;
  padding: 15px 0 0;
  border-top: 1px solid #e3e3e3;
}
.agent-list .agent-content ul li {
  display: inline-block;
  margin-right: 15px;
}
.agent-list .agent-content ul li a {
  color: #0e0e46;
}
.agent-list .agent-content ul li a:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 991.98px) {
  .agent-list .agent-content {
    text-align: center;
  }
}

.list-feature span {
  background: #fd3358;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 4px 14px;
  display: inline-block;
}
@media (max-width: 991.98px) {
  .list-feature span {
    font-size: 14px;
  }
}

/*-----------------	
    17. Compare
-----------------------*/
.compare-head {
  text-align: center;
}
.compare-head h4 {
  background: #f7f6ff;
  font-size: 16px;
  font-weight: 500;
  padding: 30px;
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .compare-head h4 {
    padding: 15px;
  }
}
.compare-head .compare-action {
  position: relative;
  margin-bottom: 20px;
}
.compare-head .compare-action img {
  border-radius: 10px;
}
.compare-head .compare-action a {
  position: absolute;
  left: 10px;
  top: 10px;
  background: #fd3358;
  width: 34px;
  height: 34px;
  border-radius: 50px;
  color: #fff;
}
.compare-head span {
  color: #0e0e46;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  display: block;
}
.compare-head h5 {
  color: var(--primary-theme-color);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.compare-table .table tr:nth-child(2n + 1) {
  background: #f6f4ff;
}
.compare-table .table tr:first-child {
  background: transparent;
}
.compare-table .table tr:first-child:hover {
  background: transparent;
}
.compare-table .table tr:hover {
  background: #f6f4ff;
}
.compare-table .table tr th {
  color: #0e0e46;
  font-weight: 500;
  padding: 15px;
  text-align: center;
  border: 0;
}
.compare-table .table tr td {
  padding: 15px;
  color: #8a909a;
  text-align: center;
  min-width: 300px;
  font-weight: 400;
  border: 0;
}
@media (max-width: 991.98px) {
  .compare-table .table tr td {
    min-width: 200px;
    padding: 10px;
  }
}
.compare-table .table tr td .text-success {
  color: #0dca95 !important;
}
.compare-table .table tr td:first-child {
  font-weight: 500;
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

/*-----------------	
    18. Detail View
-----------------------*/
.buy-detailview {
  background: #f7f6ff;
  padding: 80px 0;
}
@media (max-width: 991.98px) {
  .buy-detailview {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .buy-detailview {
    padding: 40px 0;
  }
}

.row.page-head {
  margin: 0 0 50px;
  padding: 0 0 40px;
  border-bottom: 1px solid #e3e3e3;
}
@media (max-width: 991.98px) {
  .row.page-head {
    margin: 0 0 30px;
    padding: 0 0 30px;
  }
}

.buy-btn {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.buy-btn a,
.buy-btn span {
  padding: 5px 10px;
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}
.buy-btn a.buy,
.buy-btn span.buy {
  background: var(--primary-theme-color);
  margin-right: 8px;
}
.buy-btn a.appartment,
.buy-btn span.appartment {
  background: #fcaf3d;
}

.page-title h3 {
  font-size: 28px;
  margin-bottom: 10px;
}
.page-title h3 span {
  display: inline-block;
  margin-left: 6px;
}
@media (max-width: 991.98px) {
  .page-title h3 {
    font-size: 24px;
  }
}
@media (max-width: 767.78px) {
  .page-title h3 {
    font-size: 22px;
  }
}
.page-title p {
  font-size: 14px;
  font-weight: 500;
}
.page-title p i {
  margin-right: 6px;
}
@media (max-width: 991.98px) {
  .page-title p {
    margin-bottom: 15px;
  }
}

.latest-update {
  float: right;
  text-align: right;
}
.latest-update h5 {
  font-size: 15px;
  color: var(--primary-theme-color);
  font-weight: 700;
  margin-bottom: 15px;
}
.latest-update p {
  margin-bottom: 7px;
  font-size: 28px;
  font-weight: 600;
  color: #0e0e46;
}
.latest-update p span {
  display: inline-block;
  font-size: 15px;
  color: #8a909a;
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .latest-update p {
    font-size: 24px;
  }
}
@media (max-width: 767.78px) {
  .latest-update p {
    font-size: 22px;
  }
}
.latest-update .other-pages li {
  margin: 8px 8px 0 0;
}
.latest-update .other-pages li:last-child {
  margin-right: 0;
}
.latest-update .other-pages li a {
  font-size: 14px;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  font-weight: 500;
  padding: 10px;
  background: #fff;
  color: #737373;
  border-radius: 5px;
}
.latest-update .other-pages li a i {
  margin-right: 5px;
}
.latest-update .other-pages li a:hover {
  background-color: var(--primary-theme-color);
  color: #fff;
}
@media (max-width: 991.98px) {
  .latest-update {
    float: left;
    text-align: left;
  }
}

.house-img-slider {
  margin-bottom: 24px;
}
.house-img-slider img {
  border-radius: 10px;
}

.collapse-card {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  border-radius: 5px;
  position: relative;
  margin: 0 0 24px;
  padding: 24px 24px 10px;
}
.collapse-card .card-title {
  padding-bottom: 14px;
}
.collapse-card .card-title a {
  color: #0e0e46;
  display: block;
  position: relative;
  font-size: 20px;
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .collapse-card .card-title a {
    font-size: 18px;
  }
}
@media (max-width: 767.78px) {
  .collapse-card .card-title a {
    font-size: 16px;
  }
}
.collapse-card .card-title > a:after {
  font-family: "Fontawesome";
  font-weight: 700;
  content: "\f077";
  font-size: 15px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.collapse-card .card-title > a:not(.collapsed):after {
  content: "\f078";
  color: #1e1d85;
}
.collapse-card.not-collapse .card-title a::after {
  display: none;
}
.collapse-card ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.collapse-card ul li span {
  font-size: 16px;
  font-weight: 500;
  color: #8a909a;
}
.collapse-card ul.property-overview li {
  margin: 0 100px 14px 0;
  text-align: center;
}
.collapse-card ul.property-overview li img {
  margin-bottom: 10px;
}
.collapse-card ul.property-overview li p {
  color: #0e0e46;
}
.collapse-card ul.property-overview li:last-child {
  margin-right: 0;
}
@media (max-width: 1399.98px) {
  .collapse-card ul.property-overview li {
    margin: 0 70px 14px 0;
  }
}
@media (max-width: 1199.98px) {
  .collapse-card ul.property-overview li {
    margin: 0 50px 14px 0;
  }
}
@media (max-width: 575.98px) {
  .collapse-card ul.property-overview li {
    width: 100%;
    margin-right: 0;
  }
}
.collapse-card ul.property-details {
  display: block;
}
.collapse-card ul.property-details li {
  font-weight: 700;
  margin-bottom: 14px;
}
.collapse-card ul.property-details li span {
  font-weight: 500;
  font-size: 15px;
}
.collapse-card ul.property-address li {
  font-weight: 700;
  margin: 0 20px 14px 0;
}
.collapse-card ul.property-address li span {
  font-weight: normal;
  font-size: 16px;
}
@media (max-width: 575.98px) {
  .collapse-card ul.property-address li {
    width: 100%;
    margin-right: 0;
  }
}
.collapse-card ul.amenities-list {
  display: block;
}
.collapse-card ul.amenities-list li {
  margin-bottom: 14px;
}
.collapse-card ul.amenities-list li img {
  margin-right: 8px;
}
.collapse-card .sample-video {
  border-radius: 10px;
  position: relative;
  margin-bottom: 14px;
}
.collapse-card .sample-video img {
  border-radius: 10px;
}
.collapse-card .sample-video .play-icon {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #0e0e46;
  font-size: 24px;
}
@media (max-width: 991.98px) {
  .collapse-card .sample-video .play-icon {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 767.78px) {
  .collapse-card .sample-video .play-icon {
    width: 45px;
    height: 45px;
  }
}
.collapse-card .map {
  margin-bottom: 14px;
}
.collapse-card .map iframe {
  width: 100%;
  border-radius: 10px;
  height: 380px;
}
.collapse-card .review-card {
  margin-top: 0;
}
.collapse-card .review-card .customer-review {
  margin: 0 0 24px;
  padding: 15px;
  border: 1px solid #f4f4f4;
}
.collapse-card .review-card .customer-review .customer-info {
  margin-bottom: 15px;
}
.collapse-card .review-card .customer-review .customer-info .customer-name h5 {
  color: #0e0e46;
  font-weight: 600;
  margin-bottom: 2px;
}
.collapse-card
  .review-card
  .customer-review
  .customer-info
  .customer-name
  h5
  a {
  color: #0e0e46;
}
.collapse-card
  .review-card
  .customer-review
  .customer-info
  .customer-name
  h5
  a:hover {
  color: var(--primary-theme-color);
}
.collapse-card .review-card .customer-review .customer-info .customer-name p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.collapse-card .review-card .customer-review .customer-info .customer-name img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 15px;
}
@media (max-width: 767.78px) {
  .collapse-card
    .review-card
    .customer-review
    .customer-info
    .customer-name
    img {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 767.78px) {
  .collapse-card .review-card .customer-review .customer-info .customer-name {
    margin-bottom: 15px;
  }
}
.collapse-card .review-card .customer-review .customer-info .rating {
  font-size: 14px;
  font-weight: 500;
}
.collapse-card
  .review-card
  .customer-review
  .customer-info
  .rating
  .rating-count {
  margin-right: 4px;
}
.collapse-card
  .review-card
  .customer-review
  .customer-info
  .rating
  .rating-review {
  font-size: 14px;
  font-weight: 500;
  color: #8a909a;
  display: inline-block;
}
.collapse-card
  .review-card
  .customer-review
  .customer-info
  .rating
  .rating-review
  span {
  margin-right: 5px;
}
@media (max-width: 767.78px) {
  .collapse-card .review-card .customer-review .customer-info {
    display: block;
  }
}
.collapse-card .review-card .customer-review .review-para {
  background: #f7f6ff;
  border-radius: 5px;
  margin: 0;
  padding: 10px;
}
.collapse-card:last-child {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .collapse-card:last-child {
    margin-bottom: 24px;
  }
}

.inside-collapse-card {
  margin: 24px 0 0;
}
.inside-collapse-card .card-title {
  background: #f6f4ff;
}
.inside-collapse-card .card-title a {
  margin: 0;
  padding: 10px;
  font-size: 15px;
  font-weight: 700;
}
.inside-collapse-card .card-title > a:after {
  font-family: "Fontawesome";
  font-weight: 700;
  content: "\f078";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translatey(-50%);
  color: #1e1d85;
}
.inside-collapse-card .card-title > a:not(.collapsed):after {
  content: "\f077";
  color: #fff;
}
.inside-collapse-card .card-title > a:not(.collapsed) {
  background: var(--primary-theme-color);
  color: #fff;
}
.inside-collapse-card ul {
  margin: 20px 0 10px;
  padding: 15px;
  border: 1px solid #f4f4f4;
}
.inside-collapse-card ul li {
  margin: 0;
}
.inside-collapse-card ul li img {
  margin: 0 0 5px;
}

.property-review {
  background: #fff;
}
.property-review h5 {
  padding-bottom: 0 !important;
  margin: 0 0 20px;
}
.property-review .review-form button {
  padding: 18px 15px;
}
@media (max-width: 991.98px) {
  .property-review .review-form button {
    padding: 12px 15px;
  }
}
@media (max-width: 991.98px) {
  .property-review {
    margin: 0 0 14px;
    padding: 15px;
  }
}

textarea::placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #8a909a;
  opacity: 1;
}

textarea {
  color: #0e0e46;
}

.review-form {
  margin: 0 0 24px;
}
.review-form input,
.review-form textarea {
  margin: 0;
  padding: 10px 15px;
  background: #f6f6f9;
  color: #0e0e46;
  border: 1px solid #f4f4f4;
  font-weight: normal;
  width: 100%;
  resize: none;
  border-radius: 5px;
  min-height: 45px;
  font-size: 14px;
}
.review-form input:focus,
.review-form textarea:focus {
  background: #f6f6f9;
  outline: 0;
}
.review-form input::-webkit-input-placeholder,
.review-form textarea::-webkit-input-placeholder {
  font-size: 14px;
  color: #8a909a;
  font-weight: 400;
  opacity: 1;
}
.review-form input::-moz-placeholder,
.review-form textarea::-moz-placeholder {
  font-size: 14px;
  color: #8a909a;
  font-weight: 400;
  opacity: 1;
}
.review-form input:-ms-input-placeholder,
.review-form textarea:-ms-input-placeholder {
  font-size: 14px;
  color: #8a909a;
  font-weight: 400;
  opacity: 1;
}
.review-form input::-ms-input-placeholder,
.review-form textarea::-ms-input-placeholder {
  font-size: 14px;
  color: #8a909a;
  font-weight: 400;
  opacity: 1;
}
.review-form input::placeholder,
.review-form textarea::placeholder {
  font-size: 14px;
  color: #8a909a;
  font-weight: 400;
  opacity: 1;
}
.review-form button {
  width: 100%;
  padding: 11px 15px;
}
.review-form.submit-btn {
  margin: 0;
}

.sidebar-card {
  background: #fff;
  box-shadow: 0px 4px 24px rgba(225, 225, 225, 0.25);
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 24px;
}
.sidebar-card .contact-btn {
  margin: 0 0 24px;
}
.sidebar-card .contact-btn.contact-btn-new a {
  width: calc(50% - 5px);
}
@media (max-width: 575.98px) {
  .sidebar-card .contact-btn.contact-btn-new a {
    width: 100%;
    margin-bottom: 15px;
  }
}
@media (max-width: 575.98px) {
  .sidebar-card .contact-btn.contact-btn-new a:last-child {
    margin-bottom: 0;
  }
}
.sidebar-card .contact-btn.contact-btn-new a:hover {
  color: #fff;
  background: #fd3358;
}
@media (max-width: 575.98px) {
  .sidebar-card .contact-btn.contact-btn-new {
    display: block;
  }
}
.sidebar-card .contact-btn a {
  background: #f6f4ff;
  color: #0e0e46;
  margin: 0;
  padding: 10px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
}
.sidebar-card .contact-btn a.active {
  color: #fff;
  background: #fd3358;
}
.sidebar-card .contact-btn a i {
  margin: 0 5px 0 0;
}
@media (max-width: 1399.98px) {
  .sidebar-card .contact-btn a {
    font-size: 14px;
  }
}
.sidebar-card:last-child {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .sidebar-card:last-child {
    margin-bottom: 24px;
  }
}
.sidebar-card .user-info {
  font-size: 15px;
  margin-bottom: 20px;
}
.sidebar-card
  .select2-container--default.select2-container--focus
  .select2-selection--single
  .select2-selection__rendered {
  color: #0e0e46;
}
.sidebar-card
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #8a909a;
}

.floor-map img {
  margin-bottom: 10px;
}

.user-active {
  background: #f6f4ff;
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 15px;
}
.user-active .user-img {
  position: relative;
  flex-shrink: 0;
}
.user-active .user-img img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}
@media (max-width: 767.78px) {
  .user-active .user-img img {
    width: 50px;
    height: 50px;
  }
}
.user-active .user-img .avatar-online {
  position: absolute;
  right: 18px;
  bottom: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: #0dca95;
}
.user-active .user-name h4 {
  font-size: 18px;
  margin-bottom: 5px;
}
@media (max-width: 1199.98px) {
  .user-active .user-name h4 {
    font-size: 18px;
  }
}
@media (max-width: 767.78px) {
  .user-active .user-name h4 {
    font-size: 16px;
  }
}
.user-active .user-name h4 a {
  color: #0e0e46;
}
.user-active .user-name h4 a:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 767.78px) {
  .user-active .user-name p {
    font-size: 14px;
  }
}
.user-active .user-name .rating {
  font-size: 14px;
  font-weight: 500;
  display: block;
}
.user-active .user-name .rating.star-rate {
  font-size: 14px;
  font-weight: 500;
}
.user-active .user-name .rating.star-rate i {
  font-size: 12px;
  color: #fcaf3d;
}
.user-active .user-name .rating.star-rate i:last-child {
  margin-right: 5px;
}

.connect-us {
  margin: 24px 0 0;
}
.connect-us li {
  width: 100%;
  margin: 0 15px 0 0;
}
.connect-us li:last-child {
  margin: 0;
}
.connect-us li a {
  background: #f6f6f9;
  margin: 0;
  padding: 11px;
  color: #8a909a;
  font-weight: 700;
  border-radius: 10px;
}
.connect-us li a i {
  margin-right: 8px;
}
.connect-us li a:hover {
  background-color: var(--primary-theme-color);
  color: #fff;
}

.sidebar-card-title {
  font-size: 20px;
  border-bottom: 1px solid #e3e3e3;
  margin: 0 0 20px;
}
.sidebar-card-title h5 {
  margin: 0 0 20px;
}

.list-details li {
  padding: 12px 0;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid #e3e3e3;
}
.list-details li span {
  font-size: 16px;
  font-weight: 500;
}
.list-details li:first-child {
  padding-top: 0;
}
.list-details li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.list-details li:last-child span {
  color: #1fbc2f;
}

.social-links ul.sidebar-social-links li a {
  background: #0066ff;
  border: 1px solid #0066ff;
  color: #fff;
}
.social-links ul.sidebar-social-links li a.fb-icon {
  background: #3080f8;
  border: 1px solid #3080f8;
  color: #fff;
}
.social-links ul.sidebar-social-links li a.fb-icon:hover {
  background: #fff;
  color: #3080f8;
}
.social-links ul.sidebar-social-links li a.ins-icon {
  background: #ff2d20;
  border: 1px solid #ff2d20;
  color: #fff;
}
.social-links ul.sidebar-social-links li a.ins-icon:hover {
  background: #fff;
  color: #ff2d20;
}
.social-links ul.sidebar-social-links li a.twitter-icon {
  background: #35b6ff;
  border: 1px solid #35b6ff;
  color: #fff;
}
.social-links ul.sidebar-social-links li a.twitter-icon:hover {
  background: #fff;
  color: #35b6ff;
}
.social-links ul.sidebar-social-links li a:hover {
  background: #fff;
  color: #0066ff;
}
@media (max-width: 1199.98px) {
  .social-links ul.sidebar-social-links li a {
    width: 32px;
    height: 32px;
  }
}

.review-form label {
  /* color: #0e0e46;
  font-weight: 500;
  font-size: 14px; */
}

.reset-btn {
  text-align: center;
  margin: 24px 0 0;
  padding: 0;
}
.reset-btn a {
  color: #fd3358;
  font-weight: 600;
}

.sidebar-img-slider {
  position: relative;
}
.sidebar-img-slider .slide-img {
  position: relative;
}
.sidebar-img-slider .slide-img:before {
  content: "";
  background: rgba(0, 0, 0, 0.28);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.sidebar-img-slider .slide-img img {
  border-radius: 5px;
}
.sidebar-img-slider .property-name {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 20px 20px;
}
.sidebar-img-slider .property-name h3 {
  color: #fff;
  font-size: 24px;
  margin: 0 0 5px;
}
@media (max-width: 991.98px) {
  .sidebar-img-slider .property-name h3 {
    font-size: 22px;
  }
}
.sidebar-img-slider .property-name span {
  color: #fff;
  display: block;
  margin: 0 0 10px;
}
.sidebar-img-slider .property-name span i {
  margin: 0 5px 0 0;
}
.sidebar-img-slider .property-name .star-rate i {
  color: #fcaf3d;
}
.sidebar-img-slider.owl-carousel .owl-nav {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px 20px 0 0;
}
.sidebar-img-slider.owl-carousel .owl-nav button {
  background: #fff;
  color: #0e0e46;
}

.similar-list {
  padding: 80px 0 0;
}
.similar-list .section-heading {
  align-items: start;
}
.similar-list .section-heading h2::before {
  left: 0;
}
.similar-list .section-heading h2::after {
  left: 20px;
}
@media (max-width: 991.98px) {
  .similar-list {
    padding: 50px 0 0;
  }
}
@media (max-width: 767.78px) {
  .similar-list {
    padding: 40px 0 0;
  }
}

.calender {
  margin: 0 0 24px;
}
.calender ul li {
  flex-direction: column;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #0e0e46;
}
.calender ul li span {
  font-size: 14px;
  color: #727272;
}

.load-more a.btn-primary {
  background: #f6f6f9;
  color: #0e0e46;
  border: 1px solid #f6f6f9;
  padding: 11px 15px;
}
.load-more a.btn-primary span {
  margin-left: 10px;
}
.load-more a.btn-primary:hover {
  color: var(--primary-theme-color);
  border-color: var(--primary-theme-color);
}

.grid-head {
  margin-bottom: 15px;
}
.grid-head span,
.grid-head p {
  font-size: 16px;
  font-weight: 500;
  color: #0e0e46;
  margin-bottom: 0;
}
.grid-head .review-form {
  margin: 0 15px 0 10px;
  min-width: 170px;
}
@media (max-width: 1199.98px) {
  .grid-head .review-form {
    min-width: 150px;
  }
}
@media (max-width: 575.98px) {
  .grid-head .review-form {
    margin-right: 0;
    flex: 1;
  }
}

.showing-result-head {
  margin: 0 0 40px;
  padding: 24px 24px 9px;
  background: #fff;
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
}
@media (max-width: 767.78px) {
  .showing-result-head {
    margin: 0 0 30px;
    padding: 24px 24px 9px;
  }
}
.showing-result-head
  .select2-container.select2-container--focus
  .select2-selection--single
  .select2-selection__rendered {
  color: #0e0e46;
}
.showing-result-head .select2-container .select2-selection--single {
  height: 45px;
}
.showing-result-head
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 45px;
  color: #8a909a;
}
@media (max-width: 767.78px) {
  .showing-result-head
    .select2-container
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 40px;
  }
}
.showing-result-head
  .select2-container
  .select2-selection--single
  .select2-selection__arrow {
  height: 45px;
}
@media (max-width: 767.78px) {
  .showing-result-head
    .select2-container
    .select2-selection--single
    .select2-selection__arrow {
    height: 40px;
  }
}
.showing-result-head
  .select2-container
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #8a909a transparent transparent;
}
@media (max-width: 767.78px) {
  .showing-result-head .select2-container .select2-selection--single {
    height: 40px;
  }
}
.showing-result-head
  .select2-container.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #8a909a;
}

.result-show h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.result-show h5 span {
  color: var(--primary-theme-color);
}

.grid-list-view ul {
  margin-bottom: 15px;
}
.grid-list-view ul li {
  margin: 0 15px 0 0;
}
.grid-list-view ul li a {
  width: 50px;
  height: 45px;
  background: #f6f6f9;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  color: #0e0e46;
  font-size: 20px;
}
.grid-list-view ul li a.active,
.grid-list-view ul li a:hover {
  background: var(--primary-theme-color);
  border: 1px solid var(--primary-theme-color);
  color: #fff;
}
@media (max-width: 767.78px) {
  .grid-list-view ul li a {
    width: 40px;
    height: 40px;
  }
}
.grid-list-view ul li:last-child {
  margin: 0;
}

@media (max-width: 991.98px) {
  .sort-result {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
  }
}
@media (max-width: 575.98px) {
  .sort-result {
    display: block;
  }
}

.prop-book {
  margin: 0 0 24px;
  padding: 15px 15px;
}
.prop-book i {
  margin-right: 8px;
}
@media (max-width: 767.78px) {
  .prop-book {
    margin: 0 0 24px;
    padding: 11px 15px;
  }
}

.slick-track {
  display: flex;
}

.slick-slide {
  width: 25% !important;
  margin: 0 10px;
  overflow: hidden;
}
.slick-slide.slick-cloned {
  width: 25% !important;
  margin: 0 10px;
  overflow: hidden;
}
.slick-slide.slick-active {
  width: 25% !important;
  margin: 0 10px;
  overflow: hidden;
}
.slick-slide img {
  width: 100%;
  border-radius: 10px;
}

.rental-card {
  overflow: hidden;
  box-shadow: 0px 4px 24px rgba(225, 225, 225, 0.25);
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 20px 10px;
}

.rental-slider {
  position: relative;
  margin-bottom: 15px;
  z-index: 999;
}
.rental-slider button {
  width: 55px;
  height: 55px;
  font-size: 0;
  background: #fff;
  border-radius: 50%;
  border: none;
  z-index: 9999;
  color: #8a909a;
}
@media (max-width: 991.98px) {
  .rental-slider button {
    width: 35px;
    height: 35px;
  }
}
.rental-slider button.slick-prev {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}
.rental-slider button.slick-prev:hover {
  background: var(--primary-theme-color) !important;
  color: #fff;
  -webkit-transition: 500ms all ease;
  -ms-transition: 500ms all ease;
  transition: 500ms all ease;
}
.rental-slider button.slick-prev:before {
  content: "\f060";
  display: inline-block;
  font-family: "Fontawesome";
  font-size: 15px;
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .rental-slider button.slick-prev:before {
    font-size: 13px;
  }
}
.rental-slider button.slick-next {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
.rental-slider button.slick-next:hover {
  background: var(--primary-theme-color) !important;
  color: #fff;
  -webkit-transition: 500ms all ease;
  -ms-transition: 500ms all ease;
  transition: 500ms all ease;
}
.rental-slider button.slick-next:before {
  content: "\f061";
  display: inline-block;
  font-family: "Fontawesome";
  font-size: 15px;
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .rental-slider button.slick-next:before {
    font-size: 13px;
  }
}

.rentfeature-slider .owl-dots {
  margin-top: 20px;
}
@media (max-width: 767.78px) {
  .rentfeature-slider .owl-dots {
    margin-top: 0;
  }
}

.owl-dots {
  text-align: center;
  line-height: 0;
}
.owl-dots button {
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.owl-dots button span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e3e3e3;
  display: inline-block;
}
.owl-dots button.active {
  background: #fff;
  width: 22px;
  height: 22px;
  border: 2px solid var(--primary-theme-color);
  position: relative;
}
.owl-dots button.active span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--primary-theme-color);
}

.zoomContainer,
.zoomWindow {
  z-index: 99;
}

.single-product .zoomContainer,
.single-product .zoomWindow {
  z-index: 99;
}

.zoomWindow {
  border-radius: 15px;
  overflow: hidden;
}

#FloorPlan .inside-collapse-card ul {
  justify-content: space-evenly;
}
#FloorPlan .inside-collapse-card ul li img {
  margin: 0 auto 5px;
}

/*-----------------	
    19. Rent List
-----------------------*/
.profile-widget.rent-list-view {
  margin: 0 0 24px;
}
.profile-widget.rent-list-view .pro-img,
.profile-widget.rent-list-view .doc-img {
  flex-shrink: 0;
  border-radius: 10px 0 0 10px;
  max-width: 345px;
}
@media (max-width: 991.98px) {
  .profile-widget.rent-list-view .pro-img .property-img,
  .profile-widget.rent-list-view .doc-img .property-img {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .profile-widget.rent-list-view .pro-img .property-img img,
  .profile-widget.rent-list-view .doc-img .property-img img {
    max-width: 100%;
    width: 100%;
  }
}
.profile-widget.rent-list-view .pro-img img,
.profile-widget.rent-list-view .doc-img img {
  border-radius: 0;
  max-width: 345px;
  height: 100%;
}
@media (max-width: 991.98px) {
  .profile-widget.rent-list-view .pro-img img,
  .profile-widget.rent-list-view .doc-img img {
    border-radius: 10px 10px 0 0;
  }
}
@media (max-width: 575.98px) {
  .profile-widget.rent-list-view .pro-img img,
  .profile-widget.rent-list-view .doc-img img {
    max-width: 100%;
    border-radius: 10px 10px 0 0;
  }
}
.profile-widget.rent-list-view .pro-img::before,
.profile-widget.rent-list-view .doc-img::before {
  border-radius: 0;
}
.profile-widget.rent-list-view .pro-img .favourite,
.profile-widget.rent-list-view .doc-img .favourite {
  position: absolute;
  top: 20px;
  right: 20px;
}
.profile-widget.rent-list-view .pro-img .property-img,
.profile-widget.rent-list-view .doc-img .property-img {
  border-radius: 0;
  height: 100%;
}
@media (max-width: 1399.98px) {
  .profile-widget.rent-list-view .pro-img,
  .profile-widget.rent-list-view .doc-img {
    max-width: 392px;
  }
}
@media (max-width: 991.98px) {
  .profile-widget.rent-list-view .pro-img,
  .profile-widget.rent-list-view .doc-img {
    max-width: 100%;
    border-radius: 10px 10px 0 0;
  }
}
@media (max-width: 575.98px) {
  .profile-widget.rent-list-view .pro-img,
  .profile-widget.rent-list-view .doc-img {
    max-width: 100%;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
}
.profile-widget.rent-list-view .pro-content {
  width: 100%;
}
.profile-widget.rent-list-view .pro-content .list-head .rating {
  display: block;
  margin: 0 0 10px;
}
.profile-widget.rent-list-view .pro-content .list-head h3 {
  margin: 0 0 11px;
}
.profile-widget.rent-list-view .pro-content .list-head p {
  margin: 0 0 20px;
}
.profile-widget.rent-list-view .pro-content .list-head p i {
  margin-right: 5px;
}
@media (max-width: 991.98px) {
  .profile-widget.rent-list-view .pro-content .list-head p {
    justify-content: center;
  }
}
.profile-widget.rent-list-view .pro-content .list-head .product-amount {
  position: static;
}
.profile-widget.rent-list-view .pro-content .list-head .product-amount h5 {
  color: #0e0e46;
  font-size: 24px;
  font-weight: 600;
}
.profile-widget.rent-list-view .pro-content .list-head .product-amount h5 span {
  color: #0e0e46;
  font-weight: 600;
}
@media (max-width: 1199.98px) {
  .profile-widget.rent-list-view
    .pro-content
    .list-head
    .product-amount
    h5
    span {
    font-size: 22px;
  }
}
@media (max-width: 767.78px) {
  .profile-widget.rent-list-view
    .pro-content
    .list-head
    .product-amount
    h5
    span {
    font-size: 18px;
  }
}
@media (max-width: 1199.98px) {
  .profile-widget.rent-list-view .pro-content .list-head .product-amount h5 {
    font-size: 22px;
  }
}
@media (max-width: 767.78px) {
  .profile-widget.rent-list-view .pro-content .list-head .product-amount h5 {
    font-size: 18px;
  }
}
@media (max-width: 991.98px) {
  .profile-widget.rent-list-view .pro-content .list-head {
    text-align: center;
  }
}
.profile-widget.rent-list-view .pro-content .details li {
  margin-right: 50px;
}
@media (max-width: 1399.98px) {
  .profile-widget.rent-list-view .pro-content .details li {
    font-size: 13px;
    margin-right: 35px;
  }
}
.profile-widget.rent-list-view .pro-content .details li:last-child {
  margin-right: 0;
}
@media (max-width: 991.98px) {
  .profile-widget.rent-list-view .pro-content .details {
    justify-content: center;
  }
}
.profile-widget.rent-list-view .pro-content .user-info {
  width: calc(100% - 108px);
}
@media (max-width: 991.98px) {
  .profile-widget.rent-list-view {
    display: block;
    padding: 24px 0 0;
  }
}
@media (max-width: 991.98px) {
  .profile-widget.rent-list-view {
    padding: 0;
  }
}

@media (max-width: 1199.98px) {
  .list-sidebar .apply-btn {
    margin: 0 0 24px;
  }
}

@media (max-width: 1199.98px) {
  .property-sidebar .apply-btn {
    margin: 0 0 24px;
  }
}
.property-sidebar .profile-widget.rent-list-view .pro-img,
.property-sidebar .profile-widget.rent-list-view .doc-img {
  max-width: 345px;
}

.rental-map .profile-widget.rent-list-view .doc-img img {
  max-width: initial;
}

.buy-list .product-custom .product-amount h5 {
  margin-bottom: 15px;
}

.profile-widget.rent-list-view .feature-rating .favourite {
  position: relative;
  top: 0;
  right: 0;
}

/*-----------------	
    20. Property Grid Sidebar
-----------------------*/
.left-sidebar-widget .collapse-card {
  margin: 0 0 24px;
  padding: 24px;
}
.left-sidebar-widget .collapse-card .card-title {
  font-size: 20px;
  padding-bottom: 0;
}
.left-sidebar-widget .collapse-card ul {
  display: block;
  margin: 20px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid #e3e3e3;
}
.left-sidebar-widget .collapse-card ul li {
  margin: 0 0 15px 0;
}
.left-sidebar-widget .collapse-card ul li:last-child {
  margin: 0;
}
.left-sidebar-widget .collapse-card ul .custom_check {
  margin: 0;
}
.left-sidebar-widget .collapse-card ul .custom_check i {
  color: #fcaf3d;
}
.left-sidebar-widget .collapse-card .review-form {
  margin: 0 0 15px;
}
.left-sidebar-widget .collapse-card .review-form:last-child {
  margin-bottom: 0;
}
.left-sidebar-widget .collapse-card .cat-list {
  margin: 15px 0 0;
  padding: 15px 0 0;
  border-top: 1px solid #e3e3e3;
}
.left-sidebar-widget
  .collapse-card
  .select2-container--default.select2-container--focus
  .select2-selection--single
  .select2-selection__rendered {
  color: #0e0e46;
}
.left-sidebar-widget
  .collapse-card
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #8a909a;
}
.left-sidebar-widget .collapse-card:last-child {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .left-sidebar-widget .collapse-card:last-child {
    margin-bottom: 24px;
  }
}

.price-filter .caption {
  color: var(--primary-theme-color);
  font-weight: 500;
}
.price-filter .caption h5 {
  color: #8a909a;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.price-filter .caption span {
  color: var(--primary-theme-color);
  font-size: 16px;
  margin-right: 4px;
}
.price-filter .caption span:last-child {
  margin-left: 4px;
  margin-right: 0;
}

.apply-btn {
  text-align: center;
}
.apply-btn .btn {
  margin: 0 0 24px;
  padding: 18px 15px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .apply-btn .btn {
    margin: 0 0 24px;
    padding: 12px 15px;
  }
}
.apply-btn .reset-btn {
  font-weight: 700;
  font-size: 15px;
  color: #fd3358;
}
.apply-btn .reset-btn:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 991.98px) {
  .apply-btn {
    margin: 0 0 24px;
  }
}

.form-wrap {
  position: relative;
}
.form-wrap .form-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.form-wrap .form-control {
  padding: 10px 25px 10px 15px;
}

.more,
.less {
  font-weight: 700;
  font-size: 15px;
  color: var(--primary-theme-color);
  cursor: pointer;
}
.more a,
.less a {
  font-weight: 700;
  font-size: 15px;
}
.more a i,
.less a i {
  margin-right: 8px;
}

#review .price-filter li i {
  font-size: 16px;
  margin-right: 2px;
}

/*-----------------	
    21. Rent 
-----------------------*/
.success-div {
  width: 100%;
  margin-bottom: 37px;
}
.success-div span {
  border: 1px solid #0dca95;
  border-radius: 5px;
  background: rgba(13, 202, 149, 0.09);
  color: #0dca95;
  font-size: 18px;
  font-weight: 500;
  padding: 16px;
}
@media (max-width: 991.98px) {
  .success-div span {
    padding: 10px;
    font-size: 14px;
  }
}

.details-div {
  background: #f7f6ff;
  justify-content: space-between;
  border: 1px solid #f7f6ff;
  border-radius: 10px;
  margin: 0 0 40px;
  padding: 40px;
}
@media (max-width: 991.98px) {
  .details-div {
    padding: 20px;
    margin: 0 0 24px 0;
  }
}
@media (max-width: 575.98px) {
  .details-div {
    flex-direction: column;
    display: block;
  }
}
@media (max-width: 575.98px) {
  .details-div .details-div-content {
    margin-bottom: 15px;
  }
}
.details-div .details-div-content h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.details-div .details-div-content h6 {
  color: #8a909a;
  font-weight: normal;
  font-size: 16px;
  margin: 0 0 20px 0;
}
.details-div .details-div-content p {
  margin: 0 0 20px 0;
}
.details-div .details-div-price {
  text-align: right;
}
.details-div .details-div-price h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.details-div .details-div-price h6 {
  color: #ff0000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .details-div .details-div-price h6 {
    font-size: 24px;
  }
}
@media (max-width: 991.98px) {
  .details-div .details-div-price h6 {
    font-size: 22px;
  }
}
@media (max-width: 991.98px) {
  .details-div .details-div-price h6 {
    font-size: 20px;
  }
}
.details-div .details-div-price h6 span {
  color: #8a909a;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
@media (max-width: 575.98px) {
  .details-div .details-div-price {
    text-align: left;
  }
}

.details-time {
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  border-radius: 10px;
  margin: 0 0 24px 0;
  padding: 40px;
}
@media (max-width: 991.98px) {
  .details-time {
    margin: 0 0 24px 0;
    padding: 24px;
  }
}
.details-time
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #8a909a;
  font-size: 16px;
}

.arrival-div h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.arrival-div ul {
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.arrival-div ul li {
  width: 20%;
  padding-right: 10px;
}
@media (max-width: 991.98px) {
  .arrival-div ul li {
    width: 50%;
  }
}
@media (max-width: 575.98px) {
  .arrival-div ul li {
    width: 100%;
  }
}
.arrival-div ul li input[type="radio"] {
  display: none;
}
.arrival-div ul li input[type="radio"]:checked + label {
  border: 1px solid var(--primary-theme-color);
  color: var(--primary-theme-color);
}
.arrival-div ul li input[type="radio"]:checked + label span {
  color: var(--primary-theme-color);
}
.arrival-div ul li label {
  border: 1px solid #e3e3e3;
  padding: 8px;
  text-align: center;
  color: #8a909a;
  font-size: 14px;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
}
.arrival-div ul li label span {
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: #0e0e46;
}
.arrival-div ul li.active h6 {
  border: 1px solid var(--primary-theme-color);
  color: var(--primary-theme-color);
}
.arrival-div ul li.active h6 span {
  color: var(--primary-theme-color);
}
.arrival-div ul li:hover h6 {
  border: 1px solid var(--primary-theme-color);
  color: var(--primary-theme-color);
}
.arrival-div ul li:hover h6 span {
  color: var(--primary-theme-color);
}
.arrival-div ul h5 {
  color: #0e0e46;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .arrival-div.arrival-dept {
    margin-top: 24px;
  }
}

.booking-details {
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  border-radius: 10px;
  margin: 0 0 24px 0;
  padding: 40px;
}
@media (max-width: 991.98px) {
  .booking-details {
    margin: 0 0 24px 0;
    padding: 24px;
  }
}
.booking-details .form-control {
  min-height: 50px;
}
.booking-details .information-details {
  margin-bottom: 30px;
}
@media (max-width: 991.98px) {
  .booking-details .information-details {
    margin-bottom: 20px;
  }
}
@media (max-width: 991.98px) {
  .booking-details .information-details li:last-child {
    margin-bottom: 20px;
  }
}
.booking-details h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
}
@media (max-width: 991.98px) {
  .booking-details h4 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.booking-details .payment-icon {
  margin-bottom: 30px;
}
.booking-details .payment-icon ul li {
  padding-right: 19px;
  margin-right: 0;
  width: 25%;
}
@media (max-width: 991.98px) {
  .booking-details .payment-icon ul li {
    width: 50%;
    padding-right: 5px;
  }
}
@media (max-width: 575.98px) {
  .booking-details .payment-icon ul li {
    width: 100%;
    padding-right: 0;
  }
}
.booking-details .payment-icon ul li span {
  background: #f2f7f6;
  padding: 25px;
  border-radius: 10px;
  border: 3px solid #f2f7f6;
  cursor: pointer;
  min-height: 102px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .booking-details .payment-icon ul li span {
    padding: 10px;
  }
}
.booking-details .payment-icon ul li.active span,
.booking-details .payment-icon ul li:hover span {
  border: 3px solid #ffa633;
  background: #fff;
}
.booking-details .payment-icon ul li:last-child {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .booking-details .payment-icon ul li:last-child {
    margin-bottom: 20px !important;
  }
}
.booking-details ul {
  flex-wrap: wrap;
}
.booking-details ul li {
  margin-right: 50px;
}
@media (max-width: 991.98px) {
  .booking-details ul li {
    margin-bottom: 20px;
  }
  .booking-details ul li:last-child {
    margin: 0;
  }
}
.booking-details ul li h5 {
  font-size: 16px;
  font-weight: 500;
}
.booking-details ul li h6 {
  color: #8a909a;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.booking-details ul li p {
  margin-bottom: 0;
}

.booking-details-price ul {
  background: #f6f4ff;
  margin-bottom: 24px;
  padding: 20px;
}
.booking-details-price ul li {
  margin-bottom: 20px;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .booking-details-price ul li {
    justify-content: center;
  }
}
.booking-details-price ul li:last-child {
  margin: 0;
}
.booking-details-price ul li h5 {
  width: 80%;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-align: right;
}
@media (max-width: 991.98px) {
  .booking-details-price ul li h5 {
    width: 60%;
  }
}
.booking-details-price ul li h6 {
  width: 20%;
  color: #8a909a;
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  text-align: right;
}
@media (max-width: 991.98px) {
  .booking-details-price ul li h6 {
    width: 40%;
  }
}
.booking-details-price ul.booking-details-total {
  margin: 0;
}
.booking-details-price ul.booking-details-total h5,
.booking-details-price ul.booking-details-total h6 {
  font-size: 20px;
  font-weight: 600;
  color: #0e0e46;
}
@media (max-width: 767.78px) {
  .booking-details-price ul.booking-details-total h5,
  .booking-details-price ul.booking-details-total h6 {
    font-size: 16px;
  }
}

.booking-details-btn {
  margin-top: 40px;
  justify-content: end;
}
@media (max-width: 575.98px) {
  .booking-details-btn {
    flex-direction: column;
    margin-top: 24px;
  }
}
.booking-details-btn .btn {
  min-width: 232px;
  padding: 16px 15px;
}
@media (max-width: 991.98px) {
  .booking-details-btn .btn {
    min-width: auto;
    padding: 12px 15px;
  }
}
@media (max-width: 575.98px) {
  .booking-details-btn .btn {
    width: 100%;
  }
  .booking-details-btn .btn + .btn {
    margin-top: 15px;
  }
}

.modal-succeess .modal-dialog {
  max-width: 400px;
}
.modal-succeess .modal-dialog .modal-content {
  border-radius: 1px;
}
.modal-succeess .modal-dialog .modal-content .modal-body {
  padding: 40px;
}
.modal-succeess .success-popup {
  flex-direction: column;
}
.modal-succeess .success-popup h4 {
  background: #0dca95;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  margin-bottom: 25px;
}
.modal-succeess .success-popup h5 {
  font-size: 24px;
  color: #0dca95;
  font-weight: 600;
}
.modal-succeess .success-popup p {
  text-align: center;
  margin-bottom: 25px;
}
.modal-succeess .success-popup p span {
  font-size: 16px;
  font-weight: 600;
}

.rent-grid .product-custom .profile-widget {
  height: calc(100% - 24px);
}

.buy-list .product-custom .doc-img .user-avatar {
  right: auto;
  left: 20px;
}
.buy-list .product-custom .doc-img .user-avatar img {
  border-radius: 50px;
}
.buy-list .pro-content .details {
  margin: 0;
}
.buy-list .feature-property-sec.for-rent .property-category {
  border: 0;
}

.map-right {
  top: 0;
  height: 100%;
  min-height: 100%;
  position: sticky;
  right: 0;
  top: 200px;
  z-index: 1029;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1199.98px) {
  .map-right {
    padding: 0 15px;
  }
}
.map-right.map-top {
  top: 90px;
}
@media (max-width: 1199.98px) {
  .map-right.map-top {
    top: 0;
  }
}
.map-right.map-top .map-listing {
  height: calc(100vh - 80px);
}
@media (max-width: 1199.98px) {
  .map-right {
    position: relative;
    top: 0;
    margin: 20px 0 0;
    padding-right: 15px;
  }
}
.map-right .map-listing {
  height: calc(100vh - 200px);
  width: 100%;
}
.map-right .map-listing .profile-widget {
  padding: 0;
  border: 0;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .map-right .map-listing .profile-widget {
    flex-direction: column;
  }
}
.map-right .list-map-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px;
}
.map-right .list-map-img img {
  border-radius: 4px;
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}
.map-right .list-map-img:hover img {
  transform: scale(1.15);
}
.map-right .profile-widget.rent-list-view .pro-content {
  position: relative;
  padding: 20px 0 0;
}
.map-right .profile-widget.rent-list-view .pro-content .list-head p {
  margin: 0 0 14px;
  font-weight: 400;
}
.map-right .profile-widget.rent-list-view .pro-content .list-head p i {
  margin-right: 0;
}
.map-right .profile-widget.rent-list-view .pro-content .date {
  font-size: 12px;
}
.map-right .profile-widget.rent-list-view .pro-content .list {
  font-size: 12px;
}
.map-right .profile-widget.rent-list-view .pro-content p {
  font-size: 12px;
  margin-bottom: 10px;
}
.map-right .profile-widget.rent-list-view .pro-content .product-amount span {
  font-size: 20px;
}
.map-right .profile-widget.rent-list-view .pro-content .details {
  margin: 0 0 15px;
  padding: 10px;
}
.map-right .profile-widget.rent-list-view .pro-content .title {
  font-size: 16px;
  font-weight: 600;
  color: #0168b3;
  margin: 0 0 15px 0;
}
.map-right .profile-widget.rent-list-view .pro-content .title a {
  display: inline-block;
}
.map-right .profile-widget .verified {
  color: #28a745;
  margin-left: 3px;
}
.map-right .profile-widget p.speciality {
  font-size: 14px;
  color: #4f4f4f;
  margin-bottom: 5px;
  min-height: 40px;
}
.map-right .rating {
  list-style: none;
  width: 100%;
  margin: 0 0 7px;
  padding: 0px;
  color: #8a909a;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 15px;
}
.map-right .rating i {
  font-size: 12px;
  color: #dedfe0;
}
.map-right .rating i.filled {
  color: #f4c150;
}
.map-right .rating i.checked {
  color: #fcaf3d;
}
.map-right .rating span {
  margin-left: 5px;
}
.map-right .available-info {
  font-size: 13px;
  color: #757575;
  font-weight: 400;
  list-style: none;
  margin: 0 0 15px;
  padding: 0;
}
.map-right .available-info li + li {
  margin-top: 5px;
}
.map-right .available-info li i {
  width: 22px;
}

.product-sidebarview .profile-widget.rent-list-view .doc-img,
.product-sidebarview .profile-widget.rent-list-view .pro-img {
  max-width: 300px;
}
.product-sidebarview .profile-widget.rent-list-view .doc-img .property-img,
.product-sidebarview .profile-widget.rent-list-view .pro-img .property-img {
  height: 100%;
}
.product-sidebarview .profile-widget.rent-list-view .doc-img .property-img img,
.product-sidebarview .profile-widget.rent-list-view .pro-img .property-img img {
  height: 100%;
  object-fit: cover;
}

.product-name-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 14px;
}
@media (max-width: 991.98px) {
  .product-name-price {
    justify-content: center;
  }
}
.product-name-price .product-amount {
  margin-left: 5px;
}

.gm-style .gm-style-iw-d {
  padding: 0;
  overflow: auto !important;
}
.gm-style .gm-style-iw.gm-style-iw-c {
  padding: 0;
  max-width: 600px !important;
  width: 600px;
}
@media (max-width: 575.98px) {
  .gm-style .gm-style-iw.gm-style-iw-c {
    max-width: 300px !important;
    width: 300px;
  }
}
.gm-style .gm-style-iw.gm-style-iw-c .profile-widget.rent-list-view .doc-img,
.gm-style .gm-style-iw.gm-style-iw-c .profile-widget.rent-list-view .pro-img {
  max-width: 280px;
  max-height: initial;
}
.gm-style
  .gm-style-iw.gm-style-iw-c
  .profile-widget.rent-list-view
  .doc-img
  > a
  img,
.gm-style
  .gm-style-iw.gm-style-iw-c
  .profile-widget.rent-list-view
  .pro-img
  > a
  img {
  height: 100%;
}
@media (max-width: 575.98px) {
  .gm-style
    .gm-style-iw.gm-style-iw-c
    .profile-widget.rent-list-view
    .doc-img
    > a
    img,
  .gm-style
    .gm-style-iw.gm-style-iw-c
    .profile-widget.rent-list-view
    .pro-img
    > a
    img {
    width: 100%;
  }
}
.gm-style
  .gm-style-iw.gm-style-iw-c
  .profile-widget.rent-list-view
  .pro-content {
  padding: 15px;
}
.gm-style
  .gm-style-iw.gm-style-iw-c
  .profile-widget.rent-list-view
  .pro-content
  .list-head
  .product-amount {
  padding-left: 15px;
}
.gm-style .gm-style-iw.gm-style-iw-c .product-custom .doc-img:before,
.gm-style .gm-style-iw.gm-style-iw-c .product-custom .pro-img:before {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, transparent);
}

.result-map {
  border-top: 1px solid #e3e3e3;
  padding-top: 24px;
}

.advanced-search {
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  margin: 24px 0;
  padding: 24px;
}
@media (max-width: 991.98px) {
  .advanced-search {
    margin: 24px 0;
    padding: 24px;
  }
}
.advanced-search
  .select2-container--default.select2-container--focus
  .select2-selection--single
  .select2-selection__rendered {
  color: #0e0e46;
}
.advanced-search
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #8a909a;
}
.advanced-search h3 {
  font-size: 20px;
  margin: 0 0 24px;
}
.advanced-search .review-form-btn {
  justify-content: end;
  flex-wrap: wrap;
}
.advanced-search .review-form-btn .reset-btn {
  margin-left: 24px;
  color: #fd3358;
  font-size: 15px;
  font-weight: 700;
  margin-top: 0;
}
.advanced-search .review-form-btn .reset-btn:hover {
  color: var(--primary-theme-color);
}
.advanced-search .review-form-btn .btn-primary {
  padding: 9px 15px;
}

.rental-map.buy-list .profile-widget.rent-list-view .doc-img {
  flex-shrink: 1;
}
.rental-map.buy-list .profile-widget.rent-list-view .doc-img img {
  height: 100%;
}
.rental-map.buy-list .profile-widget.rent-list-view .pro-content .details {
  margin-bottom: 20px;
}

.rental-map .profile-widget.rent-list-view .doc-img {
  flex-shrink: 0;
  max-width: 330px;
}
.rental-map .profile-widget.rent-list-view .doc-img img {
  height: 100%;
}
@media (max-width: 1399.98px) {
  .rental-map .profile-widget.rent-list-view .doc-img {
    max-width: 316px;
  }
}
@media (max-width: 575.98px) {
  .rental-map .profile-widget.rent-list-view .doc-img {
    max-width: 100%;
  }
}

@media (max-width: 767.78px) {
  .rent-list-view {
    flex-direction: column;
  }
  .rent-list-view .list-head {
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 15px;
  }
}
.grid-map .gm-style-iw.gm-style-iw-c {
  max-width: 350px !important;
  width: 350px;
}

.bootstrap-datetimepicker-widget .list-unstyled {
  display: block;
}
.bootstrap-datetimepicker-widget .list-unstyled li {
  margin-right: 0;
}

.map-content {
  padding-left: 24px;
  padding-right: 24px;
}

/*-----------------	
    22. Login
-----------------------*/
.login-body {
  position: relative;
  margin: auto;
  /* background-image: url(../img/bg/bg-auth1.png); */
  background-size: auto;
  background-position: center;
  min-height: 100vh;
  overflow-y: auto;
}
.login-body .log-header {
  margin: 0 auto;
  padding: 30px 0 0;
  max-width: 595px;
  width: 100%;
}

.logo-dark {
  margin: auto;
  display: block;
}

.login-wrapper {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  /* height: calc(100vh - 80px); */
  flex-wrap: wrap;
}
.login-wrapper .loginbox {
  margin: auto;
  max-width: 460px;
  width: 100%;
  margin: 0;
  padding: 100px 0;
}
@media (max-width: 1399.98px) {
  .login-wrapper .loginbox {
    margin: 0;
    padding: 70px 0;
  }
}
@media (max-width: 991.98px) {
  .login-wrapper .loginbox {
    margin: 0;
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .login-wrapper .loginbox .login-auth {
    width: 100%;
  }
}
.login-wrapper .loginbox .login-auth .login-auth-wrap .sign-group {
  margin: 0 0 32px;
  padding: 0;
}
.login-wrapper .loginbox .login-auth .login-auth-wrap .sign-group span i {
  line-height: 0;
  margin-right: 6px;
}
.login-wrapper .loginbox .login-auth .login-auth-wrap .sign-group a {
  font-size: 14px;
  color: #fd3358;
  padding: 0;
  font-weight: 500;
  border: 0;
}
.login-wrapper .loginbox .login-auth .login-auth-wrap .sign-group a:hover {
  color: var(--primary-theme-color);
}
.login-wrapper .loginbox .login-auth .login-auth-wrap .form-group label {
  margin: 0 0 5px 0;
  font-weight: 400;
}
.login-wrapper .loginbox .login-auth .login-auth-wrap .form-group label span {
  color: #fd3358;
}
.login-wrapper
  .loginbox
  .login-auth
  .login-auth-wrap
  .form-group
  .custom_check {
  margin: 20px 0 0;
  padding: 0 0 0 24px;
  color: #8a909a;
  display: inline-block;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  user-select: none;
}
.login-wrapper
  .loginbox
  .login-auth
  .login-auth-wrap
  .form-group
  .custom_check
  input:checked
  ~ .checkmark {
  background-color: #8a909a;
  border-color: #8a909a;
}
.login-wrapper
  .loginbox
  .login-auth
  .login-auth-wrap
  .form-group
  .custom_check
  input:checked
  ~ .checkmark:after {
  display: block;
}
.login-wrapper
  .loginbox
  .login-auth
  .login-auth-wrap
  .form-group
  .custom_check
  span {
  color: #8a909a;
}
.login-wrapper
  .loginbox
  .login-auth
  .login-auth-wrap
  .form-group
  .custom_check
  input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.login-wrapper
  .loginbox
  .login-auth
  .login-auth-wrap
  .form-group
  .custom_check
  .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 10px;
  width: 16px;
  height: 16px;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}
.login-wrapper
  .loginbox
  .login-auth
  .login-auth-wrap
  .form-group
  .custom_check
  .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  display: none;
  color: #fff;
  font-size: 9px;
}
.login-wrapper .loginbox .logo {
  margin-bottom: 20px;
  text-align: center;
}
.login-wrapper .loginbox .login-auth .login-auth-wrap h1 {
  margin: 0 0 32px;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
}
@media (max-width: 991.98px) {
  .login-wrapper .loginbox .login-auth .login-auth-wrap h1 {
    font-size: 22px;
    margin: 0 0 28px;
  }
}
.login-wrapper .loginbox .login-auth .forgotpass a {
  color: #fff;
}
.login-wrapper .loginbox .login-auth .forgotpass a:hover {
  color: #fff;
  text-decoration: underline;
}
.login-wrapper .loginbox .login-auth .dont-have {
  font-size: 14px;
  font-weight: 500;
  margin: 32px 0 0;
}
.login-wrapper .loginbox .login-auth .dont-have a {
  color: #fd3358;
  font-weight: 500;
}
.login-wrapper .loginbox .login-auth .dont-have a:hover {
  color: var(--primary-theme-color);
}
.login-wrapper .loginbox .login-auth .social-login {
  text-align: center;
}
.login-wrapper .loginbox .login-auth .social-login span {
  color: #fff;
  margin-right: 8px;
}
.login-wrapper .loginbox .login-auth .social-login a {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: inset 0 0 0 0 #fff;
  text-align: center;
  margin: 0 0 10px;
  padding: 10px 0;
  line-height: 20px;
  color: #8a909a;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
}
.login-wrapper .loginbox .login-auth .social-login a:hover {
  color: #0e0e46;
  background-color: #f6f6f9;
  border: 1px solid #f6f6f9;
  box-shadow: inset 0 0 0 0 #fff;
}
.login-wrapper .loginbox .account-subtitle {
  color: #fff;
  margin: 0 0 30px;
}
.login-wrapper .loginbox .login-or {
  color: #fff;
  margin: 30px auto;
  padding: 0 0 30px;
  position: relative;
  max-width: 385px;
  overflow: hidden;
}
@media (max-width: 767.78px) {
  .login-wrapper .loginbox .login-or {
    margin: 15px auto;
    padding: 0 0 30px;
  }
}
.login-wrapper .loginbox .or-line {
  background-color: #fff;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}
@media (max-width: 575.98px) {
  .login-wrapper .loginbox .or-line {
    display: none;
  }
}
.login-wrapper .loginbox .span-or-log {
  color: #828282;
  font-size: 14px;
  font-weight: 500;
  display: block;
  left: 25%;
  position: absolute;
  text-align: center;
  top: 6px;
  width: 200px;
}
@media (max-width: 575.98px) {
  .login-wrapper .loginbox .span-or-log {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }
}
.login-wrapper .loginbox .span-or-log::before {
  width: 95px;
  content: "";
  border-top: 1px solid #edecf8;
  position: absolute;
  top: 50%;
  left: -95px;
}
.login-wrapper .loginbox .span-or-log::after {
  width: 95px;
  content: "";
  border-top: 1px solid #edecf8;
  position: absolute;
  top: 50%;
  right: -95px;
}
.login-wrapper .loginbox .lock-user {
  margin-bottom: 20px;
  text-align: center;
}
.login-wrapper .loginbox .lock-user img {
  margin-bottom: 15px;
  width: 100px;
}
.login-wrapper .loginbox .toggle-password {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.login-wrapper .loginbox .toggle-password.fa-eye {
  margin-right: 1px;
  font-weight: 500;
}

.log-footer .copyright {
  border-top: 0;
  padding: 28px 0;
  text-align: center;
}
.log-footer .copyright .copyright-text p {
  font-size: 14px;
  color: #fff;
}

/*-----------------	
    23. FAQ
-----------------------*/
@media (max-width: 991.98px) {
  .faq-section .faq-card {
    margin: 0 0 15px;
  }
}
.faq-section .faq-card:last-child {
  margin-bottom: 0;
}
.faq-section .faq-title > a:after {
  font-size: 15px;
  transition: all 0.3s ease-in-out;
}
.faq-section .faq-title > a:not(.collapsed):after {
  transition: all 0.3s ease-in-out;
}

/*-----------------	
    24. Invoice
-----------------------*/
.invoice-section {
  padding: 60px 0 36px;
}
@media (max-width: 991.98px) {
  .invoice-section {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .invoice-section {
    padding: 40px 0 16px;
  }
}
.invoice-section .card {
  box-shadow: none;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 24px;
}
@media (max-width: 767.78px) {
  .invoice-section .card .head {
    display: block;
  }
}
@media (max-width: 767.78px) {
  .invoice-section .card .head img {
    margin: 0 0 14px;
    width: 150px;
  }
}
.invoice-section .card .head-info h4 {
  margin: 0 0 14px;
  font-weight: 600;
  font-size: 20px;
}
.invoice-section .card .head-info h5 {
  margin: 0 0 10px;
  font-weight: 600;
}
.invoice-section .card .head-info p {
  font-weight: 500;
  font-size: 14px;
  margin: 0 0 10px;
}
.invoice-section .card hr {
  margin: 40px 0;
}
.invoice-section .card .from-to {
  margin: 0 0 30px;
  padding: 24px;
  background: #f7f6ff;
}
@media (max-width: 767.78px) {
  .invoice-section .card .from-to {
    padding: 24px 24px 14px;
  }
}
.invoice-section .card .from-to h5 {
  font-size: 15px;
  margin-bottom: 5px;
}
@media (max-width: 767.78px) {
  .invoice-section .card .from-to h5 {
    margin: 24px 0 0;
  }
  .invoice-section .card .from-to h5:first-child {
    margin: 0;
  }
}
@media (max-width: 767.78px) {
  .invoice-section .card .from-to h5:first-child {
    margin: 0 0 10px;
  }
}
.invoice-section .card .from-to p {
  margin-bottom: 4px;
}
.invoice-section .card .from-to p:last-child {
  margin-bottom: 0;
}
@media (max-width: 767.78px) {
  .invoice-section .card .from-to p:last-child {
    margin-bottom: 20px !important;
  }
}
.invoice-section .card table {
  border-collapse: collapse;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  margin: 0;
}
.invoice-section .card table tr {
  border: 1px solid #eaedf0;
}
.invoice-section .card table tr th {
  background: #f7f6ff;
}
.invoice-section .card table tr th {
  border-width: 0;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  color: #0e0e46;
}
.invoice-section .card table tr td {
  border-width: 0;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  color: #8a909a;
}
.invoice-section .card table.short-info {
  border: none;
}
.invoice-section .card table.short-info tr {
  border: none;
}
.invoice-section .card table.short-info tr td {
  padding: 5px 0;
  font-weight: 500;
  font-size: 14px;
  color: #0e0e46;
}
@media (max-width: 767.78px) {
  .invoice-section .card table.short-info tr td {
    display: block;
    text-align: left;
    float: left;
    margin: 0 0 0;
  }
}
.invoice-section .card table.short-info tr td span {
  color: #8a909a;
  font-weight: 400;
  font-size: 16px;
}
@media (max-width: 767.78px) {
  .invoice-section .card table.short-info tr td span {
    font-size: 14px;
  }
}
.invoice-section .card table.short-info tr:first-child td {
  padding-top: 0;
}
.invoice-section .card table.short-info tr:last-child td {
  padding-bottom: 0;
}
.invoice-section .card table.total {
  border: none;
  margin: 0;
}
.invoice-section .card table.total tr {
  border: none;
}
.invoice-section .card table.total tr .total-price {
  float: right;
}
@media (max-width: 575.98px) {
  .invoice-section .card table.total tr .total-price {
    float: left;
  }
}
.invoice-section .card table.total tr td {
  padding: 0 0 12px 0;
}
@media (max-width: 575.98px) {
  .invoice-section .card table.total tr td {
    display: block;
    text-align: left;
  }
}
.invoice-section .card table.total tr td:last-child {
  margin-bottom: 0;
}
.invoice-section .card table.total tr td h6 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
}
.invoice-section .card table.total tr td h6 span {
  font-weight: 500;
  font-size: 14px;
  color: #8a909a;
}
@media (max-width: 575.98px) {
  .invoice-section .card table.total tr td h6 span {
    margin: 0 0 10px;
    display: block;
  }
  .invoice-section .card table.total tr td h6 span:last-child {
    margin-bottom: 0;
  }
}
.invoice-section .card table.total .credit-amount td h6 span {
  margin-bottom: 14px;
  display: block;
}
.invoice-section .card table.total .total-amount {
  margin: 14px 0 0;
}
.invoice-section .card table.total .total-amount td {
  padding: 0;
}
.invoice-section .card table.total .total-amount td h6 {
  font-weight: 700;
  font-size: 15px;
}
.invoice-section .card table.total .total-amount td h6 span {
  padding: 0 0 0 39px;
  color: #0e0e46;
  font-weight: 700;
  font-size: 15px;
}
@media (max-width: 767.78px) {
  .invoice-section .card table.total .total-amount td h6 span {
    padding: 0;
  }
}
.invoice-section .card table.total .total-amount td h6:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  display: block;
}
.invoice-section .card .total-wrap {
  margin: 25px 0 0;
  padding: 20px;
  border-radius: 3px;
  background: #f7f6ff;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
}
.invoice-section .card .payment-detail {
  background: #f7f6ff;
  margin: 25px 0 0;
  padding: 20px;
}
@media (max-width: 767.78px) {
  .invoice-section .card .payment-detail {
    display: block !important;
  }
}
.invoice-section .card .payment-detail h6 {
  margin: 0 0 15px;
  font-weight: 700;
  font-size: 15px;
}
.invoice-section .card .payment-detail h5 {
  font-weight: 700;
  font-size: 15px;
  margin: 0 0 4px;
}
.invoice-section .card .payment-detail p {
  margin: 0 0 4px;
}
@media (max-width: 767.78px) {
  .invoice-section .card .payment-detail .pay-online {
    margin: 25px 0 0;
  }
}
.invoice-section .card .payment-detail .pay-online h5 {
  margin: 0 0 15px;
}

/*-----------------	
    25. Agency
-----------------------*/
.agent-card {
  border-radius: 10px;
  color: inherit;
}
.agent-card.agency .agent-img {
  background: #f6f6f9;
  padding: 80px 0;
  text-align: center;
  min-height: 280px;
}
.agent-card.agency .agent-img img {
  width: auto;
}
.agent-card.agency .agency-user .agency-user-img {
  margin-right: 10px;
}
.agent-card.agency .agency-user .agency-user-img img {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.agent-card.agency .agency-user .agency-user-info h6 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.agent-card.agency .agency-user .agency-user-info h6 a {
  color: #0e0e46;
}
.agent-card.agency .agency-user .agency-user-info h6 a:hover {
  color: var(--primary-theme-color);
}
.agent-card.agency .agency-user .agency-user-info p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
.agent-card.agency .agency-user .agency-user-info p i {
  margin-right: 5px;
}

.agency-user .agency-user-img {
  margin-right: 10px;
  flex-shrink: 0;
}
.agency-user .agency-user-img img {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.agency-user .agency-user-info h6 {
  margin-bottom: 8px;
}
.agency-user .agency-user-info h6 a {
  color: #0e0e46;
}
.agency-user .agency-user-info h6 a:hover {
  color: var(--primary-theme-color);
}
.agency-user .agency-user-info p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
.agency-user .agency-user-info p i {
  margin-right: 5px;
}

.agent-list.agency .agent-img {
  background: #f6f6f9;
  padding: 20px;
  text-align: center;
  min-height: 207px;
}
.agent-list.agency .agent-content .rating {
  margin-bottom: 15px;
}
.agent-list.agency .agent-content .agency-user-img img {
  width: 50px;
  height: 50px;
}
.agent-list.agency .agent-content .agency-user {
  margin-bottom: 15px;
}
.agent-list.agency .agent-content .agency-user .agency-user-info {
  width: auto;
  text-align: left;
}
.agent-list.agency .agent-content .agency-user .agency-user-info h6 {
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 991.98px) {
  .agent-list.agency .agent-content .agency-user .agency-user-info h6 {
    text-align: center;
  }
}
.agent-list.agency .agent-content .agency-user .agency-user-info p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 991.98px) {
  .agent-list.agency .agent-content .agency-user {
    justify-content: center;
  }
}

/*-----------------	
    26. Gallery
-----------------------*/
.gallery-section {
  padding: 60px 0 36px;
}
@media (max-width: 991.98px) {
  .gallery-section {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .gallery-section {
    padding: 40px 0 16px;
  }
}
.gallery-section .gallery-widget {
  position: relative;
  margin: 0 0 24px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
@media (max-width: 767.78px) {
  .gallery-section .gallery-widget img {
    width: 100%;
  }
}

.fancybox-image {
  border-radius: 10px;
  border: 1px solid #fff;
}

/*-----------------	
    27. Testimonial
-----------------------*/
.testimonial-group {
  margin: 0 0 26px;
}
.testimonial-group .testimonial-card {
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  position: relative;
}
.testimonial-group .quotes-head::before {
  position: absolute;
  top: 20px;
  left: 20px;
  content: "";
  display: block;
  width: 34px;
  height: 34px;
  /* background: url(../img/quote.svg) no-repeat center center; */
  margin: 0 0 20px;
}
@media (max-width: 991.98px) {
  .testimonial-group .quotes-head::before {
    width: 18px;
    height: 18px;
    margin: 0 0 20px;
  }
}

/*-----------------	
    28. Agent Details
-----------------------*/
.collapse-view {
  padding-top: 6px;
}

.about-agent p {
  margin-bottom: 20px;
}
.about-agent p:last-child {
  margin-bottom: 14px;
}

.service-area li {
  position: relative;
  margin: 0 20px 14px 0;
  padding: 0 0 0 20px;
}
.service-area li::before {
  content: "\ea47";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-family: boxicons !important;
  width: 14px;
  height: 14px;
  background: var(--primary-theme-color);
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
}

.specialities-list li {
  background: #f6f4ff;
  border: 1px solid #f6f4ff;
  border-radius: 70px;
  margin: 0 10px 10px 0;
  padding: 5px 15px;
}

.list-details.con-list li {
  color: #8a909a;
  font-weight: normal;
}
.list-details.con-list li span {
  color: #0e0e46;
  font-size: 15px;
}
.list-details.con-list li span i {
  margin-right: 8px;
  font-size: 16px;
  color: var(--primary-theme-color);
}

.details-wrap {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  margin: 0 0 24px;
  padding: 24px;
}
.details-wrap .detail-user-wrap .detail-user-img {
  margin-right: 24px;
  flex-shrink: 0;
  width: 188px;
}
.details-wrap .detail-user-wrap .detail-user-img img {
  width: 188px;
  height: 185px;
  object-fit: cover;
  border-radius: 10px;
}
@media (max-width: 991.98px) {
  .details-wrap .detail-user-wrap .detail-user-img {
    margin: 0 auto 15px;
  }
}
.details-wrap .detail-user-wrap .user-info-wrap {
  flex: 1;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 25px;
}
.details-wrap .detail-user-wrap .user-info-wrap .detail-action {
  margin-bottom: 10px;
  text-align: right;
}
.details-wrap .detail-user-wrap .user-info-wrap .detail-action .btn {
  margin: 0 0 15px 24px;
  padding: 11px 15px;
  min-width: 162px;
}
.details-wrap .detail-user-wrap .user-info-wrap .detail-action .btn i {
  font-size: 18px;
  margin-right: 8px;
}
@media (max-width: 991.98px) {
  .details-wrap .detail-user-wrap .user-info-wrap .detail-action .btn {
    margin: 0 7px 10px;
    padding: 11px 15px;
  }
}
@media (max-width: 991.98px) {
  .details-wrap .detail-user-wrap .user-info-wrap .detail-action {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  .details-wrap .detail-user-wrap .user-info-wrap {
    display: block;
  }
}
.details-wrap .detail-user-wrap .user-wrap {
  width: 100%;
}
.details-wrap .detail-user-wrap .user-wrap .mem-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}
@media (max-width: 575.98px) {
  .details-wrap .detail-user-wrap .user-wrap .mem-list {
    flex-direction: column;
    row-gap: 0;
  }
}
.details-wrap .detail-user-wrap .user-wrap .mem-list li {
  margin-right: 30px;
  font-weight: 500;
  font-size: 14px;
}
@media (max-width: 575.98px) {
  .details-wrap .detail-user-wrap .user-wrap .mem-list li {
    margin-right: 0;
    margin-top: 10px;
  }
}
.details-wrap .detail-user-wrap .user-wrap .mem-list li span {
  color: #0e0e46;
}
.details-wrap .detail-user-wrap .detail-user-info {
  margin-bottom: 10px;
}
.details-wrap .detail-user-wrap .detail-user-info .rating {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 991.98px) {
  .details-wrap .detail-user-wrap .detail-user-info .rating {
    justify-content: center;
  }
}
.details-wrap .detail-user-wrap .detail-user-info h3 {
  margin-bottom: 10px;
}
.details-wrap .detail-user-wrap .detail-user-info h3 i {
  font-size: 20px;
  color: #0dca95;
}
@media (max-width: 991.98px) {
  .details-wrap .detail-user-wrap .detail-user-info h3 {
    font-size: 24px;
  }
}
@media (max-width: 767.78px) {
  .details-wrap .detail-user-wrap .detail-user-info h3 {
    font-size: 20px;
  }
}
.details-wrap .detail-user-wrap .detail-user-info p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}
@media (max-width: 991.98px) {
  .details-wrap .detail-user-wrap .detail-user-info p {
    justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .details-wrap .detail-user-wrap .detail-user-info p {
    display: block;
  }
}
.details-wrap .detail-user-wrap .detail-user-info p i {
  margin-right: 5px;
  font-size: 16px;
}
.details-wrap .detail-user-wrap .detail-user-info p span {
  font-size: 15px;
  font-weight: 700;
  color: var(--primary-theme-color);
  margin-left: 4px;
}
@media (max-width: 991.98px) {
  .details-wrap .detail-user-wrap {
    display: block;
    text-align: center;
  }
}

.details-wrap.agency .detail-user-img {
  background: #f6f6f9;
  border-radius: 10px;
  padding: 25px;
  height: 186px;
}
.details-wrap.agency .detail-user-img img {
  width: auto;
  height: auto;
}

.agentpro-list .feature-property-sec {
  padding: 0;
}

.slide-nav-1 {
  margin-bottom: 30px;
  text-align: right;
}
.slide-nav-1 .product-custom .profile-widget {
  margin-bottom: 24px;
}

.sub-title {
  font-weight: 600;
  margin: 0 0 24px;
}

.list-card {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  margin: 0 0 24px;
  padding: 24px 24px 10px;
}

.my-list li {
  display: inline-block;
  margin: 0 20px 14px 0;
}
.my-list li a {
  color: #8a909a;
  padding: 5px 10px;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  border-radius: 10px;
  display: inline-block;
}
.my-list li a.active,
.my-list li a:hover {
  background: var(--primary-theme-color);
  color: #fff;
}

.our-agent {
  border: 1px solid #f4f4f4;
  padding: 10px;
}
.our-agent .user-img {
  margin-right: 10px;
}
.our-agent .user-img img {
  width: 89px;
  height: 90px;
  object-fit: cover;
  border-radius: 5px;
}
.our-agent .agent-name h6 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.our-agent .agent-name h6 a {
  color: #0e0e46;
}
.our-agent .agent-name h6 a:hover {
  color: var(--primary-theme-color);
}
@media (max-width: 991.98px) {
  .our-agent .agent-name h6 {
    font-size: 16px;
  }
}
.our-agent .agent-name p {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}
.our-agent .agent-name p i {
  margin-right: 5px;
}
.our-agent .agent-name .rating {
  font-weight: 500;
  font-size: 14px;
}

.agentview-slider .owl-dots {
  margin-bottom: 24px;
}

.spl-card {
  padding-bottom: 14px;
}

/*-----------------	
    29. About Us
-----------------------*/
.aboutus-page .aboutus-section {
  padding: 80px 0 0;
}
@media (max-width: 991.98px) {
  .aboutus-page .aboutus-section {
    padding: 50px 0 0;
  }
}
.aboutus-page .about-img {
  position: relative;
  margin: 0 0 24px 0;
}
.aboutus-page .about-img img {
  border-radius: 10px;
  padding: 0 0 0 10px;
}
.aboutus-page .about-img .about-exp {
  position: absolute;
  background: var(--primary-theme-color);
  border-radius: 10px;
  width: 370px;
  height: 100%;
}
@media (max-width: 767.78px) {
  .aboutus-page .about-img .about-exp {
    width: 230px;
  }
}
.aboutus-page .about-img .about-exp span {
  font-weight: 500;
  font-size: 28px;
  color: #fff;
  display: inline-block;
  margin: 250px -122px;
  transform: rotate(-90deg);
}
@media (max-width: 767.78px) {
  .aboutus-page .about-img .about-exp span {
    font-size: 18px;
    margin: 275px -90px;
  }
}
@media (max-width: 575.98px) {
  .aboutus-page .about-img .about-exp span {
    font-size: 16px;
    margin: 115px -75px;
  }
}
@media (max-width: 991.98px) {
  .aboutus-page .about-content {
    margin: 0 0 40px 0;
  }
}
.aboutus-page .about-content h6 {
  font-size: 15px;
  color: var(--primary-theme-color);
  font-weight: 700;
  margin: 0 0 20px;
}
.aboutus-page .about-content h1 {
  font-size: 42px;
  font-weight: 700;
  max-width: 657px;
  margin: 0 0 20px;
}
@media (max-width: 991.98px) {
  .aboutus-page .about-content h1 {
    margin: 0 0 24px;
    font-size: 36px;
  }
}
@media (max-width: 767.78px) {
  .aboutus-page .about-content h1 {
    margin: 0 0 20px;
    font-size: 28px;
  }
}
.aboutus-page .about-content p {
  margin: 0 0 20px;
}
.aboutus-page .about-counter-sec {
  position: relative;
  margin: 60px 0 0;
}
@media (max-width: 991.98px) {
  .aboutus-page .about-counter-sec {
    margin: 50px 0 0;
  }
}
@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec {
    margin: 36px 0 0;
  }
}
.aboutus-page .about-counter-sec:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 769px;
  background: #f7f6ff;
  text-align: center;
  left: 0;
  right: 0;
  top: 29%;
}
.aboutus-page .about-counter-sec .about-listing-img .about-listing {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}
.aboutus-page .about-counter-sec .about-listing-img .about-listing img {
  border-radius: 20px;
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  max-width: 415px;
}
@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .about-listing-img .about-listing img {
    width: 100%;
    max-width: 100%;
  }
}
.aboutus-page .about-counter-sec .about-listing-img .about-listing img:hover {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .about-listing-img .about-listing {
    margin-bottom: 24px;
  }
}
.aboutus-page .about-counter-sec .about-listing-img .about-img-middle {
  margin: -34px 0 0;
}
@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .about-listing-img .about-img-middle {
    margin: 24px 0;
  }
}
.aboutus-page .about-counter-sec .counter-sec {
  position: relative;
  background: transparent;
}
@media (max-width: 991.98px) {
  .aboutus-page .about-counter-sec .counter-sec {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .counter-sec {
    padding: 24px 0 26px;
  }
}
.aboutus-page .about-counter-sec .counter-sec .counter-box {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}
.aboutus-page .about-counter-sec .counter-sec .counter-box:hover {
  background-color: var(--primary-theme-color);
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}
.aboutus-page .about-counter-sec .counter-sec .counter-box:hover h3 {
  color: #fff;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}
.aboutus-page .about-counter-sec .counter-sec .counter-box:hover h5 {
  color: #fff;
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}
.aboutus-page .about-counter-sec .counter-sec .counter-box h3 {
  font-size: 32px;
  color: var(--primary-theme-color);
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
}
@media (max-width: 991.98px) {
  .aboutus-page .about-counter-sec .counter-sec .counter-box h3 {
    font-size: 30px;
  }
}
@media (max-width: 767.78px) {
  .aboutus-page .about-counter-sec .counter-sec .counter-box h3 {
    font-size: 24px;
  }
}
.aboutus-page .about-counter-sec .counter-sec .counter-box h5 {
  -webkit-transition: 0.2s all;
  -ms-transition: 0.2s all;
  transition: 0.2s all;
  color: #8a909a;
}
.aboutus-page .about-counter-sec .counter-sec .counter-box h5:last-child {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .aboutus-page .about-counter-sec .counter-sec .counter-box {
    margin: 0 0 24px;
  }
}
.aboutus-page .book-section {
  background: #1e1d85;
}
.aboutus-page .book-section .book-listing h2 {
  font-size: 44px;
  color: #fff;
  margin: 0 0 34px;
}
@media (max-width: 991.98px) {
  .aboutus-page .book-section .book-listing h2 {
    font-size: 38px;
    margin: 0 0 24px;
  }
}
@media (max-width: 767.78px) {
  .aboutus-page .book-section .book-listing h2 {
    font-size: 26px;
    margin: 0 0 20px;
  }
}
@media (max-width: 991.98px) {
  .aboutus-page .book-section .book-listing {
    margin: 0 0 24px;
  }
}
.aboutus-page .book-section .book-listing img {
  border: 11px solid #fff;
}
.aboutus-page .book-section .book-content p {
  color: #fff;
}
.aboutus-page .book-section .book-content p span {
  color: #fcaf3d;
}
.aboutus-page .partners-sec {
  border-top: none;
  padding: 80px 0;
}
@media (max-width: 991.98px) {
  .aboutus-page .partners-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .aboutus-page .partners-sec {
    padding: 40px 0;
  }
}

/*-----------------	
    30. Contact
-----------------------*/
.contact-section {
  background: #f7f6ff;
  padding: 80px 0 56px;
}
@media (max-width: 991.98px) {
  .contact-section {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .contact-section {
    padding: 40px 0 16px;
  }
}
.contact-section .contact-content .card {
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  border: 0;
  border-radius: 0;
  margin: 0 0 24px;
}
.contact-section .contact-content .card .contact-info h3 {
  font-weight: 600;
  margin: 0 0 16px;
}
@media (max-width: 767.78px) {
  .contact-section .contact-content .card .contact-info h3 {
    font-size: 20px;
  }
}
.contact-section .contact-content .card .contact-info p {
  margin: 0 0 15px;
}
.contact-section .contact-content .card img {
  max-width: 636px;
  border-radius: 10px;
}
.contact-section img {
  border-radius: 10px;
  margin-bottom: 24px;
}

.contact-info-sec {
  padding: 80px 0 56px;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .contact-info-sec {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .contact-info-sec {
    padding: 40px 0 16px;
  }
}
.contact-info-sec label {
  font-size: 14px;
  font-weight: 500;
}
.contact-info-sec h3 {
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 10px;
}
@media (max-width: 991.98px) {
  .contact-info-sec h3 {
    font-size: 26px;
    margin: 0 0 30px;
  }
}
@media (max-width: 767.78px) {
  .contact-info-sec h3 {
    font-size: 22px;
    margin: 0 0 20px;
  }
}
.contact-info-sec .btn-primary {
  padding: 13px 15px;
}
@media (max-width: 767.78px) {
  .contact-info-sec .btn-primary {
    padding: 10px 15px;
  }
}
.contact-info-sec .card {
  border: 1px solid #f4f4f4;
  border-radius: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.contact-info-sec .card .card-header {
  padding-bottom: 0;
}
.contact-info-sec .card .card-header h3 {
  font-weight: 700;
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
  padding: 0 0 20px;
  font-size: 30px;
}
.contact-info-sec .card .card-body {
  padding-top: 20px;
}
.contact-info-sec .contact-info-details {
  margin: 0 0 37px;
}
.contact-info-sec .contact-info-details span {
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  margin: 0 16px 0 0;
  height: 72px;
  width: 72px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.contact-info-sec .contact-info-details h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px;
}
@media (max-width: 991.98px) {
  .contact-info-sec .contact-info-details h4 {
    font-size: 18px;
  }
}
.contact-info-sec .contact-info-details a {
  color: #000000;
  font-weight: 600;
}
.contact-info-sec .contact-info-details p {
  font-weight: 600;
  max-width: 254px;
}
@media (max-width: 767.78px) {
  .contact-info-sec .contact-info-details {
    margin: 0 0 24px;
  }
}

.map-location {
  margin-bottom: 24px;
}
.map-location iframe {
  width: 100%;
}

/*-----------------	
    31. Our Team
-----------------------*/
.our-team-section {
  position: relative;
  padding: 60px 0 36px;
}
@media (max-width: 991.98px) {
  .our-team-section {
    padding: 50px 0 26px;
  }
}
@media (max-width: 767.78px) {
  .our-team-section {
    padding: 40px 0 16px;
  }
}
.our-team-section .our-team {
  text-align: center;
  border-bottom: 3px solid #fff;
  background: #fff;
  box-shadow: 0px 4px 24px rgba(189, 189, 189, 0.24);
  border-radius: 0;
  margin: 0 0 24px;
  padding: 24px;
  transition: all 0.2s ease-out 0s;
}
.our-team-section .our-team .team-prof {
  padding: 24px 24px 0;
}
.our-team-section .our-team .team-prof h6 {
  font-weight: 600;
  margin-bottom: 10px;
}
.our-team-section .our-team .team-prof h6 a {
  color: #0e0e46;
}
.our-team-section .our-team .team-prof h6 a:hover {
  color: var(--primary-theme-color);
}
.our-team-section .our-team .team-prof .team-designation {
  padding: 0 0 20px;
  display: block;
}
.our-team-section .our-team .team-prof .footer-social-links {
  border-top: 1px solid #f4f4f4;
  padding: 20px 0 0;
}
.our-team-section .our-team .team-prof .footer-social-links ul li {
  margin-right: 20px;
}
.our-team-section .our-team .team-prof .footer-social-links ul li a {
  color: #0e0e46;
  font-size: 18px;
  text-align: center;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.our-team-section .our-team .team-prof .footer-social-links ul li a:hover {
  color: var(--primary-theme-color);
}
.our-team-section .our-team .team-prof .footer-social-links ul li:last-child {
  margin: 0;
}
.our-team-section .our-team .team-prof h3 {
  font-weight: 700;
  font-size: 24px;
  color: #0e0e46;
  margin-bottom: 10px;
}
.our-team-section .our-team .profile-pic {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.our-team-section .our-team .profile-pic img {
  width: 100%;
  height: 100%;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  border-radius: 10px;
}
.our-team-section .our-team:hover {
  border-bottom: 3px solid var(--primary-theme-color);
  transition: all 0.2s ease-out 0s;
}
.our-team-section .our-team:hover h6 a {
  color: var(--primary-theme-color);
}
.our-team-section .our-team:hover .profile-pic img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}

/*-----------------	
    32. Blog
-----------------------*/
.blog-section {
  position: relative;
  background: #f7f6ff;
}
.blog-section .grid-blog {
  background: #fff;
  margin: 0 0 24px;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  border-radius: 10px;
  overflow: hidden;
}
.blog-section .grid-blog .grid-blog .blog-image {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.blog-section .grid-blog .blog-image-list {
  border-radius: 10px 10px 0 0;
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}
.blog-section .grid-blog .blog-image-list img {
  border-radius: 10px 10px 0 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  width: 100%;
}
.blog-section .grid-blog .blog-image-list:hover img {
  transform: scale(1.14);
}
.blog-section .blog-card {
  margin: 0 0 24px;
  background: #fff;
}
.blog-section .blog-card .blog-img img {
  width: 100%;
}
.blog-section .pagination .page-link {
  background: transparent;
  border: 0;
}
.blog-section .blog-image {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.blog-section .blog-image > a {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}
.blog-section .blog-image img {
  display: block;
  position: relative;
  border-radius: 10px 10px 0 0;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}
.blog-section .blog-image img:hover {
  transform: scale(1.35) rotate(15deg);
  -webkit-transform: scale(1.35) rotate(15deg);
  -ms-transform: scale(1.35) rotate(15deg);
}
.blog-section .blog-content {
  padding: 24px;
}
@media (max-width: 767.78px) {
  .blog-section .blog-content .blog-category {
    margin: 0 0 15px;
  }
}
.blog-section .blog-content .blog-category a {
  display: inline-block;
}
.blog-section .blog-content .blog-category a span {
  background: var(--primary-theme-color);
  border-radius: 10px;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 4px 20px;
  display: inline-block;
}
.blog-section .blog-content .blog-category a:hover span {
  background: #1e1d85;
  color: #fff;
}
@media (max-width: 767.78px) {
  .blog-section .blog-content .blog-category a {
    padding: 0;
    font-size: 14px;
  }
}
@media (max-width: 767.78px) {
  .blog-section .blog-content .date-icon {
    display: block;
  }
}
.blog-section .blog-content .date-icon .post-author {
  padding: 0 22px 0 0;
}
@media (max-width: 767.78px) {
  .blog-section .blog-content .date-icon .post-author {
    display: block;
    margin: 15px 0;
  }
}
.blog-section .blog-content .blog-title {
  margin: 0 0 20px;
}
.blog-section .blog-content .blog-title a {
  font-size: 24px;
  color: #0e0e46;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media (max-width: 991.98px) {
  .blog-section .blog-content .blog-title a {
    font-size: 20px;
  }
}
.blog-section .blog-content .blog-title a:hover {
  color: var(--primary-theme-color);
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.blog-section .blog-content .blog-description {
  margin: 0 0 20px;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #e3e3e3;
}
.blog-section .blog-content .meta-item li .post-author .post-author-img {
  margin: 0 10px 0 0;
  line-height: 0;
}
.blog-section .blog-content .meta-item li .post-author .post-author-img img {
  max-width: 60px;
  height: 60px;
  border-radius: 50px;
  border: 1px solid #fff;
}
@media (max-width: 991.98px) {
  .blog-section .blog-content .meta-item li .post-author .post-author-img img {
    max-width: 40px;
    height: 40px;
  }
}
.blog-section .blog-content .meta-item li .post-author a span {
  color: #0e0e46;
  font-size: 18px;
  font-weight: 500;
}
.blog-section .blog-content .meta-item .date-icon i {
  font-weight: 400;
  margin: 0 7px 0;
}
.blog-section .blog-content .meta-item .date-icon span {
  font-size: 15px;
  color: #8a909a;
  font-weight: 700;
}
.blog-section .blog-content .blog-list-date {
  align-items: center;
  margin: 0 0 20px;
}
@media (max-width: 767.78px) {
  .blog-section .blog-content .blog-list-date {
    display: block;
    margin: 0 0 15px;
  }
}
@media (max-width: 767.78px) {
  .blog-section .blog-content .blog-list-date .meta-item-list {
    display: block;
  }
}
.blog-section
  .blog-content
  .blog-list-date
  .meta-item-list
  li
  .post-author
  .post-author-img {
  width: 24px;
  margin: 0 8px 0 0;
}
@media (max-width: 767.78px) {
  .blog-section
    .blog-content
    .blog-list-date
    .meta-item-list
    li
    .post-author
    .post-author-img {
    display: inline-block;
  }
}
.blog-section
  .blog-content
  .blog-list-date
  .meta-item-list
  li
  .post-author
  .post-author-img
  img {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid #fff;
}
.blog-section .blog-content .blog-list-date .meta-item-list .date-icon i {
  margin: 0 7px 0;
  font-weight: 400;
}
@media (max-width: 767.78px) {
  .blog-section .blog-content .blog-list-date .meta-item-list .date-icon i {
    margin-left: 0;
  }
}
.blog-section
  .blog-content
  .blog-list-date
  .meta-item-list
  .date-icon
  i
  ~ span:hover {
  color: #8a909a;
}
.blog-section .blog-content .blog-list-date .meta-item-list .date-icon span {
  font-size: 14px;
  font-weight: 500;
  color: #8a909a;
  line-height: 0;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.blog-section
  .blog-content
  .blog-list-date
  .meta-item-list
  .date-icon
  span:hover {
  color: var(--primary-theme-color);
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.blog-section .blog-content .viewlink {
  display: inline-flex;
  border-radius: 80px;
  min-width: 60px;
  height: 60px;
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
}
@media (max-width: 991.98px) {
  .blog-section .blog-content .viewlink {
    padding: 10px 15px;
    font-size: 15px;
    max-width: 30px;
    min-width: 30px;
    height: 30px;
  }
}
.blog-section .blog-content .viewlink a {
  color: #fff;
}
.blog-section .blog-content .viewlink a:hover {
  color: var(--primary-theme-color);
}
.blog-section .blog-content .viewlink a i {
  line-height: 0;
}
@media (max-width: 991.98px) {
  .blog-section .rightsidebar {
    margin: 24px 0 0;
  }
}
.blog-section .rightsidebar .card {
  box-shadow: 0px 4px 24px rgba(225, 225, 225, 0.25);
  border-radius: 10px;
  padding: 24px;
}
.blog-section .rightsidebar .card h4 {
  margin: 0 0 20px;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #e3e3e3;
  font-weight: 700;
  font-size: 20px;
}
@media (max-width: 767.78px) {
  .blog-section .rightsidebar .card h4 {
    font-size: 18px;
  }
}
.blog-section .rightsidebar .card h4 i {
  margin: 0 10px 0 0;
  color: var(--primary-theme-color);
}
.blog-section .rightsidebar .card h4 img {
  margin: 0 8px 0 0;
}
.blog-section .rightsidebar .card .blogcategories-list {
  padding: 0 0 0 17px;
}
.blog-section .rightsidebar .card .blogcategories-list li {
  list-style: disc;
  display: list-item;
  margin: 0 0 19px;
}
.blog-section .rightsidebar .card .blogcategories-list li:hover {
  color: var(--primary-theme-color);
}
.blog-section .rightsidebar .card .blogcategories-list li a {
  color: #8a909a;
}
.blog-section .rightsidebar .card .blogcategories-list li a:hover {
  padding: 0 0 0 10px;
  color: var(--primary-theme-color);
}
.blog-section .rightsidebar .card .blogcategories-list li:last-child {
  margin-bottom: 0;
}
.blog-section .rightsidebar .card .tags li {
  margin: 0 5px 8px 0;
  padding: 9px 19px;
  position: relative;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.blog-section .rightsidebar .card .tags li:hover {
  background-color: var(--primary-theme-color);
  color: #fff;
  border: 1px solid var(--primary-theme-color);
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.blog-section .rightsidebar .card .tags li:last-child {
  margin: 0;
}
.blog-section .rightsidebar .card .article {
  position: relative;
  overflow: hidden;
  margin: 0 0 24px;
}
.blog-section .rightsidebar .card .article:hover .article-blog img {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
}
.blog-section .rightsidebar .card .article:hover .article-content h5 a {
  color: var(--primary-theme-color);
}
.blog-section
  .rightsidebar
  .card
  .article:hover
  .article-content
  .article-date {
  color: var(--primary-theme-color);
}
.blog-section .rightsidebar .card .article .article-blog {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.blog-section .rightsidebar .card .article .article-blog img {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}
.blog-section .rightsidebar .card .article .article-blog:before {
  background: linear-gradient(0deg, #000000 16.31%, rgba(0, 0, 0, 0) 100%);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}
.blog-section .rightsidebar .card .article .article-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 20px 20px;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  transition: 0.7s;
}
.blog-section .rightsidebar .card .article .article-content h5 {
  margin-bottom: 10px;
}
.blog-section .rightsidebar .card .article .article-content h5 a {
  font-weight: 600;
  color: #fff;
}
@media (max-width: 767.78px) {
  .blog-section .rightsidebar .card .article .article-content h5 {
    font-size: 18px;
  }
}
.blog-section .rightsidebar .card .article .article-content .article-date {
  font-size: 14px;
  color: #fff;
}
.blog-section .rightsidebar .card .article .article-content .article-date i {
  color: var(--primary-theme-color);
  margin: 0 10px 0 0;
}
.blog-section .rightsidebar .card .article:last-child {
  margin-bottom: 0;
}
.blog-section .rightsidebar .card:last-child {
  margin-bottom: 0;
}

.blog-description p {
  margin: 0 0 20px;
}

.bloggrid-sidebar .blog.grid-blog .post-author a {
  margin: 0;
}

.blog-banner {
  /* max-width: 1075px; */
  margin: auto;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 767.78px) {
  .blog-banner {
    flex-direction: column;
  }
}
.blog-banner .blog-banner-content {
  background: #fff;
  /* position: absolute;
  bottom: -50px;
  width: 900px; */
  max-width: 100%;
  margin: auto;
  box-shadow: 0px 4px 14px 0px rgb(147 147 147 / 24%);
  padding: 24px;
  border-radius: 10px;
  margin-top: 50px;
}
@media (max-width: 991.98px) {
  .blog-banner .blog-banner-content {
    position: relative;
    padding: 15px;
    bottom: 0px;
  }
}
.blog-banner .blog-banner-contenthead {
  text-align: left;
  border-bottom: 1px solid #c9c9c9;
  margin: 0 0 24px;
  padding: 0 0 24px;
  width: 100%;
}
.blog-banner .blog-banner-contenthead h1 {
  font-size: 32px;
  margin: 0 0 10px;
}
.blog-banner .blog-date {
  opacity: 0.8;
}
.blog-banner .meta-tag-wrap {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
@media (max-width: 991.98px) {
  .blog-banner .blog-banner-contenthead h1 {
    font-size: 24px;
  }
}
@media (max-width: 575.98px) {
  .blog-banner .blog-banner-contenthead h1 {
    font-size: 18px;
  }
}
.blog-banner .blog-banner-contenthead h6 {
  background: var(--primary-theme-color);
  border-radius: 10px;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  margin: 0 0 15px;
  padding: 6px 20px;
  display: inline-block;
}
.blog-banner .post-author-img img {
  max-width: 40px;
  border-radius: 50px;
}
.blog-banner .blog-detailset {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
  padding: 0 0 15px;
}
.blog-banner .post-author {
  text-align: center;
  margin-right: 30px;
}
.blog-banner .post-author a {
  margin-left: 8px;
  color: #8a909a;
  font-weight: 500;
}
.blog-banner .post-author a:hover {
  color: var(--primary-theme-color);
}
.blog-banner .looking-for-wrap > div {
  width: 100%;
}

.blog-banner .looking-for-wrap {
  margin-top: 50px;
  gap: 20px;
}
.blog-bg {
  background: #f5f5f5;
}

.blog-para-content {
  max-width: 1075px;
  margin: auto;
  position: relative;
  width: 100%;
  padding-top: 120px;
}
@media (max-width: 991.98px) {
  .blog-para-content {
    margin: 30px 0 0;
    padding: 0;
  }
}
.blog-para-content h4 {
  font-size: 20px;
  font-weight: 600;
  color: #0e0e46;
  margin: 0 0 24px;
}
.blog-para-content img {
  margin: 0 0 24px;
}

.blog-details-sec {
  background: #f6f4ff;
  padding: 60px 0;
}
@media (max-width: 991.98px) {
  .blog-details-sec {
    padding: 50px 0;
  }
}
@media (max-width: 767.78px) {
  .blog-details-sec {
    padding: 40px 0;
  }
}

/*-----------------	
    33. Shop
-----------------------*/
.shop-page .shop-filter {
  filter: drop-shadow(0px 4px 14px rgba(214, 214, 214, 0.24));
  background: #fff;
}
.shop-page .shop-filter .collapse-card {
  box-shadow: none;
  margin-bottom: 0;
}
.shop-page .shop-filter .collapse-card .card-title {
  padding: 0 0 15px 0;
}
.shop-page .shop-filter .collapse-card .card-title > a:not(.collapsed):after {
  display: none;
}
.shop-page .shop-filter .view-content .view-all {
  margin: 15px 0 0;
}
.shop-page .shop-filter .view-content .view-all a {
  font-weight: 700;
}
.shop-page .btn {
  margin: 24px 0 0;
  padding: 18px 20px;
  line-height: 22px;
}
.shop-page #colors-available .colors-themes {
  flex-flow: row wrap;
}
.shop-page #colors-available .colors-themes li {
  -ms-flex: 20%;
  flex: 20%;
  max-width: 20%;
  text-align: center;
}
.shop-page #colors-available .colors-themes li:last-child {
  margin: 0 0 15px;
}
.shop-page #colors-available .colors-themes .theme-color {
  display: block;
  width: 42px;
  height: 40px;
  border-radius: 10px;
}
.shop-page .reset-filter {
  display: block;
  text-align: center;
  margin-top: 15px;
  color: #fd3358;
}
.shop-page .product-custom .profile-widget {
  position: relative;
  overflow: hidden;
}
.shop-page .product-custom .profile-widget:hover .doc-img-view ul {
  top: 38px;
  opacity: 1;
}
.shop-page .product-custom .profile-widget:hover .pro-content {
  padding: 24px 24px 64px 24px;
}
.shop-page .product-custom .profile-widget:hover .doc-cart-view {
  bottom: 20px;
  opacity: 1;
}
.shop-page .product-custom .profile-widget:hover .product-price {
  display: none;
}
.shop-page .product-custom .profile-widget .new-featured {
  top: 40px;
  left: 40px;
}
.shop-page .product-custom .profile-widget .new-featured span {
  padding: 5px 10px;
}
.shop-page .product-custom .profile-widget .doc-img {
  padding: 24px;
}
.shop-page .product-custom .profile-widget .doc-img::before {
  background: none;
}
.shop-page .product-custom .profile-widget .doc-img img {
  border: 1px solid #f4f4f4;
  border-radius: 0;
}
.shop-page .product-custom .profile-widget .pro-content h3 {
  font-size: 20px;
  margin: 0 0 20px;
}
.shop-page .product-custom .profile-widget .pro-content .product-price h5 {
  color: #fd3358;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
}
.shop-page .product-custom .profile-widget .pro-content .product-price h5 span {
  text-decoration: line-through;
  color: #8a909a;
  font-weight: 500;
  font-size: 20px;
  margin: 0 10px 0 0;
}
.shop-page .product-custom .profile-widget .pro-content .rating {
  margin: 0 0 5px 0;
}
.shop-page .product-custom .profile-widget .doc-img-product {
  padding: 24px 24px 0;
}
.shop-page .product-custom .profile-widget .doc-img-product img {
  width: 100%;
}
.shop-page .product-custom .profile-widget .doc-img-view ul {
  position: absolute;
  top: -38px;
  right: 36px;
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 0;
  transition: 0.5s;
}
.shop-page .product-custom .profile-widget .doc-img-view ul li {
  display: inline-block;
  margin-right: 4px;
}
.shop-page .product-custom .profile-widget .doc-img-view ul li a {
  background: #fff;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 4px 24px rgba(225, 225, 225, 0.25);
  border-radius: 40px;
  width: 40px;
  height: 40px;
}
.shop-page .product-custom .profile-widget .doc-img-view ul li a span i {
  color: #8a909a !important;
}
.shop-page .product-custom .profile-widget .doc-cart-view {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 0;
  transition: 0.5s;
}
.shop-page .product-custom .profile-widget .doc-cart-view .btn-primary {
  padding: 11px 15px;
}

.cart-section .cart-table .table thead {
  background: #f6f6f9;
  text-align: center;
  vertical-align: middle;
}
.cart-section .cart-table .table thead th {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #0e0e46;
}
.cart-section .cart-table .table tbody {
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
.cart-section .product-group .detail-gallery {
  position: relative;
}
.cart-section .product-group .detail-gallery .product-image-slider {
  background-color: #fff;
  margin: 0 0 20px 0;
  border-radius: 15px;
  border: 1px solid #e3e3e3;
  overflow: hidden;
}
.cart-section .product-group .detail-gallery .zoom-icon {
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 2;
  font-size: 22px;
  opacity: 0.6;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  width: 50px;
  height: 50px;
}
.cart-section
  .product-group
  .detail-gallery
  .slider-nav-thumbnails
  .slick-list {
  margin: 0 -10px;
}
.cart-section
  .product-group
  .detail-gallery
  .slider-nav-thumbnails
  .slick-slide {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.cart-section
  .product-group
  .detail-gallery
  .slider-nav-thumbnails
  .slick-slide
  img {
  border-radius: 15px;
  border: 1px solid #e3e3e3;
  padding: 10px;
  max-width: 80px !important;
}
.cart-section
  .product-group
  .detail-gallery
  .slider-nav-thumbnails
  .slick-slide.slick-current::before {
  border-bottom: 5px solid #e3e3e3;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: -6px;
  width: 0;
}
.cart-section .product-group .detail-gallery .new-featured {
  position: absolute;
  left: 20px;
  top: 20px;
}
.cart-section .product-group .detail-gallery .new-featured span {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background-color: #ff0000;
  padding: 8px;
  border-radius: 50px;
  position: relative;
  z-index: 99;
  width: 62px;
  height: 62px;
}
.cart-section .product-group .product-content {
  padding: 24px 24px 0;
}
.cart-section .product-group .product-content .rating {
  margin: 0 0 15px 0;
}
.cart-section .product-group .product-content .rating .rating-review {
  margin: 0 0 0 8px;
}
.cart-section .product-group .product-content .product-link {
  color: var(--primary-theme-color);
}
.cart-section .product-group .product-content h2 {
  font-size: 36px;
  text-transform: capitalize;
  margin: 15px 0 0;
}
.cart-section .product-group .product-content .product-price {
  margin: 30px 0 0;
}
.cart-section .product-group .product-content .product-price h5 {
  color: #fd3358;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
}
.cart-section .product-group .product-content .product-price h5 span {
  text-decoration: line-through;
  color: #8a909a;
  font-weight: 500;
  font-size: 20px;
  margin: 0 10px 0 0;
}
.cart-section .product-group .product-content .detail-count {
  margin: 30px 0;
  padding: 7px;
}
.cart-section .product-group .product-content .detail-count .detail-qty {
  position: relative;
  margin: 0 15px 0 0;
  padding: 7px;
  background: #fff;
  border: 1px solid #dbdbdb;
  color: #2f2f2f;
  border-radius: 5px;
  min-width: 106px;
}
.cart-section
  .product-group
  .product-content
  .detail-count
  .detail-qty
  .qty-val {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.cart-section
  .product-group
  .product-content
  .detail-count
  .detail-qty
  > a.qty-down {
  position: relative;
  left: 5px;
  color: #2f2f2f;
}
.cart-section
  .product-group
  .product-content
  .detail-count
  .detail-qty
  > a.qty-up {
  right: 12px;
  position: absolute;
  color: #2f2f2f;
}
.cart-section .product-group .product-content .detail-count .btn-cart {
  border-radius: 5px;
  padding: 7px 14px;
}
.cart-section .product-group .product-content .detail-count .btn-cart span i {
  font-size: 14px;
}
.cart-section .product-group .product-content .product-compare {
  margin: 0 0 30px 0;
}
.cart-section .product-group .product-content .product-compare a {
  color: #828282;
  font-weight: 500;
  font-size: 15px;
  margin: 0 22px 0 0;
}
.cart-section .product-group .product-content .product-compare a:last-child {
  margin-right: 0;
}
.cart-section .product-group .product-content .product-compare a:hover {
  color: var(--primary-theme-color);
}
.cart-section .product-group .product-content .cart-select {
  margin: 25px 0 0;
}
.cart-section .product-group .product-content .cart-select label {
  font-weight: 500;
  font-size: 14px;
}
.cart-section .product-group .product-content .categories {
  margin: 25px 0 0;
}
.cart-section .product-group .product-content .categories p {
  margin: 0 0 11px 0;
  color: #727272;
  font-weight: 500;
  font-size: 14px;
}
.cart-section .product-group .product-content .categories p:last-child {
  margin-bottom: 0;
}
.cart-section .product-group .product-content .categories p span {
  margin: 0 0 0 5px;
  color: #292e40;
}
.cart-section .product-group .product-content .categories p .stock {
  color: #0dca95;
}
.cart-section .product-group .product-content .social-links {
  margin: 30px 0 0;
}
.cart-section .product-group .product-content .social-links p {
  font-weight: 700;
  font-size: 15px;
  color: #828282;
  margin: 0 10px 0 0;
}
.cart-section .product-group .product-content .social-links a {
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #fff;
  margin: 0 10px 0 0;
  border-radius: 50%;
  text-decoration: none;
  font-size: 10px;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}
.cart-section .product-group .product-content .social-links a:last-child {
  margin-right: 0;
}
.cart-section .product-group .product-content .social-links a:hover {
  background: var(--primary-theme-color);
  color: #fff;
}
.cart-section .product-group .product-content .social-links .facebook {
  background: #3080f8;
}
.cart-section .product-group .product-content .social-links .instagram {
  background: #ff2d20;
}
.cart-section .product-group .product-content .social-links .behance {
  background: #0066ff;
}
.cart-section .product-group .product-content .social-links .twitter {
  background: #35b6ff;
}
.cart-section .product-group .product-content .social-links .linkedin {
  background: #0066ff;
}

.description-section {
  background: #f7f6ff;
}
.description-section .tab-group .nav-tabs {
  border: 1px solid transparent;
}
.description-section .tab-group .nav-tabs .nav-item .nav-link {
  border-radius: 10px 10px 0px 0px;
  font-weight: 500;
  font-size: 16px;
  color: #0e0e46;
}
.description-section .tab-group .nav-tabs .nav-item .nav-link:hover {
  border: 1px solid transparent;
  outline: 0;
  color: var(--primary-theme-color);
}
.description-section .tab-group .nav-tabs .nav-item .nav-link.active {
  background: var(--primary-theme-color);
  color: #fff;
}
.description-section .tab-group .tab-content .tab-details {
  background: #fff;
  padding: 60px;
}

.recommended-slider .profile-widget:hover {
  margin-top: 0 !important;
}
.recommended-slider .profile-widget:hover .doc-img-view ul {
  top: 38px;
  opacity: 1;
}
.recommended-slider .profile-widget:hover .pro-content {
  padding: 24px !important;
}
.recommended-slider .profile-widget:hover .doc-cart-view {
  bottom: 20px;
  opacity: 1;
}
.recommended-slider .profile-widget:hover .product-price {
  display: block !important;
}

.bg-primary-dark {
  background: var(--primary-theme-color);
}

.bg-danger-dark {
  background: #d90000;
}

.bg-orange-dark {
  background: #ec6b40;
}

.bg-navy-dark {
  background: #4040c1;
}

.bg-green-dark {
  background: #1fbc2f;
}

.bg-danger-light {
  background: #ec4040;
}

.bg-pink-dark {
  background: #ec6b96;
}

.bg-blue-light {
  background: #6b96ec;
}

.bg-skyblue-light {
  background: #15c1ec;
}

.bg-green-light {
  background: #0dca95;
}

.bg-ink-blue {
  background: #1e1d85;
}

.bg-skyblue-blue {
  background: #1596ec;
}

/*-----------------	
    34. Wishlist
-----------------------*/
.wishlist-section .table-responsive .table thead {
  background: #f6f6f9;
  vertical-align: middle;
  text-align: left;
  border-bottom: transparent;
}
.wishlist-section .table-responsive .table thead th {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #000;
  white-space: nowrap;
  padding: 15px 15px;
}
.wishlist-section .table-responsive .table thead th.wishlist-action {
  padding: 0 60px 0 0;
  text-align: end;
}
.wishlist-section .table-responsive .table thead th.wishlist-product {
  padding: 0 0 0 40px;
}
.wishlist-section .table-responsive .table tbody .cart-action {
  border: none;
  background: transparent;
}
.wishlist-section .table-responsive .table tbody .cart-action i {
  color: #fd3358;
}
.wishlist-section .table-responsive .table tbody .cart-img {
  margin: 0 15px;
}
.wishlist-section .table-responsive .table tbody .cart-img img {
  width: 80px;
}
.wishlist-section .table-responsive .table tbody .cart-item p {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #0e0e46;
}
@media (max-width: 991.98px) {
  .wishlist-section .table-responsive .table tbody .cart-item p {
    font-size: 14px;
  }
}
.wishlist-section .table-responsive .table tbody td {
  padding: 15px;
  vertical-align: middle;
  text-align: left;
  white-space: nowrap;
}
.wishlist-section .table-responsive .table tbody td .cart-item-price {
  font-weight: 500;
  font-size: 14px;
  color: #fd3358;
}
.wishlist-section .table-responsive .table tbody td {
  vertical-align: middle;
  text-align: left;
  white-space: nowrap;
}
.wishlist-section .table-responsive .table tbody td .cart-availability span {
  font-weight: 500;
  font-size: 14px;
  color: #0dca95;
}
.wishlist-section .table-responsive .table tbody .cart-btn {
  text-align: end;
  padding: 0 40px 0 0;
}
@media (max-width: 991.98px) {
  .wishlist-section .table-responsive .table tbody .cart-btn {
    padding: 0 20px 0 0;
  }
}
.wishlist-section .table-responsive .table tbody .cart-btn .btn-primary {
  padding: 10px 20px;
  display: inline;
}
@media (max-width: 991.98px) {
  .wishlist-section .table-responsive .table tbody .cart-btn .btn-primary {
    padding: 10px;
    font-size: 12px;
  }
}

.wishlist-section .table-responsive .table thead .cart-bg {
  background: #f7f6ff;
}
.wishlist-section .table-responsive .table tbody tr td .cart-img img {
  width: 80px;
}
.wishlist-section .table-responsive .table tbody tr td .cart-item-price-two {
  font-weight: 500;
  font-size: 14px;
  color: #292e40;
}
.wishlist-section .table-responsive .table tbody tr td .product-quantity {
  border: 1px solid #a9a9a9;
  width: 75px;
  border-radius: 5px;
  height: 40px;
  padding: 5px 0;
}
.wishlist-section .table-responsive .table tbody tr td .product-quantity span {
  cursor: pointer;
}
.wishlist-section
  .table-responsive
  .table
  tbody
  tr
  td
  .product-quantity
  .quntity-input {
  width: 20px;
  margin: 0 10px;
  border: none;
  text-align: center;
}

.promo-cart {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  padding: 24px;
}
.promo-cart .promo-content {
  display: flex;
  justify-content: end;
}
@media (max-width: 575.98px) {
  .promo-cart .promo-content {
    justify-content: center;
  }
}
.promo-cart .promo-content .promo-wrap h6 {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #0e0e46;
  margin: 0 0 15px 0;
  line-height: 27px;
}
.promo-cart .promo-content .promo-wrap .promo-form {
  display: flex;
}
@media (max-width: 575.98px) {
  .promo-cart .promo-content .promo-wrap .promo-form {
    display: block;
  }
}
.promo-cart .promo-content .promo-wrap .promo-form input {
  width: 370px;
  margin: 0 20px 0 0;
  padding: 20px;
  background: #f6f6f9;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
}
.promo-cart .promo-content .promo-wrap .promo-form input.focus {
  outline: none;
}
@media (max-width: 575.98px) {
  .promo-cart .promo-content .promo-wrap .promo-form input {
    width: 270px;
    margin: 0 0 15px 0;
  }
}
@media (max-width: 767.78px) {
  .promo-cart .promo-content .promo-wrap .promo-form .btn-primary {
    padding: 10px;
  }
}
@media (max-width: 575.98px) {
  .promo-cart .promo-content .promo-wrap .promo-form .btn-primary {
    width: 270px;
  }
}

.cart-purchase .cart-discount-amount {
  background: #f7f6ff;
  border: 1px solid #f4f4f4;
  display: flex;
  justify-content: center;
  padding: 40px;
}
.cart-purchase .cart-discount-amount p {
  font-weight: 400;
  font-size: 16px;
  color: #727272;
  margin: 0 20px 0 0;
}

.shop-section .shop-content {
  display: flex;
  justify-content: end;
}
.shop-section .shop-content .cart-continue {
  margin: 0 20px 0 0;
}
.shop-section .shop-content .cart-continue a {
  background: #f6f6f9;
  color: #292e40;
  font-weight: 700;
  font-size: 15px;
}
.shop-section .shop-content .cart-continue a :hover {
  color: #fff;
  background: var(--primary-theme-color);
}

/*-----------------	
    35. Checkout
-----------------------*/
.checkout-billings {
  padding: 80px 0;
}
.checkout-billings .checkout-login {
  margin: 0 0 40px 0;
}
.checkout-billings .checkout-login h6 {
  background: rgba(253, 51, 88, 0.05);
  padding: 30px;
  font-weight: 400;
  font-size: 16px;
  color: #0e0e46;
}
.checkout-billings .checkout-login h6 span {
  color: #fd3358;
  font-weight: 700;
  font-size: 15px;
}
.checkout-billings .checkout-you-need-block .billing-title h3 {
  font-weight: 600;
  font-size: 20px;
  color: #000;
}
.checkout-billings .checkout-you-need-block .billing-form .form-group label {
  font-weight: 400;
  font-size: 16px;
  color: #292e40;
}
.checkout-billings
  .checkout-you-need-block
  .billing-form
  .form-check
  label.form-check-label {
  color: #8a909a;
  font-weight: 500;
  font-size: 14px;
}
.checkout-billings .checout-form .booking-time {
  background: #fff;
  box-shadow: 0px 4px 24px rgba(225, 225, 225, 0.25);
  padding: 20px;
}
.checkout-billings .checout-form .booking-time h3 {
  font-weight: 600;
  font-size: 20px;
  color: #111111;
}
.checkout-billings
  .checout-form
  .booking-time
  .booking-table
  .booking-invoice
  thead
  tr
  th {
  font-weight: 500;
  font-size: 16px !important;
  text-transform: capitalize;
  color: #2f2f2f !important;
  border: 0;
  padding: 0 0 20px 0;
}
.checkout-billings
  .checout-form
  .booking-time
  .booking-table
  .booking-invoice
  tbody
  tr
  .booking-head {
  font-weight: 400;
  font-size: 16px;
  color: #212121;
  padding: 0 0 15px 0;
}
@media (max-width: 575.98px) {
  .checkout-billings
    .checout-form
    .booking-time
    .booking-table
    .booking-invoice
    tbody
    tr
    .booking-head {
    font-size: 14px;
  }
}
.checkout-billings
  .checout-form
  .booking-time
  .booking-table
  .booking-invoice
  tbody
  tr
  .booking-head-two {
  font-weight: 700;
  font-size: 15px;
  color: #212121;
  padding: 15px 0;
}
.checkout-billings
  .checout-form
  .booking-time
  .booking-table
  .booking-invoice
  tbody
  tr
  .billing-amount {
  font-weight: 400;
  font-size: 16px;
  color: #8a909a;
  padding: 0 0 15px 0;
}
.checkout-billings
  .checout-form
  .booking-time
  .booking-table
  .booking-invoice
  tbody
  tr:last-child
  td {
  border: 0;
  padding: 15px 0;
}
.checkout-billings .checout-form .billing-payment {
  background: #fff;
  box-shadow: 0px 4px 24px rgba(225, 225, 225, 0.25);
  padding: 20px;
}
.checkout-billings .checout-form .billing-payment h5 {
  font-weight: 600;
  font-size: 20px;
  color: #111111;
}
.checkout-billings .checout-form .billing-payment .payment-gateway p {
  font-weight: 500;
  font-size: 16px;
  color: #2f2f2f;
}
.checkout-billings .checout-form .billing-payment .payment-gateway p span {
  color: #ff0000;
}
.checkout-billings
  .checout-form
  .billing-payment
  .payment-gateway
  .checkout-payment-method {
  margin: 0 0 20px 0;
}
.checkout-billings
  .checout-form
  .billing-payment
  .payment-gateway
  .checkout-payment-method
  .debit-credit-bill {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-weight: 400;
  color: #8a909a;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkout-billings
  .checout-form
  .billing-payment
  .payment-gateway
  .checkout-payment-method
  .debit-credit-bill
  input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkout-billings
  .checout-form
  .billing-payment
  .payment-gateway
  .checkout-payment-method
  .debit-credit-bill
  input
  ~ .checkmark-check:after {
  content: "";
  position: absolute;
  display: none;
}
.checkout-billings
  .checout-form
  .billing-payment
  .payment-gateway
  .checkout-payment-method
  .debit-credit-bill
  input:checked
  ~ .checkmark-check {
  background-color: var(--primary-theme-color);
}
.checkout-billings
  .checout-form
  .billing-payment
  .payment-gateway
  .checkout-payment-method
  .debit-credit-bill
  input:checked
  ~ .checkmark-check:after {
  display: block;
}
.checkout-billings
  .checout-form
  .billing-payment
  .payment-gateway
  .checkout-payment-method
  .debit-credit-bill
  .checkmark-check {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fcfbfb;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
}
.checkout-billings
  .checout-form
  .billing-payment
  .payment-gateway
  .checkout-payment-method
  .debit-credit-bill
  .checkmark-check:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}

/*-----------------	
    36. Add Property
-----------------------*/
.property-tabs {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  margin: 0 0 50px;
  padding: 20px 20px 10px;
}
.property-tabs ul li {
  display: inline-block;
  margin-right: 10px;
}
.property-tabs ul li a {
  font-weight: 700;
  font-size: 15px;
  color: #0e0e46;
  border-radius: 10px;
  display: inline-block;
  margin: 0 0 10px;
  padding: 14px 20px;
}
.property-tabs ul li a.active {
  background-color: var(--primary-theme-color);
  color: #fff;
}
.property-tabs ul li a:hover {
  background-color: var(--primary-theme-color);
  color: #fff;
}
@media (max-width: 767.78px) {
  .property-tabs ul li a {
    margin: 0 0 10px;
    padding: 10px 20px;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .property-tabs ul li {
    display: block;
    margin-right: 0;
  }
}

.property-info {
  margin-bottom: 24px;
}
.property-info h4 {
  margin-bottom: 12px;
}
@media (max-width: 991.98px) {
  .property-info h4 {
    font-size: 22px;
  }
}
@media (max-width: 767.78px) {
  .property-info h4 {
    font-size: 18px;
  }
}

.add-property-wrap {
  background: #fff;
  box-shadow: 0px 4px 4px #f5f5f5;
  border-radius: 10px;
  margin: 0 0 33px;
  padding: 24px 24px 0;
}
.add-property-wrap .select2-container .select2-selection--single {
  height: 45px;
}
.add-property-wrap
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 45px;
}
.add-property-wrap
  .select2-container
  .select2-selection--single
  .select2-selection__arrow {
  height: 45px;
}
.add-property-wrap .custom_check {
  color: #8a909a;
}

.property-submit {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(214, 214, 214, 0.24);
  padding: 20px 20px 10px;
  text-align: right;
}
.property-submit .btn {
  min-width: 238px;
  margin: 0 20px 10px 0;
  padding: 10px;
}
.property-submit .btn:last-child {
  margin-right: 0;
}
@media (max-width: 767.78px) {
  .property-submit .btn:last-child {
    margin-right: 10px;
  }
}
@media (max-width: 767.78px) {
  .property-submit .btn {
    min-width: 180px;
    margin: 0 10px 10px;
    padding: 11px;
  }
}
@media (max-width: 767.78px) {
  .property-submit {
    text-align: center;
  }
}

.upload-list {
  margin-bottom: 24px;
}
.upload-list ul li {
  position: relative;
  margin: 0 0 20px;
  padding: 0 0 0 20px;
}
.upload-list ul li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-family: "Fontawesome";
  font-size: 8px;
  content: "\f111";
}
.upload-list p {
  color: #0dca95;
}
.upload-list p i {
  margin-right: 10px;
}

.upload-file {
  background: #f6f6f9;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  padding: 10px 20px;
  position: relative;
}
.upload-file input {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.upload-file span {
  font-size: 14px;
  font-weight: 500;
}

.btn-upload i {
  margin-right: 8px;
  font-size: 16px;
}

.gallery-property ul li {
  position: relative;
  display: inline-block;
  margin: 0 24px 24px 0;
}
.gallery-property ul li img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
}
.gallery-property ul li a {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #fd3358;
  color: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (max-width: 767.78px) {
  .gallery-property ul li {
    margin-right: 20px;
  }
}

.google-maps iframe {
  width: 100%;
  height: 400px;
}

.add-more {
  color: #fd3358;
  font-weight: 500;
  font-size: 14px;
}
.add-more:hover {
  color: var(--primary-theme-color);
}

.property-img-slider .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.property-img-slider .owl-dots button span {
  background: #fff;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.property-img-slider .owl-dots button.active {
  border: none;
  width: auto;
  height: auto;
  border-radius: 4px;
}

.property-img-slider .owl-dots button.active span {
  width: 20px;
  height: 8px;
  border-radius: 54px !important;
}
.charges-to-pay-now {
  margin-top: 30px;
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 8px;
  background: #f9f9ff;
}

.charges-to-pay-now .wrap span {
  font-weight: 700;
  font-size: 14px;
}

.charges-to-pay-now .wrap p {
  font-size: 14px;
  line-height: 20px;
}
.charges-to-pay-now .title-wrap {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
}

.charges-to-pay-now .title-wrap h5 {
  margin-bottom: 0;
  font-size: 18px;
}

.charges-to-pay-now .title-wrap .amount-to-pay-now {
  font-weight: 600;
  color: green;
  font-size: 22px;
}

.charges-to-pay-now .title-wrap .amount-to-pay-now svg {
  font-size: 18px;
  margin-right: 3px;
}
.kyc-images {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.kyc-img {
  width: 100px;
  height: auto;
  border-radius: 5px;
  border: 1px solid #ddd;
}
.tenant-info {
  display: flex;
  justify-content: flex-start;
  gap: 50px;
}
.contact-label {
  display: block;
}
.contact-value {
  margin-left: block;
}
.audit-image-slider img {
  height: 400px;
  object-fit: contain;
  border: 1px solid lightgray;
  border-radius: 8px;
}
